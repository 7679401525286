.banner{
    &-video, &-item{
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 64%;
        @include rpmin(md){
            padding-top: 31.25%;
        }
        display: block;
        
        video,img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &.bannerHome{
        .banner-video, .banner-item{
            // padding-top: 130%;
            // @include rpmin(md){
            //     padding-top: 56.25%;
            // }

            padding-top: 64%;
            @include rpmin(md){
                padding-top: 31.25%;
            }
        }
    }

    &-item{
        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #171717;
            opacity: .3;
        }
    }

    .playYtb{
        width: 5.2rem;
        height: 5.2rem;
        padding-top: 0;

        @include rpmin(md){
            width: 6.4rem;
            height: 6.4rem;
            padding-top: 0;
        }
        @include rpmin(lg){
            width: 8.8rem;
            height: 8.8rem;
            padding-top: 0;
        }

        opacity: .3;
    }


}