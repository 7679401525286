.box-pagination{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
  align-items: center;
  --wh: 4rem;

  li{
    background-color: var(--cl-main);
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: solid 2px white;
    height: var(--wh);
    width:var(--wh);
    transition: .3s ease-in-out;
    @include sizeBs(s20);

    &:not(.active):hover{
      border: solid 2px white;
      color: white;
      background-color: map-get($color, ora );
    }

    a{
      background-color: var(--cl-main);
      color: white;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      // border: solid 2px white;
      height: var(--wh);
      width:var(--wh);
      transition: .3s ease-in-out;
      @include sizeBs(s20);

      &:not(.active):hover{
        border: solid 2px white;
        color: white;
        background-color: map-get($color, ora );
      }
    }
    
    // @include size()
    svg{
      width: .8rem;
      @include rpmin(md){
        width: 1rem;
      }
      @include rpmin(lg){
        width: 1.6rem;
      }
    }

    cursor: pointer;
    user-select: none;

    &.active{
      color: var(--cl-w);
      background-color: var(--cl-sub);
      cursor: initial;
      a{
        color: var(--cl-w);
        background-color: var(--cl-sub);
        cursor: initial;
      }
    }
  }
  li.next,
  li.prev{
    background-color: var(--cl-main);
    border: unset !important;
    width: var(--wh);
    height: var(--wh);
    transition: .3s ease-in-out;
    
    &:not(.btn-hidden):hover{
      background-color: var(--cl-sub) ;
    }

    &.btn-hidden{
      display: none;
      // background-color: olive;
      cursor: initial;
    }

    a{
      background-color: var(--cl-main);
      border: unset !important;
      width: var(--wh);
      height: var(--wh);
      transition: .3s ease-in-out;
      
      &:not(.btn-hidden):hover{
        background-color: var(--cl-sub) ;
      }
    }
  }

  li.prev{
    svg{
      transform: rotate(180deg);
    }
  }
  
  li + li{
    margin-left: 6px;
    @include rpmin(sm){
      margin-left: 10px;
    }
    @include rpmin(lg){
      margin-left: 15px;
    }
  }
}