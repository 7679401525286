@charset "UTF-8";
/*!
* source: https://getbootstrap.com/
* Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/
.container,
.container-left,
.container-right {
  width: 96%;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 420px) {
  .container {
    width: 96%; }
  .container-right,
  .container-left {
    width: 96%; } }

@media (min-width: 420px) and (min-width: 992px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; }
  .container-left {
    margin-right: initial;
    margin-left: auto; }
  .container-right,
  .container-left {
    width: calc(96% + ((100% - 96%) / 2)); } }

@media (min-width: 576px) {
  .container {
    width: 540px; }
  .container-right,
  .container-left {
    width: 540px; } }

@media (min-width: 576px) and (min-width: 992px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; }
  .container-left {
    margin-right: initial;
    margin-left: auto; }
  .container-right,
  .container-left {
    width: calc(540px + ((100% - 540px) / 2)); } }

@media (min-width: 768px) {
  .container {
    width: 720px; }
  .container-right,
  .container-left {
    width: 720px; } }

@media (min-width: 768px) and (min-width: 992px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; }
  .container-left {
    margin-right: initial;
    margin-left: auto; }
  .container-right,
  .container-left {
    width: calc(720px + ((100% - 720px) / 2)); } }

@media (min-width: 992px) {
  .container {
    width: 960px; }
  .container-right,
  .container-left {
    width: 960px; } }

@media (min-width: 992px) and (min-width: 992px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; }
  .container-left {
    margin-right: initial;
    margin-left: auto; }
  .container-right,
  .container-left {
    width: calc(960px + ((100% - 960px) / 2)); } }

@media (min-width: 1200px) {
  .container {
    width: 1140px; }
  .container-right,
  .container-left {
    width: 1140px; } }

@media (min-width: 1200px) and (min-width: 992px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; }
  .container-left {
    margin-right: initial;
    margin-left: auto; }
  .container-right,
  .container-left {
    width: calc(1140px + ((100% - 1140px) / 2)); } }

@media (min-width: 1336px) {
  .container {
    width: 1245px; }
  .container-right,
  .container-left {
    width: 1245px; } }

@media (min-width: 1336px) and (min-width: 992px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; }
  .container-left {
    margin-right: initial;
    margin-left: auto; }
  .container-right,
  .container-left {
    width: calc(1245px + ((100% - 1245px) / 2)); } }

@media (min-width: 1500px) {
  .container {
    width: 1245px; }
  .container-right,
  .container-left {
    width: 1245px; } }

@media (min-width: 1500px) and (min-width: 992px) {
  .container-right {
    margin-right: auto;
    margin-left: initial; }
  .container-left {
    margin-right: initial;
    margin-left: auto; }
  .container-right,
  .container-left {
    width: calc(1245px + ((100% - 1245px) / 2)); } }

@media (max-width: 767px) {
  .container {
    overflow: hidden; } }

.col {
  flex: 1 0 0%; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
  width: calc(100% + (var(--bs-gutter-x) * 0.5 * 2)); }
  .row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }
  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%; }
  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%; }

@media (min-width: 420px) {
  .col-sx {
    flex: 1 0 0%; }
  .row-cols-sx-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sx-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sx-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-sx-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-sx-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-sx-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-sx-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-sx-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sx-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-sx-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sx-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sx-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-sx-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-sm-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-sm-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-sm-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-md-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-md-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-md-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-lg-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-lg-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-lg-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-xl-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-xl-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-xl-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1336px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-xxl-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-xxl-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-xxl-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

@media (min-width: 1500px) {
  .col-yl {
    flex: 1 0 0%; }
  .row-cols-yl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-yl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-yl-2 > * {
    flex: 0 0 auto;
    width: 91.66667%; }
  .row-cols-yl-3 > * {
    flex: 0 0 auto;
    width: 83.33333%; }
  .row-cols-yl-4 > * {
    flex: 0 0 auto;
    width: 75%; }
  .row-cols-yl-5 > * {
    flex: 0 0 auto;
    width: 66.66667%; }
  .row-cols-yl-6 > * {
    flex: 0 0 auto;
    width: 58.33333%; }
  .row-cols-yl-7 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-yl-8 > * {
    flex: 0 0 auto;
    width: 41.66667%; }
  .row-cols-yl-9 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-yl-10 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-yl-11 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .row-cols-yl-12 > * {
    flex: 0 0 auto;
    width: 8.33333%; } }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

@media (max-width: 419px) {
  .order-sx-1 {
    order: 1; }
  .order-sx-2 {
    order: 2; }
  .order-sx-3 {
    order: 3; }
  .order-sx-4 {
    order: 4; }
  .order-sx-5 {
    order: 5; }
  .order-sx-6 {
    order: 6; }
  .order-sx-7 {
    order: 7; }
  .order-sx-8 {
    order: 8; }
  .order-sx-9 {
    order: 9; }
  .order-sx-10 {
    order: 10; } }

@media (max-width: 575px) {
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; } }

@media (max-width: 767px) {
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; } }

@media (max-width: 991px) {
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; } }

@media (max-width: 1199px) {
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; } }

@media (max-width: 1335px) {
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; } }

@media (max-width: 1499px) {
  .order-yl-1 {
    order: 1; }
  .order-yl-2 {
    order: 2; }
  .order-yl-3 {
    order: 3; }
  .order-yl-4 {
    order: 4; }
  .order-yl-5 {
    order: 5; }
  .order-yl-6 {
    order: 6; }
  .order-yl-7 {
    order: 7; }
  .order-yl-8 {
    order: 8; }
  .order-yl-9 {
    order: 9; }
  .order-yl-10 {
    order: 10; } }

.offset-0 {
  margin-left: 0%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.offset-l-0 {
  margin-left: 0%; }

.offset-l-1 {
  margin-left: 8.33333%; }

.offset-l-2 {
  margin-left: 16.66667%; }

.offset-l-3 {
  margin-left: 25%; }

.offset-l-4 {
  margin-left: 33.33333%; }

.offset-l-5 {
  margin-left: 41.66667%; }

.offset-l-6 {
  margin-left: 50%; }

.offset-l-7 {
  margin-left: 58.33333%; }

.offset-l-8 {
  margin-left: 66.66667%; }

.offset-l-9 {
  margin-left: 75%; }

.offset-l-10 {
  margin-left: 83.33333%; }

.offset-l-11 {
  margin-left: 91.66667%; }

.offset-r-0 {
  margin-right: 0%; }

.offset-r-1 {
  margin-right: 8.33333%; }

.offset-r-2 {
  margin-right: 16.66667%; }

.offset-r-3 {
  margin-right: 25%; }

.offset-r-4 {
  margin-right: 33.33333%; }

.offset-r-5 {
  margin-right: 41.66667%; }

.offset-r-6 {
  margin-right: 50%; }

.offset-r-7 {
  margin-right: 58.33333%; }

.offset-r-8 {
  margin-right: 66.66667%; }

.offset-r-9 {
  margin-right: 75%; }

.offset-r-10 {
  margin-right: 83.33333%; }

.offset-r-11 {
  margin-right: 91.66667%; }

.gx-0 {
  --bs-gutter-x: 0rem; }

.gy-0 {
  --bs-gutter-y: 0rem; }

.gx-8 {
  --bs-gutter-x: 0.8rem; }

.gy-8 {
  --bs-gutter-y: 0.8rem; }

.gx-10 {
  --bs-gutter-x: 1rem; }

.gy-10 {
  --bs-gutter-y: 1rem; }

.gx-16 {
  --bs-gutter-x: 1.6rem; }

.gy-16 {
  --bs-gutter-y: 1.6rem; }

.gx-20 {
  --bs-gutter-x: 2rem; }

.gy-20 {
  --bs-gutter-y: 2rem; }

.gx-24 {
  --bs-gutter-x: 2.4rem; }

.gy-24 {
  --bs-gutter-y: 2.4rem; }

.gx-26 {
  --bs-gutter-x: 2.6rem; }

.gy-26 {
  --bs-gutter-y: 2.6rem; }

.gx-30 {
  --bs-gutter-x: 3rem; }

.gy-30 {
  --bs-gutter-y: 3rem; }

.gx-32 {
  --bs-gutter-x: 3.2rem; }

.gy-32 {
  --bs-gutter-y: 3.2rem; }

.gx-38 {
  --bs-gutter-x: 3.8rem; }

.gy-38 {
  --bs-gutter-y: 3.8rem; }

.gx-40 {
  --bs-gutter-x: 4rem; }

.gy-40 {
  --bs-gutter-y: 4rem; }

.gx-42 {
  --bs-gutter-x: 4.2rem; }

.gy-42 {
  --bs-gutter-y: 4.2rem; }

.gx-48 {
  --bs-gutter-x: 4.8rem; }

.gy-48 {
  --bs-gutter-y: 4.8rem; }

.gx-52 {
  --bs-gutter-x: 5.2rem; }

.gy-52 {
  --bs-gutter-y: 5.2rem; }

.gx-60 {
  --bs-gutter-x: 6rem; }

.gy-60 {
  --bs-gutter-y: 6rem; }

.gx-76 {
  --bs-gutter-x: 7.6rem; }

.gy-76 {
  --bs-gutter-y: 7.6rem; }

@media (min-width: 420px) {
  .col-sx-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sx-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sx-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sx-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sx-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sx-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sx-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sx-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sx-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sx-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sx-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sx-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sx-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sx-0 > * {
    margin-left: 0%; }
  .offset-sx-1 > * {
    margin-left: 8.33333%; }
  .offset-sx-2 > * {
    margin-left: 16.66667%; }
  .offset-sx-3 > * {
    margin-left: 25%; }
  .offset-sx-4 > * {
    margin-left: 33.33333%; }
  .offset-sx-5 > * {
    margin-left: 41.66667%; }
  .offset-sx-6 > * {
    margin-left: 50%; }
  .offset-sx-7 > * {
    margin-left: 58.33333%; }
  .offset-sx-8 > * {
    margin-left: 66.66667%; }
  .offset-sx-9 > * {
    margin-left: 75%; }
  .offset-sx-10 > * {
    margin-left: 83.33333%; }
  .offset-sx-11 > * {
    margin-left: 91.66667%; }
  .gx-sx-0 {
    --bs-gutter-x: 0rem; }
  .gy-sx-0 {
    --bs-gutter-y: 0rem; }
  .gx-sx-8 {
    --bs-gutter-x: 0.8rem; }
  .gy-sx-8 {
    --bs-gutter-y: 0.8rem; }
  .gx-sx-10 {
    --bs-gutter-x: 1rem; }
  .gy-sx-10 {
    --bs-gutter-y: 1rem; }
  .gx-sx-16 {
    --bs-gutter-x: 1.6rem; }
  .gy-sx-16 {
    --bs-gutter-y: 1.6rem; }
  .gx-sx-20 {
    --bs-gutter-x: 2rem; }
  .gy-sx-20 {
    --bs-gutter-y: 2rem; }
  .gx-sx-24 {
    --bs-gutter-x: 2.4rem; }
  .gy-sx-24 {
    --bs-gutter-y: 2.4rem; }
  .gx-sx-26 {
    --bs-gutter-x: 2.6rem; }
  .gy-sx-26 {
    --bs-gutter-y: 2.6rem; }
  .gx-sx-30 {
    --bs-gutter-x: 3rem; }
  .gy-sx-30 {
    --bs-gutter-y: 3rem; }
  .gx-sx-32 {
    --bs-gutter-x: 3.2rem; }
  .gy-sx-32 {
    --bs-gutter-y: 3.2rem; }
  .gx-sx-38 {
    --bs-gutter-x: 3.8rem; }
  .gy-sx-38 {
    --bs-gutter-y: 3.8rem; }
  .gx-sx-40 {
    --bs-gutter-x: 4rem; }
  .gy-sx-40 {
    --bs-gutter-y: 4rem; }
  .gx-sx-42 {
    --bs-gutter-x: 4.2rem; }
  .gy-sx-42 {
    --bs-gutter-y: 4.2rem; }
  .gx-sx-48 {
    --bs-gutter-x: 4.8rem; }
  .gy-sx-48 {
    --bs-gutter-y: 4.8rem; }
  .gx-sx-52 {
    --bs-gutter-x: 5.2rem; }
  .gy-sx-52 {
    --bs-gutter-y: 5.2rem; }
  .gx-sx-60 {
    --bs-gutter-x: 6rem; }
  .gy-sx-60 {
    --bs-gutter-y: 6rem; }
  .gx-sx-76 {
    --bs-gutter-x: 7.6rem; }
  .gy-sx-76 {
    --bs-gutter-y: 7.6rem; } }

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 > * {
    margin-left: 0%; }
  .offset-sm-1 > * {
    margin-left: 8.33333%; }
  .offset-sm-2 > * {
    margin-left: 16.66667%; }
  .offset-sm-3 > * {
    margin-left: 25%; }
  .offset-sm-4 > * {
    margin-left: 33.33333%; }
  .offset-sm-5 > * {
    margin-left: 41.66667%; }
  .offset-sm-6 > * {
    margin-left: 50%; }
  .offset-sm-7 > * {
    margin-left: 58.33333%; }
  .offset-sm-8 > * {
    margin-left: 66.66667%; }
  .offset-sm-9 > * {
    margin-left: 75%; }
  .offset-sm-10 > * {
    margin-left: 83.33333%; }
  .offset-sm-11 > * {
    margin-left: 91.66667%; }
  .gx-sm-0 {
    --bs-gutter-x: 0rem; }
  .gy-sm-0 {
    --bs-gutter-y: 0rem; }
  .gx-sm-8 {
    --bs-gutter-x: 0.8rem; }
  .gy-sm-8 {
    --bs-gutter-y: 0.8rem; }
  .gx-sm-10 {
    --bs-gutter-x: 1rem; }
  .gy-sm-10 {
    --bs-gutter-y: 1rem; }
  .gx-sm-16 {
    --bs-gutter-x: 1.6rem; }
  .gy-sm-16 {
    --bs-gutter-y: 1.6rem; }
  .gx-sm-20 {
    --bs-gutter-x: 2rem; }
  .gy-sm-20 {
    --bs-gutter-y: 2rem; }
  .gx-sm-24 {
    --bs-gutter-x: 2.4rem; }
  .gy-sm-24 {
    --bs-gutter-y: 2.4rem; }
  .gx-sm-26 {
    --bs-gutter-x: 2.6rem; }
  .gy-sm-26 {
    --bs-gutter-y: 2.6rem; }
  .gx-sm-30 {
    --bs-gutter-x: 3rem; }
  .gy-sm-30 {
    --bs-gutter-y: 3rem; }
  .gx-sm-32 {
    --bs-gutter-x: 3.2rem; }
  .gy-sm-32 {
    --bs-gutter-y: 3.2rem; }
  .gx-sm-38 {
    --bs-gutter-x: 3.8rem; }
  .gy-sm-38 {
    --bs-gutter-y: 3.8rem; }
  .gx-sm-40 {
    --bs-gutter-x: 4rem; }
  .gy-sm-40 {
    --bs-gutter-y: 4rem; }
  .gx-sm-42 {
    --bs-gutter-x: 4.2rem; }
  .gy-sm-42 {
    --bs-gutter-y: 4.2rem; }
  .gx-sm-48 {
    --bs-gutter-x: 4.8rem; }
  .gy-sm-48 {
    --bs-gutter-y: 4.8rem; }
  .gx-sm-52 {
    --bs-gutter-x: 5.2rem; }
  .gy-sm-52 {
    --bs-gutter-y: 5.2rem; }
  .gx-sm-60 {
    --bs-gutter-x: 6rem; }
  .gy-sm-60 {
    --bs-gutter-y: 6rem; }
  .gx-sm-76 {
    --bs-gutter-x: 7.6rem; }
  .gy-sm-76 {
    --bs-gutter-y: 7.6rem; } }

@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 > * {
    margin-left: 0%; }
  .offset-md-1 > * {
    margin-left: 8.33333%; }
  .offset-md-2 > * {
    margin-left: 16.66667%; }
  .offset-md-3 > * {
    margin-left: 25%; }
  .offset-md-4 > * {
    margin-left: 33.33333%; }
  .offset-md-5 > * {
    margin-left: 41.66667%; }
  .offset-md-6 > * {
    margin-left: 50%; }
  .offset-md-7 > * {
    margin-left: 58.33333%; }
  .offset-md-8 > * {
    margin-left: 66.66667%; }
  .offset-md-9 > * {
    margin-left: 75%; }
  .offset-md-10 > * {
    margin-left: 83.33333%; }
  .offset-md-11 > * {
    margin-left: 91.66667%; }
  .gx-md-0 {
    --bs-gutter-x: 0rem; }
  .gy-md-0 {
    --bs-gutter-y: 0rem; }
  .gx-md-8 {
    --bs-gutter-x: 0.8rem; }
  .gy-md-8 {
    --bs-gutter-y: 0.8rem; }
  .gx-md-10 {
    --bs-gutter-x: 1rem; }
  .gy-md-10 {
    --bs-gutter-y: 1rem; }
  .gx-md-16 {
    --bs-gutter-x: 1.6rem; }
  .gy-md-16 {
    --bs-gutter-y: 1.6rem; }
  .gx-md-20 {
    --bs-gutter-x: 2rem; }
  .gy-md-20 {
    --bs-gutter-y: 2rem; }
  .gx-md-24 {
    --bs-gutter-x: 2.4rem; }
  .gy-md-24 {
    --bs-gutter-y: 2.4rem; }
  .gx-md-26 {
    --bs-gutter-x: 2.6rem; }
  .gy-md-26 {
    --bs-gutter-y: 2.6rem; }
  .gx-md-30 {
    --bs-gutter-x: 3rem; }
  .gy-md-30 {
    --bs-gutter-y: 3rem; }
  .gx-md-32 {
    --bs-gutter-x: 3.2rem; }
  .gy-md-32 {
    --bs-gutter-y: 3.2rem; }
  .gx-md-38 {
    --bs-gutter-x: 3.8rem; }
  .gy-md-38 {
    --bs-gutter-y: 3.8rem; }
  .gx-md-40 {
    --bs-gutter-x: 4rem; }
  .gy-md-40 {
    --bs-gutter-y: 4rem; }
  .gx-md-42 {
    --bs-gutter-x: 4.2rem; }
  .gy-md-42 {
    --bs-gutter-y: 4.2rem; }
  .gx-md-48 {
    --bs-gutter-x: 4.8rem; }
  .gy-md-48 {
    --bs-gutter-y: 4.8rem; }
  .gx-md-52 {
    --bs-gutter-x: 5.2rem; }
  .gy-md-52 {
    --bs-gutter-y: 5.2rem; }
  .gx-md-60 {
    --bs-gutter-x: 6rem; }
  .gy-md-60 {
    --bs-gutter-y: 6rem; }
  .gx-md-76 {
    --bs-gutter-x: 7.6rem; }
  .gy-md-76 {
    --bs-gutter-y: 7.6rem; } }

@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 > * {
    margin-left: 0%; }
  .offset-lg-1 > * {
    margin-left: 8.33333%; }
  .offset-lg-2 > * {
    margin-left: 16.66667%; }
  .offset-lg-3 > * {
    margin-left: 25%; }
  .offset-lg-4 > * {
    margin-left: 33.33333%; }
  .offset-lg-5 > * {
    margin-left: 41.66667%; }
  .offset-lg-6 > * {
    margin-left: 50%; }
  .offset-lg-7 > * {
    margin-left: 58.33333%; }
  .offset-lg-8 > * {
    margin-left: 66.66667%; }
  .offset-lg-9 > * {
    margin-left: 75%; }
  .offset-lg-10 > * {
    margin-left: 83.33333%; }
  .offset-lg-11 > * {
    margin-left: 91.66667%; }
  .gx-lg-0 {
    --bs-gutter-x: 0rem; }
  .gy-lg-0 {
    --bs-gutter-y: 0rem; }
  .gx-lg-8 {
    --bs-gutter-x: 0.8rem; }
  .gy-lg-8 {
    --bs-gutter-y: 0.8rem; }
  .gx-lg-10 {
    --bs-gutter-x: 1rem; }
  .gy-lg-10 {
    --bs-gutter-y: 1rem; }
  .gx-lg-16 {
    --bs-gutter-x: 1.6rem; }
  .gy-lg-16 {
    --bs-gutter-y: 1.6rem; }
  .gx-lg-20 {
    --bs-gutter-x: 2rem; }
  .gy-lg-20 {
    --bs-gutter-y: 2rem; }
  .gx-lg-24 {
    --bs-gutter-x: 2.4rem; }
  .gy-lg-24 {
    --bs-gutter-y: 2.4rem; }
  .gx-lg-26 {
    --bs-gutter-x: 2.6rem; }
  .gy-lg-26 {
    --bs-gutter-y: 2.6rem; }
  .gx-lg-30 {
    --bs-gutter-x: 3rem; }
  .gy-lg-30 {
    --bs-gutter-y: 3rem; }
  .gx-lg-32 {
    --bs-gutter-x: 3.2rem; }
  .gy-lg-32 {
    --bs-gutter-y: 3.2rem; }
  .gx-lg-38 {
    --bs-gutter-x: 3.8rem; }
  .gy-lg-38 {
    --bs-gutter-y: 3.8rem; }
  .gx-lg-40 {
    --bs-gutter-x: 4rem; }
  .gy-lg-40 {
    --bs-gutter-y: 4rem; }
  .gx-lg-42 {
    --bs-gutter-x: 4.2rem; }
  .gy-lg-42 {
    --bs-gutter-y: 4.2rem; }
  .gx-lg-48 {
    --bs-gutter-x: 4.8rem; }
  .gy-lg-48 {
    --bs-gutter-y: 4.8rem; }
  .gx-lg-52 {
    --bs-gutter-x: 5.2rem; }
  .gy-lg-52 {
    --bs-gutter-y: 5.2rem; }
  .gx-lg-60 {
    --bs-gutter-x: 6rem; }
  .gy-lg-60 {
    --bs-gutter-y: 6rem; }
  .gx-lg-76 {
    --bs-gutter-x: 7.6rem; }
  .gy-lg-76 {
    --bs-gutter-y: 7.6rem; } }

@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 > * {
    margin-left: 0%; }
  .offset-xl-1 > * {
    margin-left: 8.33333%; }
  .offset-xl-2 > * {
    margin-left: 16.66667%; }
  .offset-xl-3 > * {
    margin-left: 25%; }
  .offset-xl-4 > * {
    margin-left: 33.33333%; }
  .offset-xl-5 > * {
    margin-left: 41.66667%; }
  .offset-xl-6 > * {
    margin-left: 50%; }
  .offset-xl-7 > * {
    margin-left: 58.33333%; }
  .offset-xl-8 > * {
    margin-left: 66.66667%; }
  .offset-xl-9 > * {
    margin-left: 75%; }
  .offset-xl-10 > * {
    margin-left: 83.33333%; }
  .offset-xl-11 > * {
    margin-left: 91.66667%; }
  .gx-xl-0 {
    --bs-gutter-x: 0rem; }
  .gy-xl-0 {
    --bs-gutter-y: 0rem; }
  .gx-xl-8 {
    --bs-gutter-x: 0.8rem; }
  .gy-xl-8 {
    --bs-gutter-y: 0.8rem; }
  .gx-xl-10 {
    --bs-gutter-x: 1rem; }
  .gy-xl-10 {
    --bs-gutter-y: 1rem; }
  .gx-xl-16 {
    --bs-gutter-x: 1.6rem; }
  .gy-xl-16 {
    --bs-gutter-y: 1.6rem; }
  .gx-xl-20 {
    --bs-gutter-x: 2rem; }
  .gy-xl-20 {
    --bs-gutter-y: 2rem; }
  .gx-xl-24 {
    --bs-gutter-x: 2.4rem; }
  .gy-xl-24 {
    --bs-gutter-y: 2.4rem; }
  .gx-xl-26 {
    --bs-gutter-x: 2.6rem; }
  .gy-xl-26 {
    --bs-gutter-y: 2.6rem; }
  .gx-xl-30 {
    --bs-gutter-x: 3rem; }
  .gy-xl-30 {
    --bs-gutter-y: 3rem; }
  .gx-xl-32 {
    --bs-gutter-x: 3.2rem; }
  .gy-xl-32 {
    --bs-gutter-y: 3.2rem; }
  .gx-xl-38 {
    --bs-gutter-x: 3.8rem; }
  .gy-xl-38 {
    --bs-gutter-y: 3.8rem; }
  .gx-xl-40 {
    --bs-gutter-x: 4rem; }
  .gy-xl-40 {
    --bs-gutter-y: 4rem; }
  .gx-xl-42 {
    --bs-gutter-x: 4.2rem; }
  .gy-xl-42 {
    --bs-gutter-y: 4.2rem; }
  .gx-xl-48 {
    --bs-gutter-x: 4.8rem; }
  .gy-xl-48 {
    --bs-gutter-y: 4.8rem; }
  .gx-xl-52 {
    --bs-gutter-x: 5.2rem; }
  .gy-xl-52 {
    --bs-gutter-y: 5.2rem; }
  .gx-xl-60 {
    --bs-gutter-x: 6rem; }
  .gy-xl-60 {
    --bs-gutter-y: 6rem; }
  .gx-xl-76 {
    --bs-gutter-x: 7.6rem; }
  .gy-xl-76 {
    --bs-gutter-y: 7.6rem; } }

@media (min-width: 1336px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 > * {
    margin-left: 0%; }
  .offset-xxl-1 > * {
    margin-left: 8.33333%; }
  .offset-xxl-2 > * {
    margin-left: 16.66667%; }
  .offset-xxl-3 > * {
    margin-left: 25%; }
  .offset-xxl-4 > * {
    margin-left: 33.33333%; }
  .offset-xxl-5 > * {
    margin-left: 41.66667%; }
  .offset-xxl-6 > * {
    margin-left: 50%; }
  .offset-xxl-7 > * {
    margin-left: 58.33333%; }
  .offset-xxl-8 > * {
    margin-left: 66.66667%; }
  .offset-xxl-9 > * {
    margin-left: 75%; }
  .offset-xxl-10 > * {
    margin-left: 83.33333%; }
  .offset-xxl-11 > * {
    margin-left: 91.66667%; }
  .gx-xxl-0 {
    --bs-gutter-x: 0rem; }
  .gy-xxl-0 {
    --bs-gutter-y: 0rem; }
  .gx-xxl-8 {
    --bs-gutter-x: 0.8rem; }
  .gy-xxl-8 {
    --bs-gutter-y: 0.8rem; }
  .gx-xxl-10 {
    --bs-gutter-x: 1rem; }
  .gy-xxl-10 {
    --bs-gutter-y: 1rem; }
  .gx-xxl-16 {
    --bs-gutter-x: 1.6rem; }
  .gy-xxl-16 {
    --bs-gutter-y: 1.6rem; }
  .gx-xxl-20 {
    --bs-gutter-x: 2rem; }
  .gy-xxl-20 {
    --bs-gutter-y: 2rem; }
  .gx-xxl-24 {
    --bs-gutter-x: 2.4rem; }
  .gy-xxl-24 {
    --bs-gutter-y: 2.4rem; }
  .gx-xxl-26 {
    --bs-gutter-x: 2.6rem; }
  .gy-xxl-26 {
    --bs-gutter-y: 2.6rem; }
  .gx-xxl-30 {
    --bs-gutter-x: 3rem; }
  .gy-xxl-30 {
    --bs-gutter-y: 3rem; }
  .gx-xxl-32 {
    --bs-gutter-x: 3.2rem; }
  .gy-xxl-32 {
    --bs-gutter-y: 3.2rem; }
  .gx-xxl-38 {
    --bs-gutter-x: 3.8rem; }
  .gy-xxl-38 {
    --bs-gutter-y: 3.8rem; }
  .gx-xxl-40 {
    --bs-gutter-x: 4rem; }
  .gy-xxl-40 {
    --bs-gutter-y: 4rem; }
  .gx-xxl-42 {
    --bs-gutter-x: 4.2rem; }
  .gy-xxl-42 {
    --bs-gutter-y: 4.2rem; }
  .gx-xxl-48 {
    --bs-gutter-x: 4.8rem; }
  .gy-xxl-48 {
    --bs-gutter-y: 4.8rem; }
  .gx-xxl-52 {
    --bs-gutter-x: 5.2rem; }
  .gy-xxl-52 {
    --bs-gutter-y: 5.2rem; }
  .gx-xxl-60 {
    --bs-gutter-x: 6rem; }
  .gy-xxl-60 {
    --bs-gutter-y: 6rem; }
  .gx-xxl-76 {
    --bs-gutter-x: 7.6rem; }
  .gy-xxl-76 {
    --bs-gutter-y: 7.6rem; } }

@media (min-width: 1500px) {
  .col-yl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-yl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-yl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-yl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-yl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-yl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-yl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-yl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-yl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-yl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-yl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-yl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-yl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-yl-0 > * {
    margin-left: 0%; }
  .offset-yl-1 > * {
    margin-left: 8.33333%; }
  .offset-yl-2 > * {
    margin-left: 16.66667%; }
  .offset-yl-3 > * {
    margin-left: 25%; }
  .offset-yl-4 > * {
    margin-left: 33.33333%; }
  .offset-yl-5 > * {
    margin-left: 41.66667%; }
  .offset-yl-6 > * {
    margin-left: 50%; }
  .offset-yl-7 > * {
    margin-left: 58.33333%; }
  .offset-yl-8 > * {
    margin-left: 66.66667%; }
  .offset-yl-9 > * {
    margin-left: 75%; }
  .offset-yl-10 > * {
    margin-left: 83.33333%; }
  .offset-yl-11 > * {
    margin-left: 91.66667%; }
  .gx-yl-0 {
    --bs-gutter-x: 0rem; }
  .gy-yl-0 {
    --bs-gutter-y: 0rem; }
  .gx-yl-8 {
    --bs-gutter-x: 0.8rem; }
  .gy-yl-8 {
    --bs-gutter-y: 0.8rem; }
  .gx-yl-10 {
    --bs-gutter-x: 1rem; }
  .gy-yl-10 {
    --bs-gutter-y: 1rem; }
  .gx-yl-16 {
    --bs-gutter-x: 1.6rem; }
  .gy-yl-16 {
    --bs-gutter-y: 1.6rem; }
  .gx-yl-20 {
    --bs-gutter-x: 2rem; }
  .gy-yl-20 {
    --bs-gutter-y: 2rem; }
  .gx-yl-24 {
    --bs-gutter-x: 2.4rem; }
  .gy-yl-24 {
    --bs-gutter-y: 2.4rem; }
  .gx-yl-26 {
    --bs-gutter-x: 2.6rem; }
  .gy-yl-26 {
    --bs-gutter-y: 2.6rem; }
  .gx-yl-30 {
    --bs-gutter-x: 3rem; }
  .gy-yl-30 {
    --bs-gutter-y: 3rem; }
  .gx-yl-32 {
    --bs-gutter-x: 3.2rem; }
  .gy-yl-32 {
    --bs-gutter-y: 3.2rem; }
  .gx-yl-38 {
    --bs-gutter-x: 3.8rem; }
  .gy-yl-38 {
    --bs-gutter-y: 3.8rem; }
  .gx-yl-40 {
    --bs-gutter-x: 4rem; }
  .gy-yl-40 {
    --bs-gutter-y: 4rem; }
  .gx-yl-42 {
    --bs-gutter-x: 4.2rem; }
  .gy-yl-42 {
    --bs-gutter-y: 4.2rem; }
  .gx-yl-48 {
    --bs-gutter-x: 4.8rem; }
  .gy-yl-48 {
    --bs-gutter-y: 4.8rem; }
  .gx-yl-52 {
    --bs-gutter-x: 5.2rem; }
  .gy-yl-52 {
    --bs-gutter-y: 5.2rem; }
  .gx-yl-60 {
    --bs-gutter-x: 6rem; }
  .gy-yl-60 {
    --bs-gutter-y: 6rem; }
  .gx-yl-76 {
    --bs-gutter-x: 7.6rem; }
  .gy-yl-76 {
    --bs-gutter-y: 7.6rem; } }

/*# sourceMappingURL=bootstrap-grid.css.map */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

:root {
  --cl-main: #303090;
  --cl-sub: #05ACEB;
  --cl-text: #171717BF;
  --cl-w: #FFF;
  --cl-b: #000;
  --cl-gray: #707070;
  --cl-black: #171717;
  --cl-lv-1: #0C1E72;
  --cl-lv-2: #1F6BB4;
  --cl-lv-3: #293D9C;
  --cl-lv-4: #8D8D8D;
  --cl-lv-5: #D1D1D1;
  --cl-lv-6: #D6F7FF;
  --cl-lv-7: #E5E5E5;
  --cl-lv-8: #F0F0F0;
  --cl-lv-9: #F1ECE5;
  --cl-lv-10: #F6F6F6;
  --cl-lv-11: #E6FFFF;
  --cl-overlay-1: #0000004D;
  --cl-overlay-2: #1B23357F;
  --cl-overlay-3: #0C1E7291; }

html, body {
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  font-family: "utm-avo", sans-serif;
  font-weight: 400; }

html {
  font-size: 52.5%; }
  @media (min-width: 768px) {
    html {
      font-size: 57.5%; } }
  @media (min-width: 1200px) {
    html {
      font-size: 62.5%; } }

body {
  background-color: var(--color-w);
  color: var(--color-text);
  line-height: 1.2;
  font-size: 1.44rem; }
  @media (min-width: 768px) {
    body {
      font-size: 1.47rem; } }
  @media (min-width: 1200px) {
    body {
      font-size: 1.5rem; } }
  @media (min-width: 1336px) {
    body {
      font-size: 1.6rem; } }

main {
  position: relative;
  height: auto;
  width: 100%; }

main,
#banner,
#bannerSub,
#breadcrumb {
  margin-left: auto;
  margin-right: auto; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

a[data-fancybox] img {
  cursor: zoom-in; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

.hidden {
  display: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

b,
strong {
  font-weight: bolder;
  font-weight: 700; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  text-decoration: none;
  background-color: transparent; }

pre,
code,
kbd,
samp {
  font-family: inherit;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  border-style: none;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center; }

img.svg {
  width: auto; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

*:focus,
*:active {
  outline: none; }

@font-face {
  font-family: "utm-avo";
  src: url("../fonts/UTM-Avo.ttf") format("truetype");
  font-weight: 400; }

@font-face {
  font-family: "utm-avo";
  src: url("../fonts/UTM-AvoBold.ttf") format("truetype");
  font-weight: 700; }

@font-face {
  font-family: "utm-avo";
  src: url("../fonts/UTM-AvoItalic.ttf") format("truetype");
  font-style: italic; }

@font-face {
  font-family: "utm-avo";
  src: url("../fonts/UTM-AvoBold_Italic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

.m-c {
  margin-left: auto;
  margin-right: auto; }

[class|="fx"] {
  display: flex; }

[class^="fx-c"] {
  justify-content: center; }

[class^="fx-l"] {
  justify-content: flex-start; }

[class^="fx-r"] {
  justify-content: flex-end; }

.fx-cc, .fx-lc, .fx-rc {
  align-items: center; }

.fx-cl, .fx-ll, .fx-rl {
  align-items: flex-start; }

.fx-lr, .fx-cr, .fx-rr {
  align-items: flex-end; }

.fx-ls, .fx-cs, .fx-rs {
  align-items: stretch; }

.fx-1 {
  flex: 1; }

.fx-col {
  flex-direction: column; }

.fx-row {
  flex-direction: row; }

.t-c {
  text-align: center; }

.t-l {
  text-align: left; }

.t-r {
  text-align: right; }

.uppercase {
  text-transform: uppercase; }

.bold {
  font-weight: bold; }

.italic {
  font-style: italic; }

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.scrollgsap {
  position: relative;
  transition: top .5s ease,opacity .1s ease;
  top: 150px;
  opacity: 0; }
  .scrollgsap.gsap-loaded {
    top: 0;
    opacity: 1; }

.of-scroll {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .of-scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 3px; }
  .of-scroll::-webkit-scrollbar-track {
    background: #EAEAEA; }
  .of-scroll::-webkit-scrollbar-thumb {
    background: var(--color-main); }
  .of-scroll::-webkit-scrollbar-thumb:hover {
    background: #5D5D5D; }

.appear-box {
  animation: appearBox .4s linear; }

.plc-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-7 {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-8 {
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-9 {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.plc-10 {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden; }

.cl-main {
  color: #303090; }

.cl-sub {
  color: #05ACEB; }

.cl-text {
  color: #171717BF; }

.cl-w {
  color: #FFF; }

.cl-b {
  color: #000; }

.cl-gray {
  color: #707070; }

.cl-black {
  color: #171717; }

.cl-lv-1 {
  color: #0C1E72; }

.cl-lv-2 {
  color: #1F6BB4; }

.cl-lv-3 {
  color: #293D9C; }

.cl-lv-4 {
  color: #8D8D8D; }

.cl-lv-5 {
  color: #D1D1D1; }

.cl-lv-6 {
  color: #D6F7FF; }

.cl-lv-7 {
  color: #E5E5E5; }

.cl-lv-8 {
  color: #F0F0F0; }

.cl-lv-9 {
  color: #F1ECE5; }

.cl-lv-10 {
  color: #F6F6F6; }

.cl-lv-11 {
  color: #E6FFFF; }

.cl-overlay-1 {
  color: #0000004D; }

.cl-overlay-2 {
  color: #1B23357F; }

.cl-overlay-3 {
  color: #0C1E7273; }

.cl-info {
  color: #00529B; }

.cl-info-bg {
  color: #BDE5F8; }

.cl-success {
  color: #4F8A10; }

.cl-success-bg {
  color: #DFF2BF; }

.cl-warning {
  color: #9F6000; }

.cl-warning-bg {
  color: #FEEFB3; }

.cl-error {
  color: #D8000C; }

.cl-error-bg {
  color: #FFBABA; }

.p-b24 {
  padding: 2rem 0; }
  @media (min-width: 768px) {
    .p-b24 {
      padding: 2.1rem 0; } }
  @media (min-width: 992px) {
    .p-b24 {
      padding: 2.2rem 0; } }
  @media (min-width: 1336px) {
    .p-b24 {
      padding: 2.4rem 0; } }

.pt-b24 {
  padding-top: 2rem; }
  @media (min-width: 768px) {
    .pt-b24 {
      padding-top: 2.1rem; } }
  @media (min-width: 992px) {
    .pt-b24 {
      padding-top: 2.2rem; } }
  @media (min-width: 1336px) {
    .pt-b24 {
      padding-top: 2.4rem; } }

.pb-b24 {
  padding-bottom: 2rem; }
  @media (min-width: 768px) {
    .pb-b24 {
      padding-bottom: 2.1rem; } }
  @media (min-width: 992px) {
    .pb-b24 {
      padding-bottom: 2.2rem; } }
  @media (min-width: 1336px) {
    .pb-b24 {
      padding-bottom: 2.4rem; } }

.m-b24 {
  margin: 2rem 0; }
  @media (min-width: 768px) {
    .m-b24 {
      margin: 2.1rem 0; } }
  @media (min-width: 992px) {
    .m-b24 {
      margin: 2.2rem 0; } }
  @media (min-width: 1336px) {
    .m-b24 {
      margin: 2.4rem 0; } }

.mt-b24 {
  margin-top: 2rem; }
  @media (min-width: 768px) {
    .mt-b24 {
      margin-top: 2.1rem; } }
  @media (min-width: 992px) {
    .mt-b24 {
      margin-top: 2.2rem; } }
  @media (min-width: 1336px) {
    .mt-b24 {
      margin-top: 2.4rem; } }

.mb-b24 {
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    .mb-b24 {
      margin-bottom: 2.1rem; } }
  @media (min-width: 992px) {
    .mb-b24 {
      margin-bottom: 2.2rem; } }
  @media (min-width: 1336px) {
    .mb-b24 {
      margin-bottom: 2.4rem; } }

.p-b40 {
  padding: 3.2rem 0; }
  @media (min-width: 768px) {
    .p-b40 {
      padding: 3.36rem 0; } }
  @media (min-width: 992px) {
    .p-b40 {
      padding: 3.6rem 0; } }
  @media (min-width: 1336px) {
    .p-b40 {
      padding: 4rem 0; } }

.pt-b40, .results-item:not(:first-child), .si-1__section form:not(:first-child) {
  padding-top: 3.2rem; }
  @media (min-width: 768px) {
    .pt-b40, .results-item:not(:first-child), .si-1__section form:not(:first-child) {
      padding-top: 3.36rem; } }
  @media (min-width: 992px) {
    .pt-b40, .results-item:not(:first-child), .si-1__section form:not(:first-child) {
      padding-top: 3.6rem; } }
  @media (min-width: 1336px) {
    .pt-b40, .results-item:not(:first-child), .si-1__section form:not(:first-child) {
      padding-top: 4rem; } }

.pb-b40 {
  padding-bottom: 3.2rem; }
  @media (min-width: 768px) {
    .pb-b40 {
      padding-bottom: 3.36rem; } }
  @media (min-width: 992px) {
    .pb-b40 {
      padding-bottom: 3.6rem; } }
  @media (min-width: 1336px) {
    .pb-b40 {
      padding-bottom: 4rem; } }

.m-b40 {
  margin: 3.2rem 0; }
  @media (min-width: 768px) {
    .m-b40 {
      margin: 3.36rem 0; } }
  @media (min-width: 992px) {
    .m-b40 {
      margin: 3.6rem 0; } }
  @media (min-width: 1336px) {
    .m-b40 {
      margin: 4rem 0; } }

.mt-b40 {
  margin-top: 3.2rem; }
  @media (min-width: 768px) {
    .mt-b40 {
      margin-top: 3.36rem; } }
  @media (min-width: 992px) {
    .mt-b40 {
      margin-top: 3.6rem; } }
  @media (min-width: 1336px) {
    .mt-b40 {
      margin-top: 4rem; } }

.mb-b40 {
  margin-bottom: 3.2rem; }
  @media (min-width: 768px) {
    .mb-b40 {
      margin-bottom: 3.36rem; } }
  @media (min-width: 992px) {
    .mb-b40 {
      margin-bottom: 3.6rem; } }
  @media (min-width: 1336px) {
    .mb-b40 {
      margin-bottom: 4rem; } }

.p-b42 {
  padding: 3.36rem 0; }
  @media (min-width: 768px) {
    .p-b42 {
      padding: 3.53rem 0; } }
  @media (min-width: 992px) {
    .p-b42 {
      padding: 3.78rem 0; } }
  @media (min-width: 1336px) {
    .p-b42 {
      padding: 4.2rem 0; } }

.pt-b42 {
  padding-top: 3.36rem; }
  @media (min-width: 768px) {
    .pt-b42 {
      padding-top: 3.53rem; } }
  @media (min-width: 992px) {
    .pt-b42 {
      padding-top: 3.78rem; } }
  @media (min-width: 1336px) {
    .pt-b42 {
      padding-top: 4.2rem; } }

.pb-b42 {
  padding-bottom: 3.36rem; }
  @media (min-width: 768px) {
    .pb-b42 {
      padding-bottom: 3.53rem; } }
  @media (min-width: 992px) {
    .pb-b42 {
      padding-bottom: 3.78rem; } }
  @media (min-width: 1336px) {
    .pb-b42 {
      padding-bottom: 4.2rem; } }

.m-b42 {
  margin: 3.36rem 0; }
  @media (min-width: 768px) {
    .m-b42 {
      margin: 3.53rem 0; } }
  @media (min-width: 992px) {
    .m-b42 {
      margin: 3.78rem 0; } }
  @media (min-width: 1336px) {
    .m-b42 {
      margin: 4.2rem 0; } }

.mt-b42 {
  margin-top: 3.36rem; }
  @media (min-width: 768px) {
    .mt-b42 {
      margin-top: 3.53rem; } }
  @media (min-width: 992px) {
    .mt-b42 {
      margin-top: 3.78rem; } }
  @media (min-width: 1336px) {
    .mt-b42 {
      margin-top: 4.2rem; } }

.mb-b42 {
  margin-bottom: 3.36rem; }
  @media (min-width: 768px) {
    .mb-b42 {
      margin-bottom: 3.53rem; } }
  @media (min-width: 992px) {
    .mb-b42 {
      margin-bottom: 3.78rem; } }
  @media (min-width: 1336px) {
    .mb-b42 {
      margin-bottom: 4.2rem; } }

.p-b50 {
  padding: 4rem 0; }
  @media (min-width: 768px) {
    .p-b50 {
      padding: 4.2rem 0; } }
  @media (min-width: 992px) {
    .p-b50 {
      padding: 4.5rem 0; } }
  @media (min-width: 1336px) {
    .p-b50 {
      padding: 5rem 0; } }

.pt-b50 {
  padding-top: 4rem; }
  @media (min-width: 768px) {
    .pt-b50 {
      padding-top: 4.2rem; } }
  @media (min-width: 992px) {
    .pt-b50 {
      padding-top: 4.5rem; } }
  @media (min-width: 1336px) {
    .pt-b50 {
      padding-top: 5rem; } }

.pb-b50 {
  padding-bottom: 4rem; }
  @media (min-width: 768px) {
    .pb-b50 {
      padding-bottom: 4.2rem; } }
  @media (min-width: 992px) {
    .pb-b50 {
      padding-bottom: 4.5rem; } }
  @media (min-width: 1336px) {
    .pb-b50 {
      padding-bottom: 5rem; } }

.m-b50 {
  margin: 4rem 0; }
  @media (min-width: 768px) {
    .m-b50 {
      margin: 4.2rem 0; } }
  @media (min-width: 992px) {
    .m-b50 {
      margin: 4.5rem 0; } }
  @media (min-width: 1336px) {
    .m-b50 {
      margin: 5rem 0; } }

.mt-b50 {
  margin-top: 4rem; }
  @media (min-width: 768px) {
    .mt-b50 {
      margin-top: 4.2rem; } }
  @media (min-width: 992px) {
    .mt-b50 {
      margin-top: 4.5rem; } }
  @media (min-width: 1336px) {
    .mt-b50 {
      margin-top: 5rem; } }

.mb-b50 {
  margin-bottom: 4rem; }
  @media (min-width: 768px) {
    .mb-b50 {
      margin-bottom: 4.2rem; } }
  @media (min-width: 992px) {
    .mb-b50 {
      margin-bottom: 4.5rem; } }
  @media (min-width: 1336px) {
    .mb-b50 {
      margin-bottom: 5rem; } }

.p-b56 {
  padding: 4.48rem 0; }
  @media (min-width: 768px) {
    .p-b56 {
      padding: 4.7rem 0; } }
  @media (min-width: 992px) {
    .p-b56 {
      padding: 5.04rem 0; } }
  @media (min-width: 1336px) {
    .p-b56 {
      padding: 5.6rem 0; } }

.pt-b56 {
  padding-top: 4.48rem; }
  @media (min-width: 768px) {
    .pt-b56 {
      padding-top: 4.7rem; } }
  @media (min-width: 992px) {
    .pt-b56 {
      padding-top: 5.04rem; } }
  @media (min-width: 1336px) {
    .pt-b56 {
      padding-top: 5.6rem; } }

.pb-b56 {
  padding-bottom: 4.48rem; }
  @media (min-width: 768px) {
    .pb-b56 {
      padding-bottom: 4.7rem; } }
  @media (min-width: 992px) {
    .pb-b56 {
      padding-bottom: 5.04rem; } }
  @media (min-width: 1336px) {
    .pb-b56 {
      padding-bottom: 5.6rem; } }

.m-b56 {
  margin: 4.48rem 0; }
  @media (min-width: 768px) {
    .m-b56 {
      margin: 4.7rem 0; } }
  @media (min-width: 992px) {
    .m-b56 {
      margin: 5.04rem 0; } }
  @media (min-width: 1336px) {
    .m-b56 {
      margin: 5.6rem 0; } }

.mt-b56 {
  margin-top: 4.48rem; }
  @media (min-width: 768px) {
    .mt-b56 {
      margin-top: 4.7rem; } }
  @media (min-width: 992px) {
    .mt-b56 {
      margin-top: 5.04rem; } }
  @media (min-width: 1336px) {
    .mt-b56 {
      margin-top: 5.6rem; } }

.mb-b56 {
  margin-bottom: 4.48rem; }
  @media (min-width: 768px) {
    .mb-b56 {
      margin-bottom: 4.7rem; } }
  @media (min-width: 992px) {
    .mb-b56 {
      margin-bottom: 5.04rem; } }
  @media (min-width: 1336px) {
    .mb-b56 {
      margin-bottom: 5.6rem; } }

.p-b60 {
  padding: 4.8rem 0; }
  @media (min-width: 768px) {
    .p-b60 {
      padding: 5.04rem 0; } }
  @media (min-width: 992px) {
    .p-b60 {
      padding: 5.4rem 0; } }
  @media (min-width: 1336px) {
    .p-b60 {
      padding: 6rem 0; } }

.pt-b60 {
  padding-top: 4.8rem; }
  @media (min-width: 768px) {
    .pt-b60 {
      padding-top: 5.04rem; } }
  @media (min-width: 992px) {
    .pt-b60 {
      padding-top: 5.4rem; } }
  @media (min-width: 1336px) {
    .pt-b60 {
      padding-top: 6rem; } }

.pb-b60 {
  padding-bottom: 4.8rem; }
  @media (min-width: 768px) {
    .pb-b60 {
      padding-bottom: 5.04rem; } }
  @media (min-width: 992px) {
    .pb-b60 {
      padding-bottom: 5.4rem; } }
  @media (min-width: 1336px) {
    .pb-b60 {
      padding-bottom: 6rem; } }

.m-b60 {
  margin: 4.8rem 0; }
  @media (min-width: 768px) {
    .m-b60 {
      margin: 5.04rem 0; } }
  @media (min-width: 992px) {
    .m-b60 {
      margin: 5.4rem 0; } }
  @media (min-width: 1336px) {
    .m-b60 {
      margin: 6rem 0; } }

.mt-b60 {
  margin-top: 4.8rem; }
  @media (min-width: 768px) {
    .mt-b60 {
      margin-top: 5.04rem; } }
  @media (min-width: 992px) {
    .mt-b60 {
      margin-top: 5.4rem; } }
  @media (min-width: 1336px) {
    .mt-b60 {
      margin-top: 6rem; } }

.mb-b60 {
  margin-bottom: 4.8rem; }
  @media (min-width: 768px) {
    .mb-b60 {
      margin-bottom: 5.04rem; } }
  @media (min-width: 992px) {
    .mb-b60 {
      margin-bottom: 5.4rem; } }
  @media (min-width: 1336px) {
    .mb-b60 {
      margin-bottom: 6rem; } }

.p-b80 {
  padding: 6.4rem 0; }
  @media (min-width: 768px) {
    .p-b80 {
      padding: 6.72rem 0; } }
  @media (min-width: 992px) {
    .p-b80 {
      padding: 7.2rem 0; } }
  @media (min-width: 1336px) {
    .p-b80 {
      padding: 8rem 0; } }

.pt-b80 {
  padding-top: 6.4rem; }
  @media (min-width: 768px) {
    .pt-b80 {
      padding-top: 6.72rem; } }
  @media (min-width: 992px) {
    .pt-b80 {
      padding-top: 7.2rem; } }
  @media (min-width: 1336px) {
    .pt-b80 {
      padding-top: 8rem; } }

.pb-b80 {
  padding-bottom: 6.4rem; }
  @media (min-width: 768px) {
    .pb-b80 {
      padding-bottom: 6.72rem; } }
  @media (min-width: 992px) {
    .pb-b80 {
      padding-bottom: 7.2rem; } }
  @media (min-width: 1336px) {
    .pb-b80 {
      padding-bottom: 8rem; } }

.m-b80 {
  margin: 6.4rem 0; }
  @media (min-width: 768px) {
    .m-b80 {
      margin: 6.72rem 0; } }
  @media (min-width: 992px) {
    .m-b80 {
      margin: 7.2rem 0; } }
  @media (min-width: 1336px) {
    .m-b80 {
      margin: 8rem 0; } }

.mt-b80 {
  margin-top: 6.4rem; }
  @media (min-width: 768px) {
    .mt-b80 {
      margin-top: 6.72rem; } }
  @media (min-width: 992px) {
    .mt-b80 {
      margin-top: 7.2rem; } }
  @media (min-width: 1336px) {
    .mt-b80 {
      margin-top: 8rem; } }

.mb-b80 {
  margin-bottom: 6.4rem; }
  @media (min-width: 768px) {
    .mb-b80 {
      margin-bottom: 6.72rem; } }
  @media (min-width: 992px) {
    .mb-b80 {
      margin-bottom: 7.2rem; } }
  @media (min-width: 1336px) {
    .mb-b80 {
      margin-bottom: 8rem; } }

.p-b100 {
  padding: 8rem 0; }
  @media (min-width: 768px) {
    .p-b100 {
      padding: 8.4rem 0; } }
  @media (min-width: 992px) {
    .p-b100 {
      padding: 9rem 0; } }
  @media (min-width: 1336px) {
    .p-b100 {
      padding: 10rem 0; } }

.pt-b100 {
  padding-top: 8rem; }
  @media (min-width: 768px) {
    .pt-b100 {
      padding-top: 8.4rem; } }
  @media (min-width: 992px) {
    .pt-b100 {
      padding-top: 9rem; } }
  @media (min-width: 1336px) {
    .pt-b100 {
      padding-top: 10rem; } }

.pb-b100 {
  padding-bottom: 8rem; }
  @media (min-width: 768px) {
    .pb-b100 {
      padding-bottom: 8.4rem; } }
  @media (min-width: 992px) {
    .pb-b100 {
      padding-bottom: 9rem; } }
  @media (min-width: 1336px) {
    .pb-b100 {
      padding-bottom: 10rem; } }

.m-b100 {
  margin: 8rem 0; }
  @media (min-width: 768px) {
    .m-b100 {
      margin: 8.4rem 0; } }
  @media (min-width: 992px) {
    .m-b100 {
      margin: 9rem 0; } }
  @media (min-width: 1336px) {
    .m-b100 {
      margin: 10rem 0; } }

.mt-b100 {
  margin-top: 8rem; }
  @media (min-width: 768px) {
    .mt-b100 {
      margin-top: 8.4rem; } }
  @media (min-width: 992px) {
    .mt-b100 {
      margin-top: 9rem; } }
  @media (min-width: 1336px) {
    .mt-b100 {
      margin-top: 10rem; } }

.mb-b100 {
  margin-bottom: 8rem; }
  @media (min-width: 768px) {
    .mb-b100 {
      margin-bottom: 8.4rem; } }
  @media (min-width: 992px) {
    .mb-b100 {
      margin-bottom: 9rem; } }
  @media (min-width: 1336px) {
    .mb-b100 {
      margin-bottom: 10rem; } }

.s60 {
  font-size: 3.8rem; }
  @media (min-width: 576px) {
    .s60 {
      font-size: 4.56rem; } }
  @media (min-width: 1200px) {
    .s60 {
      font-size: 5.16rem; } }
  @media (min-width: 1336px) {
    .s60 {
      font-size: 6rem; } }

.s60 {
  font-size: 3.8rem; }
  @media (min-width: 576px) {
    .s60 {
      font-size: 4.56rem; } }
  @media (min-width: 1200px) {
    .s60 {
      font-size: 5.16rem; } }
  @media (min-width: 1336px) {
    .s60 {
      font-size: 6rem; } }

.s60 {
  font-size: 3.8rem; }
  @media (min-width: 576px) {
    .s60 {
      font-size: 4.56rem; } }
  @media (min-width: 1200px) {
    .s60 {
      font-size: 5.16rem; } }
  @media (min-width: 1336px) {
    .s60 {
      font-size: 6rem; } }

.s36 {
  font-size: 2.95rem; }
  @media (min-width: 768px) {
    .s36 {
      font-size: 3.1rem; } }
  @media (min-width: 1200px) {
    .s36 {
      font-size: 3.24rem; } }
  @media (min-width: 1336px) {
    .s36 {
      font-size: 3.6rem; } }

.s36 {
  font-size: 2.95rem; }
  @media (min-width: 768px) {
    .s36 {
      font-size: 3.1rem; } }
  @media (min-width: 1200px) {
    .s36 {
      font-size: 3.24rem; } }
  @media (min-width: 1336px) {
    .s36 {
      font-size: 3.6rem; } }

.s36 {
  font-size: 2.95rem; }
  @media (min-width: 768px) {
    .s36 {
      font-size: 3.1rem; } }
  @media (min-width: 1200px) {
    .s36 {
      font-size: 3.24rem; } }
  @media (min-width: 1336px) {
    .s36 {
      font-size: 3.6rem; } }

.s34 {
  font-size: 2.79rem; }
  @media (min-width: 768px) {
    .s34 {
      font-size: 2.92rem; } }
  @media (min-width: 1200px) {
    .s34 {
      font-size: 3.06rem; } }
  @media (min-width: 1336px) {
    .s34 {
      font-size: 3.4rem; } }

.s34 {
  font-size: 2.79rem; }
  @media (min-width: 768px) {
    .s34 {
      font-size: 2.92rem; } }
  @media (min-width: 1200px) {
    .s34 {
      font-size: 3.06rem; } }
  @media (min-width: 1336px) {
    .s34 {
      font-size: 3.4rem; } }

.s34 {
  font-size: 2.79rem; }
  @media (min-width: 768px) {
    .s34 {
      font-size: 2.92rem; } }
  @media (min-width: 1200px) {
    .s34 {
      font-size: 3.06rem; } }
  @media (min-width: 1336px) {
    .s34 {
      font-size: 3.4rem; } }

.s32 {
  font-size: 2.62rem; }
  @media (min-width: 768px) {
    .s32 {
      font-size: 2.75rem; } }
  @media (min-width: 1200px) {
    .s32 {
      font-size: 2.88rem; } }
  @media (min-width: 1336px) {
    .s32 {
      font-size: 3.2rem; } }

.s32 {
  font-size: 2.62rem; }
  @media (min-width: 768px) {
    .s32 {
      font-size: 2.75rem; } }
  @media (min-width: 1200px) {
    .s32 {
      font-size: 2.88rem; } }
  @media (min-width: 1336px) {
    .s32 {
      font-size: 3.2rem; } }

.s32 {
  font-size: 2.62rem; }
  @media (min-width: 768px) {
    .s32 {
      font-size: 2.75rem; } }
  @media (min-width: 1200px) {
    .s32 {
      font-size: 2.88rem; } }
  @media (min-width: 1336px) {
    .s32 {
      font-size: 3.2rem; } }

.s30 {
  font-size: 2.5rem; }
  @media (min-width: 768px) {
    .s30 {
      font-size: 2.6rem; } }
  @media (min-width: 1200px) {
    .s30 {
      font-size: 2.7rem; } }
  @media (min-width: 1336px) {
    .s30 {
      font-size: 3rem; } }

.s30 {
  font-size: 2.5rem; }
  @media (min-width: 768px) {
    .s30 {
      font-size: 2.6rem; } }
  @media (min-width: 1200px) {
    .s30 {
      font-size: 2.7rem; } }
  @media (min-width: 1336px) {
    .s30 {
      font-size: 3rem; } }

.s30 {
  font-size: 2.5rem; }
  @media (min-width: 768px) {
    .s30 {
      font-size: 2.6rem; } }
  @media (min-width: 1200px) {
    .s30 {
      font-size: 2.7rem; } }
  @media (min-width: 1336px) {
    .s30 {
      font-size: 3rem; } }

.s28 {
  font-size: 2.52rem; }
  @media (min-width: 768px) {
    .s28 {
      font-size: 2.58rem; } }
  @media (min-width: 1200px) {
    .s28 {
      font-size: 2.63rem; } }
  @media (min-width: 1336px) {
    .s28 {
      font-size: 2.8rem; } }

.s28 {
  font-size: 2.52rem; }
  @media (min-width: 768px) {
    .s28 {
      font-size: 2.58rem; } }
  @media (min-width: 1200px) {
    .s28 {
      font-size: 2.63rem; } }
  @media (min-width: 1336px) {
    .s28 {
      font-size: 2.8rem; } }

.s28 {
  font-size: 2.52rem; }
  @media (min-width: 768px) {
    .s28 {
      font-size: 2.58rem; } }
  @media (min-width: 1200px) {
    .s28 {
      font-size: 2.63rem; } }
  @media (min-width: 1336px) {
    .s28 {
      font-size: 2.8rem; } }

.s24 {
  font-size: 2.16rem; }
  @media (min-width: 768px) {
    .s24 {
      font-size: 2.21rem; } }
  @media (min-width: 1200px) {
    .s24 {
      font-size: 2.26rem; } }
  @media (min-width: 1336px) {
    .s24 {
      font-size: 2.4rem; } }

.s24 {
  font-size: 2.16rem; }
  @media (min-width: 768px) {
    .s24 {
      font-size: 2.21rem; } }
  @media (min-width: 1200px) {
    .s24 {
      font-size: 2.26rem; } }
  @media (min-width: 1336px) {
    .s24 {
      font-size: 2.4rem; } }

.s24 {
  font-size: 2.16rem; }
  @media (min-width: 768px) {
    .s24 {
      font-size: 2.21rem; } }
  @media (min-width: 1200px) {
    .s24 {
      font-size: 2.26rem; } }
  @media (min-width: 1336px) {
    .s24 {
      font-size: 2.4rem; } }

.s22 {
  font-size: 1.98rem; }
  @media (min-width: 768px) {
    .s22 {
      font-size: 2.02rem; } }
  @media (min-width: 1200px) {
    .s22 {
      font-size: 2.21rem; } }
  @media (min-width: 1336px) {
    .s22 {
      font-size: 2.2rem; } }

.s22 {
  font-size: 1.98rem; }
  @media (min-width: 768px) {
    .s22 {
      font-size: 2.02rem; } }
  @media (min-width: 1200px) {
    .s22 {
      font-size: 2.21rem; } }
  @media (min-width: 1336px) {
    .s22 {
      font-size: 2.2rem; } }

.s22 {
  font-size: 1.98rem; }
  @media (min-width: 768px) {
    .s22 {
      font-size: 2.02rem; } }
  @media (min-width: 1200px) {
    .s22 {
      font-size: 2.21rem; } }
  @media (min-width: 1336px) {
    .s22 {
      font-size: 2.2rem; } }

.s20 {
  font-size: 1.8rem; }
  @media (min-width: 768px) {
    .s20 {
      font-size: 1.84rem; } }
  @media (min-width: 1200px) {
    .s20 {
      font-size: 1.88rem; } }
  @media (min-width: 1336px) {
    .s20 {
      font-size: 2rem; } }

.s20 {
  font-size: 1.8rem; }
  @media (min-width: 768px) {
    .s20 {
      font-size: 1.84rem; } }
  @media (min-width: 1200px) {
    .s20 {
      font-size: 1.88rem; } }
  @media (min-width: 1336px) {
    .s20 {
      font-size: 2rem; } }

.s20 {
  font-size: 1.8rem; }
  @media (min-width: 768px) {
    .s20 {
      font-size: 1.84rem; } }
  @media (min-width: 1200px) {
    .s20 {
      font-size: 1.88rem; } }
  @media (min-width: 1336px) {
    .s20 {
      font-size: 2rem; } }

.s18 {
  font-size: 1.62rem; }
  @media (min-width: 768px) {
    .s18 {
      font-size: 1.66rem; } }
  @media (min-width: 1200px) {
    .s18 {
      font-size: 1.7rem; } }
  @media (min-width: 1336px) {
    .s18 {
      font-size: 1.8rem; } }

.s18 {
  font-size: 1.62rem; }
  @media (min-width: 768px) {
    .s18 {
      font-size: 1.66rem; } }
  @media (min-width: 1200px) {
    .s18 {
      font-size: 1.7rem; } }
  @media (min-width: 1336px) {
    .s18 {
      font-size: 1.8rem; } }

.s18 {
  font-size: 1.62rem; }
  @media (min-width: 768px) {
    .s18 {
      font-size: 1.66rem; } }
  @media (min-width: 1200px) {
    .s18 {
      font-size: 1.7rem; } }
  @media (min-width: 1336px) {
    .s18 {
      font-size: 1.8rem; } }

.s16 {
  font-size: 1.44rem; }
  @media (min-width: 768px) {
    .s16 {
      font-size: 1.47rem; } }
  @media (min-width: 1200px) {
    .s16 {
      font-size: 1.5rem; } }
  @media (min-width: 1336px) {
    .s16 {
      font-size: 1.6rem; } }

.s16 {
  font-size: 1.44rem; }
  @media (min-width: 768px) {
    .s16 {
      font-size: 1.47rem; } }
  @media (min-width: 1200px) {
    .s16 {
      font-size: 1.5rem; } }
  @media (min-width: 1336px) {
    .s16 {
      font-size: 1.6rem; } }

.s16 {
  font-size: 1.44rem; }
  @media (min-width: 768px) {
    .s16 {
      font-size: 1.47rem; } }
  @media (min-width: 1200px) {
    .s16 {
      font-size: 1.5rem; } }
  @media (min-width: 1336px) {
    .s16 {
      font-size: 1.6rem; } }

.s15 {
  font-size: 1.35rem; }
  @media (min-width: 768px) {
    .s15 {
      font-size: 1.38rem; } }
  @media (min-width: 1200px) {
    .s15 {
      font-size: 1.41rem; } }
  @media (min-width: 1336px) {
    .s15 {
      font-size: 1.5rem; } }

.s15 {
  font-size: 1.35rem; }
  @media (min-width: 768px) {
    .s15 {
      font-size: 1.38rem; } }
  @media (min-width: 1200px) {
    .s15 {
      font-size: 1.41rem; } }
  @media (min-width: 1336px) {
    .s15 {
      font-size: 1.5rem; } }

.s15 {
  font-size: 1.35rem; }
  @media (min-width: 768px) {
    .s15 {
      font-size: 1.38rem; } }
  @media (min-width: 1200px) {
    .s15 {
      font-size: 1.41rem; } }
  @media (min-width: 1336px) {
    .s15 {
      font-size: 1.5rem; } }

.s14 {
  font-size: 1.34rem; }
  @media (min-width: 768px) {
    .s14 {
      font-size: 1.35rem; } }
  @media (min-width: 1200px) {
    .s14 {
      font-size: 1.38rem; } }
  @media (min-width: 1336px) {
    .s14 {
      font-size: 1.4rem; } }

.s14 {
  font-size: 1.34rem; }
  @media (min-width: 768px) {
    .s14 {
      font-size: 1.35rem; } }
  @media (min-width: 1200px) {
    .s14 {
      font-size: 1.38rem; } }
  @media (min-width: 1336px) {
    .s14 {
      font-size: 1.4rem; } }

.s14 {
  font-size: 1.34rem; }
  @media (min-width: 768px) {
    .s14 {
      font-size: 1.35rem; } }
  @media (min-width: 1200px) {
    .s14 {
      font-size: 1.38rem; } }
  @media (min-width: 1336px) {
    .s14 {
      font-size: 1.4rem; } }

.animate__bounce {
  animation-name: bounce;
  animation-duration: 1s;
  transform-origin: center bottom; }

.wrapper-content {
  color: #8D8D8D;
  font-size: 1.62rem; }
  @media (min-width: 768px) {
    .wrapper-content {
      font-size: 1.66rem; } }
  @media (min-width: 1200px) {
    .wrapper-content {
      font-size: 1.7rem; } }
  @media (min-width: 1336px) {
    .wrapper-content {
      font-size: 1.8rem; } }
  .wrapper-content h1 {
    font-size: 2.62rem;
    color: #171717;
    line-height: 1.6; }
    @media (min-width: 768px) {
      .wrapper-content h1 {
        font-size: 2.75rem; } }
    @media (min-width: 1200px) {
      .wrapper-content h1 {
        font-size: 2.88rem; } }
    @media (min-width: 1336px) {
      .wrapper-content h1 {
        font-size: 3.2rem; } }
  .wrapper-content h2 {
    font-size: 2.16rem;
    color: #707070;
    line-height: 1.4; }
    @media (min-width: 768px) {
      .wrapper-content h2 {
        font-size: 2.21rem; } }
    @media (min-width: 1200px) {
      .wrapper-content h2 {
        font-size: 2.26rem; } }
    @media (min-width: 1336px) {
      .wrapper-content h2 {
        font-size: 2.4rem; } }
  .wrapper-content p {
    color: currentColor;
    font-size: 1.62rem;
    line-height: 1.4; }
    @media (min-width: 768px) {
      .wrapper-content p {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .wrapper-content p {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .wrapper-content p {
        font-size: 1.8rem; } }
  .wrapper-content time {
    font-size: 1.62rem;
    font-style: italic;
    color: var(--cl-main); }
    @media (min-width: 768px) {
      .wrapper-content time {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .wrapper-content time {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .wrapper-content time {
        font-size: 1.8rem; } }
  .wrapper-content img {
    display: block; }
  .wrapper-content > *:not(:first-child) {
    margin-top: 2rem; }
  .wrapper-content ul {
    list-style-type: none; }
    .wrapper-content ul li {
      line-height: 1.6; }

.submit-now {
  margin-top: 2rem; }
  .submit-now > * {
    display: inline-block;
    padding: 1.4rem 2rem;
    font-size: 1.44rem;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--cl-w);
    background-color: var(--cl-sub);
    border-radius: 10px;
    transition: .3s all ease-in-out; }
    @media (min-width: 768px) {
      .submit-now > * {
        font-size: 1.47rem; } }
    @media (min-width: 1200px) {
      .submit-now > * {
        font-size: 1.5rem; } }
    @media (min-width: 1336px) {
      .submit-now > * {
        font-size: 1.6rem; } }
    .submit-now > *:hover {
      background-color: var(--cl-main); }

.playYtb {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  transform-box: fill-box;
  transition: .3s all;
  display: block;
  width: 14%;
  padding-top: 14%;
  height: 0;
  border-radius: 50%;
  opacity: .6; }
  @media (min-width: 1200px) {
    .playYtb {
      width: 8.8rem;
      height: 8.8rem;
      padding-top: 0; } }
  .playYtb img,
  .playYtb svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    pointer-events: none; }
  .playYtb .arrowPlay {
    transition: .3s all;
    fill: #d1d1d1; }
  .playYtb:hover {
    opacity: 1 !important;
    animation: grow 1s infinite alternate linear; }
    .playYtb:hover .arrowPlay {
      fill: var(--cl-main); }

.content-line *, .content-line p {
  color: var(--cl-text); }

.content-line p {
  white-space: pre-wrap;
  line-height: 1.23; }

.content-line img {
  width: 100%;
  height: auto; }

.content-line p.note {
  text-align: center;
  width: 90%;
  font-weight: 400; }

.content-line img ~ p.note {
  margin-top: 8px; }

.content-line .head {
  text-transform: uppercase;
  color: #707070;
  font-weight: 700;
  font-size: 2.16rem; }
  @media (min-width: 768px) {
    .content-line .head {
      font-size: 2.21rem; } }
  @media (min-width: 1200px) {
    .content-line .head {
      font-size: 2.26rem; } }
  @media (min-width: 1336px) {
    .content-line .head {
      font-size: 2.4rem; } }

.content-line .bold {
  font-weight: 700; }

.content-line .table-content > * p {
  height: 100%;
  padding: 16px 12px;
  text-transform: uppercase;
  font-size: 1.62rem;
  font-weight: 400;
  background-color: #F5F5F5;
  text-align: center; }
  @media (min-width: 768px) {
    .content-line .table-content > * p {
      font-size: 1.66rem; } }
  @media (min-width: 1200px) {
    .content-line .table-content > * p {
      font-size: 1.7rem; } }
  @media (min-width: 1336px) {
    .content-line .table-content > * p {
      font-size: 1.8rem; } }

.content-line .table-content > * img {
  height: 100%; }

.content-line .table-content img {
  height: 100%; }

.content-line .table-content + .table {
  margin-top: 0 !important; }

.content-line ul {
  list-style-type: none; }
  .content-line ul li {
    font-size: 1.62rem;
    padding-left: 20px;
    position: relative; }
    @media (min-width: 768px) {
      .content-line ul li {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .content-line ul li {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .content-line ul li {
        font-size: 1.8rem; } }
    .content-line ul li::after {
      content: "";
      position: absolute;
      left: 0;
      transform: translate(0, -50%);
      background-color: var(--cl-main);
      border-radius: 50%;
      --rLi: 6px;
      --tLi: 10px;
      top: var(--tLi);
      width: var(--rLi);
      height: var(--rLi); }
      @media (min-width: 768px) {
        .content-line ul li::after {
          --tLi: 10px;
          --rLi: 6px; } }
      @media (min-width: 1200px) {
        .content-line ul li::after {
          --tLi: 11px;
          --rLi: 8px; } }

.social-contact {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: -12px;
  margin-left: -8px;
  margin-right: -8px; }
  .social-contact__item {
    display: block;
    padding-top: 12px;
    padding-left: 8px;
    padding-right: 8px; }
    .social-contact__item-link {
      transition: .3s all linear;
      cursor: pointer;
      position: relative; }
      .social-contact__item-link svg path {
        transition: .3s all linear; }

.wrapper-footer {
  display: flex;
  flex-wrap: wrap; }
  .wrapper-footer .tag {
    flex: 0 0 100%;
    width: 100%; }
  .wrapper-footer .sourceAuthor {
    flex: 0 0 100%;
    margin-top: auto; }
  .wrapper-footer .socials {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    flex: 0 0 100%;
    width: 100%; }
    .wrapper-footer .socials p {
      color: var(--cl-main);
      font-size: 1.62rem; }
      @media (min-width: 768px) {
        .wrapper-footer .socials p {
          font-size: 1.66rem; } }
      @media (min-width: 1200px) {
        .wrapper-footer .socials p {
          font-size: 1.7rem; } }
      @media (min-width: 1336px) {
        .wrapper-footer .socials p {
          font-size: 1.8rem; } }
    .wrapper-footer .socials-item {
      margin-left: 12px;
      border-radius: 50%;
      cursor: pointer; }
      @media (min-width: 768px) {
        .wrapper-footer .socials-item {
          margin-left: 18px; } }
      .wrapper-footer .socials-item:hover {
        opacity: .8; }
      .wrapper-footer .socials-item > * {
        user-select: none;
        pointer-events: none; }
  .wrapper-footer > *:not(:first-child) {
    margin-top: 20px; }
    @media (min-width: 768px) {
      .wrapper-footer > *:not(:first-child) {
        margin-top: 30px; } }

.sourceAuthor {
  color: var(--cl-main);
  font-size: 1.62rem;
  font-style: italic; }
  @media (min-width: 768px) {
    .sourceAuthor {
      font-size: 1.66rem; } }
  @media (min-width: 1200px) {
    .sourceAuthor {
      font-size: 1.7rem; } }
  @media (min-width: 1336px) {
    .sourceAuthor {
      font-size: 1.8rem; } }
  .sourceAuthor a {
    display: inline-block;
    color: var(--cl-main);
    font-size: 1.62rem;
    font-style: italic; }
    @media (min-width: 768px) {
      .sourceAuthor a {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .sourceAuthor a {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .sourceAuthor a {
        font-size: 1.8rem; } }

.tag {
  display: inline-block;
  width: 100%;
  font-size: 1.98rem;
  color: var(--cl-black); }
  @media (min-width: 768px) {
    .tag {
      font-size: 2.02rem; } }
  @media (min-width: 1200px) {
    .tag {
      font-size: 2.21rem; } }
  @media (min-width: 1336px) {
    .tag {
      font-size: 2.2rem; } }
  .tag > p {
    display: inline-block; }
  .tag-list a + a {
    position: relative; }
    .tag-list a + a::before {
      display: inline;
      content: ", "; }
  .tag-list a {
    font-size: 1.98rem;
    color: var(--cl-black);
    transition: .16s all; }
    @media (min-width: 768px) {
      .tag-list a {
        font-size: 2.02rem; } }
    @media (min-width: 1200px) {
      .tag-list a {
        font-size: 2.21rem; } }
    @media (min-width: 1336px) {
      .tag-list a {
        font-size: 2.2rem; } }
    .tag-list a:hover {
      color: var(--cl-main); }

.copyMaker {
  position: relative; }
  .copyMaker:before {
    content: "";
    width: 16px;
    height: 16px;
    bottom: 4px;
    clip-path: polygon(50% 40%, 0% 100%, 100% 100%); }
  .copyMaker:after {
    content: attr(tooltip);
    width: auto;
    bottom: -8px;
    white-space: nowrap;
    padding: 5px;
    border-radius: 4px;
    font-size: 10px; }
  .copyMaker:before, .copyMaker:after {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 50%;
    box-sizing: border-box;
    background-color: #05aceb;
    color: white;
    transform: translate(-50%, 0%);
    transition: all 300ms ease; }
  .copyMaker:hover:before, .copyMaker:hover:after {
    opacity: 1;
    transform: translate(-50%, 100%); }

.fixed-phone {
  position: fixed;
  bottom: 8vh;
  left: 2vw;
  z-index: 1001;
  display: flex;
  align-items: center; }
  .fixed-phone .phone-i {
    display: block;
    margin: 0;
    width: 1em;
    height: 1em;
    font-size: 40px;
    z-index: 2;
    background-color: var(--cl-sub);
    border-radius: 0.5em;
    box-shadow: 0 0 0 0em rgba(var(--cl-sub), 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
    transform: translate3d(0, 0, 0) scale(1); }
    .fixed-phone .phone-i::before, .fixed-phone .phone-i::after {
      position: absolute;
      content: ""; }
    .fixed-phone .phone-i::before {
      top: 0;
      left: 0;
      width: 1em;
      height: 1em;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 100%;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(0); }
    .fixed-phone .phone-i::after {
      top: 0.25em;
      left: 0.25em;
      width: 0.5em;
      height: 0.5em;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z'/%3E%3C/svg%3E");
      fill: white;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      transform: translate3d(0, 0, 0); }
    .fixed-phone .phone-i.is-animating {
      animation: phone-outer 3000ms infinite; }
      .fixed-phone .phone-i.is-animating::before {
        animation: phone-inner 3000ms infinite; }
      .fixed-phone .phone-i.is-animating::after {
        animation: phone-icon 3000ms infinite; }
  .fixed-phone .phone-text {
    display: none; }
    @media (min-width: 768px) {
      .fixed-phone .phone-text {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 4px 20px 4px 60px;
        max-width: 40px;
        z-index: 1;
        font-size: 16px;
        font-weight: 700;
        white-space: nowrap;
        display: flex;
        align-items: center;
        border-radius: 100px;
        background-color: var(--cl-sub);
        opacity: 1;
        transition: .16s all linear;
        visibility: hidden;
        color: #FFF;
        clip-path: polygon(0 0, 0 0%, 0 100%, 0% 100%); } }
  .fixed-phone:hover .phone-text {
    visibility: visible;
    max-width: initial;
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); }

@keyframes appearBox {
  from {
    transform: translateY(100px);
    opacity: 0; }
  to {
    opacity: 1;
    transform: translateY(0); } }

@keyframes grow {
  from {
    box-shadow: 0px 0px 1em var(--cl-main); }
  to {
    box-shadow: 0px 0px 3em var(--cl-main); } }

@keyframes rotateLogoInfinite {
  to {
    transform: rotate(359deg); } }

@keyframes _360-infinite {
  to {
    transform: translate(14.3rem, 10.4rem) rotate(359deg); } }

@keyframes _360-infinite-btn {
  to {
    transform: translate(-50%, -50%) rotate(359deg); } }

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0) scaleY(1.1);
    transform: translate3d(0, -5px, 0) scaleY(1.1); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -2px, 0) scaleY(1.05);
    transform: translate3d(0, -2px, 0) scaleY(1.05); }
  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0) scaleY(0.95);
    transform: translateZ(0) scaleY(0.95); }
  90% {
    -webkit-transform: translate3d(0, -1px, 0) scaleY(1.02);
    transform: translate3d(0, -1px, 0) scaleY(1.02); } }

@keyframes bounceDot {
  0%,
  20%,
  53%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0);
    transform: translateZ(0); }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -5px, 0) scaleY(1.1);
    transform: translate3d(0, -5px, 0) scaleY(1.1); }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -2px, 0) scaleY(1.05);
    transform: translate3d(0, -2px, 0) scaleY(1.05); }
  80% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translateZ(0) scaleY(0.95);
    transform: translateZ(0) scaleY(0.95); } }

@keyframes modalPopup {
  to {
    transform: scale(1) rotate(0); } }

@keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(var(--cl-sub), 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2); }
  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow: 0 0 0 0em rgba(var(--cl-sub), 0.1), 0em 0.05em 0.1em rgba(0, 0, 0, 0.5); }
  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0.5em rgba(var(--cl-sub), 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2); }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(var(--cl-sub), 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2); } }

@keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0); }
  33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9); }
  66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0); }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0); } }

@keyframes phone-icon {
  0% {
    transform: translate3d(0em, 0, 0); }
  2% {
    transform: translate3d(0.01em, 0, 0); }
  4% {
    transform: translate3d(-0.01em, 0, 0); }
  6% {
    transform: translate3d(0.01em, 0, 0); }
  8% {
    transform: translate3d(-0.01em, 0, 0); }
  10% {
    transform: translate3d(0.01em, 0, 0); }
  12% {
    transform: translate3d(-0.01em, 0, 0); }
  14% {
    transform: translate3d(0.01em, 0, 0); }
  16% {
    transform: translate3d(-0.01em, 0, 0); }
  18% {
    transform: translate3d(0.01em, 0, 0); }
  20% {
    transform: translate3d(-0.01em, 0, 0); }
  22% {
    transform: translate3d(0.01em, 0, 0); }
  24% {
    transform: translate3d(-0.01em, 0, 0); }
  26% {
    transform: translate3d(0.01em, 0, 0); }
  28% {
    transform: translate3d(-0.01em, 0, 0); }
  30% {
    transform: translate3d(0.01em, 0, 0); }
  32% {
    transform: translate3d(-0.01em, 0, 0); }
  34% {
    transform: translate3d(0.01em, 0, 0); }
  36% {
    transform: translate3d(-0.01em, 0, 0); }
  38% {
    transform: translate3d(0.01em, 0, 0); }
  40% {
    transform: translate3d(-0.01em, 0, 0); }
  42% {
    transform: translate3d(0.01em, 0, 0); }
  44% {
    transform: translate3d(-0.01em, 0, 0); }
  46% {
    transform: translate3d(0em, 0, 0); } }

.braney-title > * {
  font-weight: 700;
  font-size: 2.62rem;
  color: var(--cl-sub);
  line-height: 1.4; }
  @media (min-width: 768px) {
    .braney-title > * {
      font-size: 2.75rem; } }
  @media (min-width: 1200px) {
    .braney-title > * {
      font-size: 2.88rem; } }
  @media (min-width: 1336px) {
    .braney-title > * {
      font-size: 3.2rem; } }

.braney-title.center {
  text-align: center; }

.braney-subtitle {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center; }
  .braney-subtitle.center {
    justify-content: center;
    text-align: center; }
  .braney-subtitle > * {
    font-weight: 700;
    font-size: 1.62rem;
    text-transform: uppercase;
    color: var(--cl-main);
    text-align: center; }
    @media (min-width: 768px) {
      .braney-subtitle > * {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .braney-subtitle > * {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .braney-subtitle > * {
        font-size: 1.8rem; } }
  .braney-subtitle path {
    fill: var(--cl-main); }
  .braney-subtitle > *:not(:first-child) {
    margin-left: .6rem; }
  @media (min-width: 768px) {
    .braney-subtitle {
      justify-content: flex-start;
      text-align: left; } }

.braney-subtitle ~ .braney-title {
  margin-top: 2rem; }

.braney-desc {
  font-size: 1.8rem;
  color: var(--cl-text); }
  @media (min-width: 768px) {
    .braney-desc {
      font-size: 1.84rem; } }
  @media (min-width: 1200px) {
    .braney-desc {
      font-size: 1.88rem; } }
  @media (min-width: 1336px) {
    .braney-desc {
      font-size: 2rem; } }

.braney-more > * {
  font-weight: 700;
  font-size: 1.44rem;
  color: var(--cl-black);
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  transition: .3s all; }
  @media (min-width: 768px) {
    .braney-more > * {
      font-size: 1.47rem; } }
  @media (min-width: 1200px) {
    .braney-more > * {
      font-size: 1.5rem; } }
  @media (min-width: 1336px) {
    .braney-more > * {
      font-size: 1.6rem; } }
  .braney-more > * svg {
    margin-right: .6rem;
    transition: .3s all; }
  .braney-more > *:hover {
    color: var(--cl-sub) !important; }
    .braney-more > *:hover svg {
      transform: rotate(-45deg); }

form {
  max-width: 100%;
  overflow: hidden; }

.box-intro {
  width: 100%;
  height: 100%;
  background-color: var(--cl-w);
  padding: 2rem 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: linear-gradient(to right, var(--cl-lv-2) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--cl-lv-2) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, var(--cl-lv-2) 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--cl-lv-2) 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 0px 2px, 2px 0px;
  color: var(--cl-gray);
  transition: 1.6s all;
  position: relative; }
  .box-intro::after {
    content: "";
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 2rem;
    height: 2rem;
    transform: rotate(-360deg) scale(0);
    transform-origin: center;
    transition: 1.6s all;
    background-color: var(--cl-sub); }
  .box-intro:hover {
    background-size: 20px 2px, 2px 20px;
    color: var(--cl-b); }
    .box-intro:hover .box-intro__icon path[fill] {
      fill: #05ACEB; }
    .box-intro:hover .box-intro__icon path[stroke] {
      stroke: #05ACEB; }
    .box-intro:hover .box-intro__title > * {
      color: var(--cl-b); }
    .box-intro:hover::after {
      transform: rotate(0deg) scale(1); }
  .box-intro > *:not(:first-child) {
    margin-top: 1.2rem; }
  .box-intro__icon path {
    transition: .3s all; }
    .box-intro__icon path[fill] {
      fill: #1F6BB4; }
    .box-intro__icon path[stroke] {
      stroke: #1F6BB4; }
  .box-intro__title {
    text-align: center; }
    .box-intro__title > * {
      font-size: 1.8rem;
      color: var(--cl-gray);
      font-weight: 700;
      transition: .3s all; }
      @media (min-width: 768px) {
        .box-intro__title > * {
          font-size: 1.84rem; } }
      @media (min-width: 1200px) {
        .box-intro__title > * {
          font-size: 1.88rem; } }
      @media (min-width: 1336px) {
        .box-intro__title > * {
          font-size: 2rem; } }

.box-service {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 1.6rem;
  background-color: var(--cl-lv-3);
  color: var(--cl-w);
  position: relative;
  overflow: hidden; }
  @media (min-width: 992px) {
    .box-service {
      max-width: initial;
      padding: 4.2rem 2.8rem; } }
  .box-service::before {
    --wh: 40rem;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: var(--wh);
    height: var(--wh);
    background: transparent;
    border-radius: var(--wh);
    transform: translate(14.3rem, 10.4rem);
    border: 2px dashed #fff;
    box-shadow: inset 0px 0px 0px 1rem #293D9C;
    animation: _360-infinite 16s linear infinite; }
  .box-service::after {
    --wh: 39rem;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: var(--wh);
    height: var(--wh);
    background: #0C1E72;
    border-radius: var(--wh);
    transform: translate(14rem, 10rem);
    box-shadow: inset 0px 0px 0px 0rem #293D9C; }
  .box-service > *:not(:first-child) {
    margin-top: 2rem; }
  .box-service__title {
    position: relative;
    z-index: 1; }
    .box-service__title > * {
      font-size: 1.8rem;
      line-height: 1.6;
      color: var(--cl-w); }
      @media (min-width: 768px) {
        .box-service__title > * {
          font-size: 1.84rem; } }
      @media (min-width: 1200px) {
        .box-service__title > * {
          font-size: 1.88rem; } }
      @media (min-width: 1336px) {
        .box-service__title > * {
          font-size: 2rem; } }
  .box-service__desc {
    position: relative;
    z-index: 1; }
    .box-service__desc > * {
      font-size: 1.44rem;
      color: var(--cl-lv-5);
      line-height: 1.4; }
      @media (min-width: 768px) {
        .box-service__desc > * {
          font-size: 1.47rem; } }
      @media (min-width: 1200px) {
        .box-service__desc > * {
          font-size: 1.5rem; } }
      @media (min-width: 1336px) {
        .box-service__desc > * {
          font-size: 1.6rem; } }
  .box-service .braney-more {
    position: relative;
    z-index: 1;
    flex: 1;
    display: flex;
    align-items: flex-end; }
    .box-service .braney-more > * {
      color: var(--cl-w); }
  .box-service__img {
    position: relative;
    z-index: 1;
    padding-top: 8rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; }
    .box-service__img > * {
      width: 18rem;
      height: 18rem;
      object-fit: contain; }

.box-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .box-form-number {
    --wh: 5.4rem;
    width: var(--wh);
    height: var(--wh);
    padding: 1rem;
    border-radius: 50%;
    background-color: var(--cl-sub);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: .3s all;
    cursor: pointer; }
    @media (min-width: 992px) {
      .box-form-number {
        --wh: 62px; } }
    .box-form-number::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      border: 2px dashed #fff;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transform-origin: center;
      transition: 1.2s all;
      transition: .3s all; }
    .box-form-number > * {
      font-weight: 700;
      font-size: 1.98rem;
      color: var(--cl-w); }
      @media (min-width: 768px) {
        .box-form-number > * {
          font-size: 2.02rem; } }
      @media (min-width: 1200px) {
        .box-form-number > * {
          font-size: 2.21rem; } }
      @media (min-width: 1336px) {
        .box-form-number > * {
          font-size: 2.2rem; } }
  .box-form-icon {
    width: 15rem;
    height: 15rem;
    background-color: var(--cl-w);
    border: 2px solid var(--cl-main);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s all; }
    .box-form-icon img {
      display: block;
      width: 60%;
      height: 60%; }
  .box-form-content {
    margin-left: auto;
    margin-right: auto; }
    .box-form-content > * {
      font-weight: 700;
      font-size: 1.98rem; }
      @media (min-width: 768px) {
        .box-form-content > * {
          font-size: 2.02rem; } }
      @media (min-width: 1200px) {
        .box-form-content > * {
          font-size: 2.21rem; } }
      @media (min-width: 1336px) {
        .box-form-content > * {
          font-size: 2.2rem; } }
  .box-form > *:not(:first-child) {
    margin-top: 2rem; }
  .box-form:hover .box-form-number {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    .box-form:hover .box-form-number::after {
      animation: _360-infinite-btn 8s linear infinite; }
  .box-form:hover .box-form-icon {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }

.box-article {
  max-width: 320px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 992px) {
    .box-article {
      max-width: initial; } }
  .box-article-block {
    width: 100%;
    position: relative;
    --ratioHeight: 134.45%;
    --ratioHeightHover: 56.5%;
    --timeTransition: 1s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: var(--timeTransition) all;
    padding-top: var(--ratioHeight); }
    @media (max-width: 575px) {
      .box-article-block {
        --ratioHeight: 114%; } }
  .box-article-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: var(--ratioHeightHover);
    transition: var(--timeTransition); }
    .box-article-img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .box-article-img::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0C1E7273;
      opacity: 0;
      visibility: hidden;
      transition: var(--timeTransition); }
  .box-article-time {
    position: absolute;
    top: 0;
    right: 3rem;
    background-color: var(--cl-main);
    color: var(--cl-w);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 1.6rem 1.2rem;
    font-weight: 700;
    font-size: 1.62rem; }
    @media (min-width: 992px) {
      .box-article-time {
        right: 4rem; } }
    .box-article-time > * {
      text-align: center; }
      .box-article-time > *:not(:first-child) {
        margin-top: .4rem; }
    @media (min-width: 768px) {
      .box-article-time {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .box-article-time {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .box-article-time {
        font-size: 1.8rem; } }
  .box-article-w {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: calc(var(--ratioHeight) - var(--ratioHeightHover));
    background-color: white;
    transition: var(--timeTransition); }
  .box-article-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: calc(var(--ratioHeight) - var(--ratioHeightHover));
    transition: var(--timeTransition); }
  .box-article-content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    color: var(--cl-w);
    padding: 2rem 2rem 4rem 2rem;
    display: flex;
    flex-direction: column; }
    .box-article-content > *:not(:first-child) {
      padding-top: 1.6rem; }
    .box-article-content .content-title {
      display: block;
      padding-bottom: 2rem;
      position: relative;
      background: linear-gradient(to right, var(--cl-lv-1) 80%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-repeat: repeat-x;
      background-size: 20px 2px;
      transition: var(--timeTransition); }
      .box-article-content .content-title > * {
        font-weight: 700;
        font-size: 1.8rem;
        color: var(--cl-black);
        line-height: 1.5;
        transition: var(--timeTransition); }
        @media (min-width: 768px) {
          .box-article-content .content-title > * {
            font-size: 1.84rem; } }
        @media (min-width: 1200px) {
          .box-article-content .content-title > * {
            font-size: 1.88rem; } }
        @media (min-width: 1336px) {
          .box-article-content .content-title > * {
            font-size: 2rem; } }
    .box-article-content .content-desc {
      flex: 1;
      transition: var(--timeTransition);
      transform-origin: top center; }
      .box-article-content .content-desc > * {
        color: var(--cl-text);
        transition: var(--timeTransition); }
  .box-article .braney-more > * {
    transition: var(--timeTransition); }
  .box-article:hover {
    box-shadow: none; }
    .box-article:hover .box-article-img {
      padding-top: var(--ratioHeight); }
      .box-article:hover .box-article-img::after {
        opacity: 1;
        visibility: visible; }
    .box-article:hover .box-article-w {
      padding-top: 0; }
    .box-article:hover .box-article-content {
      justify-content: flex-end; }
      .box-article:hover .box-article-content .content-title {
        background: linear-gradient(to right, var(--cl-w) 80%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-repeat: repeat-x;
        background-size: 20px 2px; }
        .box-article:hover .box-article-content .content-title > * {
          color: var(--cl-w); }
      .box-article:hover .box-article-content .content-desc {
        opacity: 0;
        height: 0;
        transform: scale(0);
        flex: none; }
    .box-article:hover .braney-more > * {
      color: var(--cl-w); }

.box-logo {
  display: block;
  width: 100%;
  padding-top: 40%;
  background-color: var(--cl-w);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: relative; }
  .box-logo img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    padding: 1rem;
    transform: translate(-50%, -50%);
    display: block;
    object-fit: contain; }

.box-contact__img {
  position: relative;
  padding-top: 56.25%;
  width: 100%; }
  .box-contact__img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.box-contact__title {
  text-align: center;
  margin-top: 2rem; }
  .box-contact__title > * {
    display: block;
    font-weight: 700;
    font-size: 2.16rem;
    line-height: 1.4;
    color: var(--cl-black); }
    @media (min-width: 768px) {
      .box-contact__title > * {
        font-size: 2.21rem; } }
    @media (min-width: 1200px) {
      .box-contact__title > * {
        font-size: 2.26rem; } }
    @media (min-width: 1336px) {
      .box-contact__title > * {
        font-size: 2.4rem; } }

.box-contact__bottom {
  margin-top: 1.6rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cl-w);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .box-contact__bottom > * {
    line-height: 1.6;
    font-size: 1.98rem;
    color: var(--cl-black); }
    @media (min-width: 768px) {
      .box-contact__bottom > * {
        font-size: 2.02rem; } }
    @media (min-width: 1200px) {
      .box-contact__bottom > * {
        font-size: 2.21rem; } }
    @media (min-width: 1336px) {
      .box-contact__bottom > * {
        font-size: 2.2rem; } }
  .box-contact__bottom a {
    line-height: 1.6;
    font-size: 1.98rem;
    color: var(--cl-black); }
    @media (min-width: 768px) {
      .box-contact__bottom a {
        font-size: 2.02rem; } }
    @media (min-width: 1200px) {
      .box-contact__bottom a {
        font-size: 2.21rem; } }
    @media (min-width: 1336px) {
      .box-contact__bottom a {
        font-size: 2.2rem; } }

.bsv-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .bsv-page:hover .bsv-page__box {
    background-color: rgba(41, 61, 156, 0.5); }
  .bsv-page--top {
    position: relative;
    height: 0;
    padding-top: 80%;
    display: block; }
  .bsv-page--bottom {
    flex: 1;
    background-color: var(--cl-w);
    padding: 3rem 3rem 0 3rem;
    display: flex;
    flex-direction: column; }
    .bsv-page--bottom .braney-more {
      border-top: 1px solid #DFDDDD;
      text-align: center;
      padding: 2rem 0; }
  .bsv-page__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: #293D9C; }
    .bsv-page__bg img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
  .bsv-page__box {
    position: absolute;
    top: 16px;
    left: 16px;
    bottom: 16px;
    right: 16px;
    z-index: 1;
    transition: .6s all ease-in-out;
    background-color: #293D9C;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center; }
    .bsv-page__box .icon {
      margin-top: 5.8rem; }
      .bsv-page__box .icon svg, .bsv-page__box .icon img {
        max-height: 7rem;
        object-fit: contain; }
    .bsv-page__box .title {
      width: 80%;
      margin-top: 2.4rem; }
      .bsv-page__box .title > * {
        font-weight: 700;
        color: var(--cl-w);
        font-size: 1.98rem; }
        @media (min-width: 768px) {
          .bsv-page__box .title > * {
            font-size: 2.02rem; } }
        @media (min-width: 1200px) {
          .bsv-page__box .title > * {
            font-size: 2.21rem; } }
        @media (min-width: 1336px) {
          .bsv-page__box .title > * {
            font-size: 2.2rem; } }
  .bsv-page__desc {
    flex: 1;
    color: var(--cl-lv-4);
    margin-bottom: 3rem; }
    .bsv-page__desc > * {
      color: var(--cl-lv-4); }

.box-policy {
  display: block;
  width: 100%;
  height: 100%; }
  .box-policy__img {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 44%; }
    .box-policy__img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .box-policy__content {
    position: relative;
    width: calc(100% - 6rem);
    transform: translate(3rem, -50%);
    padding: 2rem 3rem;
    background-color: var(--cl-w);
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: .3s all; }
    .box-policy__content > * {
      font-weight: 700;
      font-size: 1.8rem;
      color: var(--cl-black);
      transition: .3s all; }
      @media (min-width: 768px) {
        .box-policy__content > * {
          font-size: 1.84rem; } }
      @media (min-width: 1200px) {
        .box-policy__content > * {
          font-size: 1.88rem; } }
      @media (min-width: 1336px) {
        .box-policy__content > * {
          font-size: 2rem; } }
  .box-policy:hover .box-policy__content {
    background-color: #05ACEB; }
    .box-policy:hover .box-policy__content > * {
      color: var(--cl-w); }

.block-t1-wrapper {
  max-width: 100%;
  overflow: auto;
  padding-bottom: .8rem; }

.block-t1__title {
  text-align: center;
  padding: 1.4rem 2rem;
  background-color: #303090; }
  .block-t1__title > * {
    text-transform: uppercase;
    font-size: 2.16rem;
    font-weight: 700;
    color: var(--cl-w); }
    @media (min-width: 768px) {
      .block-t1__title > * {
        font-size: 2.21rem; } }
    @media (min-width: 1200px) {
      .block-t1__title > * {
        font-size: 2.26rem; } }
    @media (min-width: 1336px) {
      .block-t1__title > * {
        font-size: 2.4rem; } }

.block-t1 table {
  width: 100%; }

.block-t1 table, .block-t1 th, .block-t1 td {
  border: 1px solid #707070;
  border-collapse: collapse;
  font-size: 1.62rem;
  text-align: center;
  color: var(--cl-black); }
  @media (min-width: 768px) {
    .block-t1 table, .block-t1 th, .block-t1 td {
      font-size: 1.66rem; } }
  @media (min-width: 1200px) {
    .block-t1 table, .block-t1 th, .block-t1 td {
      font-size: 1.7rem; } }
  @media (min-width: 1336px) {
    .block-t1 table, .block-t1 th, .block-t1 td {
      font-size: 1.8rem; } }

.block-t1 th {
  font-size: 1.34rem;
  font-weight: 400;
  color: #05ACEB;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  @media (min-width: 768px) {
    .block-t1 th {
      font-size: 1.35rem; } }
  @media (min-width: 1200px) {
    .block-t1 th {
      font-size: 1.38rem; } }
  @media (min-width: 1336px) {
    .block-t1 th {
      font-size: 1.4rem; } }

.block-t1 th, .block-t1 td {
  padding: 1.2rem 1rem; }

.block-t3 .title {
  background-color: #303090;
  text-align: center;
  display: flex;
  justify-content: center;
  align-self: center; }
  .block-t3 .title > * {
    color: var(--cl-w);
    padding: 1rem 2rem;
    font-size: 1.62rem; }
    @media (min-width: 768px) {
      .block-t3 .title > * {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .block-t3 .title > * {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .block-t3 .title > * {
        font-size: 1.8rem; } }

.block-t3 .img {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 54.6%; }
  .block-t3 .img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.block-t3--bottom {
  margin-top: 2.4rem; }

.block-t3 .tb-item {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  background-color: transparent;
  flex-wrap: wrap; }
  .block-t3 .tb-item:not(:nth-of-type(2)) {
    margin-top: .8rem; }
  .block-t3 .tb-item > * {
    background-color: #F5F5F5; }
  .block-t3 .tb-item * {
    font-size: 1.62rem;
    font-weight: 400; }
    @media (min-width: 768px) {
      .block-t3 .tb-item * {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .block-t3 .tb-item * {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .block-t3 .tb-item * {
        font-size: 1.8rem; } }
  .block-t3 .tb-item--merge {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    background-color: transparent;
    flex-wrap: wrap; }
    .block-t3 .tb-item--merge > * {
      background-color: #F5F5F5; }
    .block-t3 .tb-item--merge .tb-item__key {
      border-top: none; }
    .block-t3 .tb-item--merge .tb-item__value {
      border-top: none; }
    .block-t3 .tb-item--merge:first-child .tb-item__key {
      border-top: 1px solid #707070; }
    .block-t3 .tb-item--merge:first-child .tb-item__value > * {
      border-top: 1px solid #707070; }
  .block-t3 .tb-item__key {
    width: 25%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border: 1px solid #707070; }
    @media (min-width: 992px) {
      .block-t3 .tb-item__key {
        text-align: left;
        justify-content: flex-start;
        padding-left: 6rem; } }
    .block-t3 .tb-item__key[data-colspan="2"] {
      width: 50%; }
  .block-t3 .tb-item__value {
    margin-left: .8rem;
    flex: 1;
    border-top: 1px solid #707070; }
  .block-t3 .tb-item--row {
    display: flex; }
    .block-t3 .tb-item--row > * {
      border-left: 1px solid #707070;
      border-right: 1px solid #707070;
      border-bottom: 1px solid #707070;
      flex: 1;
      padding: 1rem 1rem 1rem 1rem;
      text-align: center; }
      @media (min-width: 992px) {
        .block-t3 .tb-item--row > * {
          text-align: left;
          padding: 1rem 1rem 1rem 3rem; } }
      .block-t3 .tb-item--row > *:not(:first-child) {
        margin-left: .8rem; }

.input {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -2rem;
  margin-right: -2rem;
  margin-top: -2rem; }
  .input.ip-3 {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: -2rem; }
    .input.ip-3 .inputForm {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 2rem; }
      @media (min-width: 768px) {
        .input.ip-3 .inputForm {
          width: 33.33333%; } }
  .input .inputForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem; }
    @media (min-width: 768px) {
      .input .inputForm {
        padding-right: 2rem;
        width: 50%; } }
    .input .inputForm.full {
      width: 100%; }
      @media (min-width: 768px) {
        .input .inputForm.full {
          width: 100%; } }

.table {
  display: block;
  max-width: 100%;
  overflow: auto;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .table * {
    font-size: 1.44rem;
    transition: .3s all; }
    @media (min-width: 768px) {
      .table * {
        font-size: 1.47rem; } }
    @media (min-width: 1200px) {
      .table * {
        font-size: 1.5rem; } }
    @media (min-width: 1336px) {
      .table * {
        font-size: 1.6rem; } }
  .table table {
    min-width: 1024px; }
  .table table {
    border-collapse: collapse;
    width: 100%; }
    .table table thead th {
      background-color: var(--cl-w);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    .table table tbody tr {
      background-color: #E5E5E5; }
      .table table tbody tr.disabled {
        background-color: var(--cl-w); }
        .table table tbody tr.disabled td input, .table table tbody tr.disabled td select {
          cursor: initial;
          user-select: none;
          appearance: none; }
        .table table tbody tr.disabled td .group-opt::after {
          color: #707070;
          opacity: .3; }
    .table table tbody td > * {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    .table table th {
      padding: 2rem .8rem; }
    .table table td {
      padding: .8rem; }
      .table table td input, .table table td select, .table table td .actionEvt {
        height: 40px;
        width: 100%;
        padding: 0 1.2rem;
        font-weight: normal;
        outline: none;
        border: none;
        background-color: var(--cl-w); }
      .table table td .actionEvt {
        display: flex;
        justify-content: center;
        align-items: center; }
        .table table td .actionEvt button {
          width: 2.3rem;
          height: 2.3rem;
          background-color: transparent;
          outline: none;
          border: 1px solid #707070;
          position: relative;
          transition: .3s all; }
          .table table td .actionEvt button::after, .table table td .actionEvt button::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1rem;
            height: 2px;
            background-color: #707070; }
        .table table td .actionEvt .btnAdd::before {
          transform: translate(-50%, -50%) rotate(90deg); }
        .table table td .actionEvt .btnAdd:hover {
          border: 1px solid var(--cl-sub);
          background-color: var(--cl-sub); }
          .table table td .actionEvt .btnAdd:hover::before, .table table td .actionEvt .btnAdd:hover::after {
            background-color: white; }
        .table table td .actionEvt .btnRemove::before, .table table td .actionEvt .btnRemove::after {
          background-color: red; }
        .table table td .actionEvt .btnRemove:hover {
          border: 1px solid var(--cl-sub);
          background-color: var(--cl-sub); }
          .table table td .actionEvt .btnRemove:hover::before, .table table td .actionEvt .btnRemove:hover::after {
            background-color: red; }
        .table table td .actionEvt .btnRemove::before {
          transform: translate(-50%, -50%) rotate(45deg); }
        .table table td .actionEvt .btnRemove::after {
          transform: translate(-50%, -50%) rotate(-45deg); }
  .table .group-opt {
    /* thiết kế dấu mũi tên */ }
    .table .group-opt::after {
      padding: 0 .6rem; }
  .table .input-item[disabled] {
    color: var(--cl-text);
    opacity: .7;
    user-select: none; }

form > .row {
  padding: 4px 6px; }

form label {
  margin-bottom: 2rem;
  font-size: 1.62rem;
  color: var(--cl-text); }
  @media (min-width: 768px) {
    form label {
      font-size: 1.66rem; } }
  @media (min-width: 1200px) {
    form label {
      font-size: 1.7rem; } }
  @media (min-width: 1336px) {
    form label {
      font-size: 1.8rem; } }
  form label span {
    color: inherit; }

form select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0;
  background: transparent;
  background-image: none; }

form input, form textarea, form select {
  width: 100%;
  padding: 1.5rem 2rem;
  border-radius: 0;
  font-size: 1.44rem;
  resize: none;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: var(--cl-black); }
  @media (min-width: 768px) {
    form input, form textarea, form select {
      font-size: 1.47rem; } }
  @media (min-width: 1200px) {
    form input, form textarea, form select {
      font-size: 1.5rem; } }
  @media (min-width: 1336px) {
    form input, form textarea, form select {
      font-size: 1.6rem; } }

form .group-opt {
  position: relative;
  display: flex;
  width: 100%;
  line-height: 1.4;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  /* thiết kế dấu mũi tên */
  /* Transition */ }
  form .group-opt select {
    width: 100%;
    flex: 1;
    cursor: pointer; }
  form .group-opt option[disabled] {
    display: none; }
  form .group-opt::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    padding: 0 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
    color: #05ACEB; }
  form .group-opt:hover::after {
    color: #707070; }

form .inputFile {
  display: flex;
  align-items: center;
  flex-direction: column; }
  form .inputFile .spanList {
    display: none;
    opacity: 0;
    visibility: hidden;
    transform: scale(0); }
  form .inputFile .preview ol {
    list-style: none; }
  form .inputFile-group {
    position: relative;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: auto; }
    form .inputFile-group p {
      font-size: 1.62rem;
      color: var(--cl-main);
      font-weight: 700;
      display: none; }
      @media (min-width: 768px) {
        form .inputFile-group p {
          font-size: 1.66rem; } }
      @media (min-width: 1200px) {
        form .inputFile-group p {
          font-size: 1.7rem; } }
      @media (min-width: 1336px) {
        form .inputFile-group p {
          font-size: 1.8rem; } }
      @media (min-width: 768px) {
        form .inputFile-group p {
          display: inline-block; } }
    form .inputFile-group input[type="file"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      padding: 0;
      opacity: 0;
      cursor: pointer; }
    form .inputFile-group button.file {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.4;
      padding: .6rem 2rem;
      border-radius: 5px !important;
      border: 1px solid #171717;
      background: none;
      outline: none; }
      @media (min-width: 768px) {
        form .inputFile-group button.file {
          margin-left: 1rem; } }
      form .inputFile-group button.file svg {
        margin-right: 1.2rem; }

.inputSubmit {
  margin-top: 2.4rem;
  width: 100%; }
  @media (min-width: 768px) {
    .inputSubmit {
      width: 50%;
      padding-right: 2rem;
      margin-left: auto;
      margin-right: auto; } }
  .inputSubmit.btn-full button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }
  .inputSubmit.full-center {
    width: 100%;
    padding-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .inputSubmit .braney-more {
    background-color: #05ACEB;
    outline: none;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }
    .inputSubmit .braney-more > * {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 1.8rem;
      width: 100%;
      color: var(--cl-w); }
    .inputSubmit .braney-more path {
      stroke: var(--cl-w); }
    .inputSubmit .braney-more > * {
      background-color: #05ACEB;
      border: none;
      outline: none; }
      .inputSubmit .braney-more > *:hover {
        color: var(--cl-w) !important; }

.formBrn .group-opt {
  position: relative;
  display: flex;
  width: 100%;
  line-height: 1.4;
  background: #fff;
  overflow: hidden;
  border-radius: 10px;
  /* Thiết kẻ thẻ select */
  /* thiết kế dấu mũi tên */
  /* Transition */ }
  .formBrn .group-opt select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0;
    background: transparent;
    background-image: none; }
  .formBrn .group-opt select {
    flex: 1;
    padding: 1rem 3rem;
    color: #707070;
    cursor: pointer;
    font-size: 1.8rem;
    border: 1px solid #171717;
    border-radius: 10px; }
    @media (min-width: 768px) {
      .formBrn .group-opt select {
        font-size: 1.84rem; } }
    @media (min-width: 1200px) {
      .formBrn .group-opt select {
        font-size: 1.88rem; } }
    @media (min-width: 1336px) {
      .formBrn .group-opt select {
        font-size: 2rem; } }
  .formBrn .group-opt option[disabled] {
    display: none; }
  .formBrn .group-opt::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    padding: 0 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
    color: #05ACEB; }
  .formBrn .group-opt:hover::after {
    color: #707070; }

.formBrn .group-btn {
  width: 100%;
  height: 100%;
  border-radius: 10px; }
  .formBrn .group-btn button, .formBrn .group-btn input {
    padding: 1rem 1.2rem;
    outline: none;
    border: none;
    background: none;
    width: 100%;
    height: 100%;
    background-color: #05ACEB;
    color: white;
    font-size: 1.8rem;
    font-weight: 700;
    border-radius: 10px;
    transition: .3s all ease-in-out; }
    @media (min-width: 768px) {
      .formBrn .group-btn button, .formBrn .group-btn input {
        font-size: 1.84rem; } }
    @media (min-width: 1200px) {
      .formBrn .group-btn button, .formBrn .group-btn input {
        font-size: 1.88rem; } }
    @media (min-width: 1336px) {
      .formBrn .group-btn button, .formBrn .group-btn input {
        font-size: 2rem; } }
    .formBrn .group-btn button:hover, .formBrn .group-btn input:hover {
      background-color: var(--cl-main); }

.box-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
  align-items: center;
  --wh: 4rem; }
  .box-pagination li {
    background-color: var(--cl-main);
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--wh);
    width: var(--wh);
    transition: .3s ease-in-out;
    font-size: 1.8rem;
    cursor: pointer;
    user-select: none; }
    @media (min-width: 768px) {
      .box-pagination li {
        font-size: 1.84rem; } }
    @media (min-width: 1200px) {
      .box-pagination li {
        font-size: 1.88rem; } }
    @media (min-width: 1336px) {
      .box-pagination li {
        font-size: 2rem; } }
    .box-pagination li:not(.active):hover {
      border: solid 2px white;
      color: white; }
    .box-pagination li a {
      background-color: var(--cl-main);
      color: white;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      height: var(--wh);
      width: var(--wh);
      transition: .3s ease-in-out;
      font-size: 1.8rem; }
      @media (min-width: 768px) {
        .box-pagination li a {
          font-size: 1.84rem; } }
      @media (min-width: 1200px) {
        .box-pagination li a {
          font-size: 1.88rem; } }
      @media (min-width: 1336px) {
        .box-pagination li a {
          font-size: 2rem; } }
      .box-pagination li a:not(.active):hover {
        border: solid 2px white;
        color: white; }
    .box-pagination li svg {
      width: .8rem; }
      @media (min-width: 768px) {
        .box-pagination li svg {
          width: 1rem; } }
      @media (min-width: 992px) {
        .box-pagination li svg {
          width: 1.6rem; } }
    .box-pagination li.active {
      color: var(--cl-w);
      background-color: var(--cl-sub);
      cursor: initial; }
      .box-pagination li.active a {
        color: var(--cl-w);
        background-color: var(--cl-sub);
        cursor: initial; }
  .box-pagination li.next,
  .box-pagination li.prev {
    background-color: var(--cl-main);
    border: unset !important;
    width: var(--wh);
    height: var(--wh);
    transition: .3s ease-in-out; }
    .box-pagination li.next:not(.btn-hidden):hover,
    .box-pagination li.prev:not(.btn-hidden):hover {
      background-color: var(--cl-sub); }
    .box-pagination li.next.btn-hidden,
    .box-pagination li.prev.btn-hidden {
      display: none;
      cursor: initial; }
    .box-pagination li.next a,
    .box-pagination li.prev a {
      background-color: var(--cl-main);
      border: unset !important;
      width: var(--wh);
      height: var(--wh);
      transition: .3s ease-in-out; }
      .box-pagination li.next a:not(.btn-hidden):hover,
      .box-pagination li.prev a:not(.btn-hidden):hover {
        background-color: var(--cl-sub); }
  .box-pagination li.prev svg {
    transform: rotate(180deg); }
  .box-pagination li + li {
    margin-left: 6px; }
    @media (min-width: 576px) {
      .box-pagination li + li {
        margin-left: 10px; } }
    @media (min-width: 992px) {
      .box-pagination li + li {
        margin-left: 15px; } }

.nav-bar {
  padding: 2rem;
  background-color: #F5F5F5; }
  .nav-bar-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 1rem 2rem;
    min-height: 6rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: .3s all; }
    .nav-bar-item > * {
      transition: .3s all;
      color: #171717;
      font-size: 1.44rem; }
      @media (min-width: 768px) {
        .nav-bar-item > * {
          font-size: 1.47rem; } }
      @media (min-width: 1200px) {
        .nav-bar-item > * {
          font-size: 1.5rem; } }
      @media (min-width: 1336px) {
        .nav-bar-item > * {
          font-size: 1.6rem; } }
    .nav-bar-item > p {
      margin-left: 1rem;
      margin-right: 1.6rem;
      line-height: 1.4;
      width: 100%;
      text-transform: uppercase; }
    .nav-bar-item svg {
      height: 42px; }
      .nav-bar-item svg * {
        transition: .3s all; }
      .nav-bar-item svg *[fill]:not([fill="none"]) {
        fill: #05aceb; }
      .nav-bar-item svg *[stroke]:not([stroke="none"]) {
        stroke: #05aceb; }
    .nav-bar-item.active, .nav-bar-item:hover {
      background-color: #05aceb;
      box-shadow: none; }
      .nav-bar-item.active .nav-bar-item__arrow, .nav-bar-item:hover .nav-bar-item__arrow {
        position: relative;
        transform-origin: center;
        transform: rotate(90deg); }
      .nav-bar-item.active > *, .nav-bar-item:hover > * {
        color: #fff; }
      .nav-bar-item.active svg *[fill]:not([fill="none"]), .nav-bar-item:hover svg *[fill]:not([fill="none"]) {
        fill: #fff; }
      .nav-bar-item.active svg *[stroke]:not([stroke="none"]), .nav-bar-item:hover svg *[stroke]:not([stroke="none"]) {
        stroke: #fff; }
    .nav-bar-item:hover {
      background-color: #05aceb;
      box-shadow: none; }
      .nav-bar-item:hover > * {
        color: #fff; }
      .nav-bar-item:hover svg *[fill]:not([fill="none"]) {
        fill: #fff; }
      .nav-bar-item:hover svg *[stroke]:not([stroke="none"]) {
        stroke: #fff; }
  .nav-bar-item + .nav-bar-item {
    margin-top: 1.6rem; }

.swiper-slide {
  height: auto; }

.swiper-custom-button {
  --wcontainer: 96%;
  --spaceBtn: 4rem; }
  @media (min-width: 420px) {
    .swiper-custom-button {
      --wcontainer: 96%; } }
  @media (min-width: 576px) {
    .swiper-custom-button {
      --wcontainer: 540px; } }
  @media (min-width: 768px) {
    .swiper-custom-button {
      --wcontainer: 720px; } }
  @media (min-width: 992px) {
    .swiper-custom-button {
      --wcontainer: 960px; } }
  @media (min-width: 1200px) {
    .swiper-custom-button {
      --wcontainer: 1140px; } }
  @media (min-width: 1336px) {
    .swiper-custom-button {
      --wcontainer: 1245px; } }
  @media (min-width: 1500px) {
    .swiper-custom-button {
      --wcontainer: 1245px; } }
  @media (min-width: 768px) {
    .swiper-custom-button {
      --directionBtn: -1;
      --spaceBtn: calc(((100vw - var(--wcontainer)) / 2) / 100 * 16 * var(--directionBtn)); } }
  .swiper-custom-button-next, .swiper-custom-button-prev {
    --wh: 3rem;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: var(--wh);
    height: var(--wh);
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .3s all; }
    @media (min-width: 768px) {
      .swiper-custom-button-next, .swiper-custom-button-prev {
        --wh: 40px; } }
    @media (max-width: 767px) {
      .swiper-custom-button-next svg, .swiper-custom-button-prev svg {
        width: 16px;
        height: 10px; } }
    .swiper-custom-button-next path, .swiper-custom-button-prev path {
      fill: var(--cl-1);
      transition: .3s all; }
    .swiper-custom-button-next[aria-disabled="true"], .swiper-custom-button-prev[aria-disabled="true"] {
      opacity: .4 !important;
      user-select: none !important;
      cursor: initial !important;
      background: transparent !important; }
      .swiper-custom-button-next[aria-disabled="true"] path, .swiper-custom-button-prev[aria-disabled="true"] path {
        fill: var(--cl-1) !important; }
    .swiper-custom-button-next::after, .swiper-custom-button-prev::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%) scale(1.2);
      transform-origin: center;
      background: linear-gradient(to right, #0C1E72 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(#0C1E72 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #0C1E72 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(#0C1E72 80%, rgba(255, 255, 255, 0) 0%);
      background-position: top, right, bottom, left;
      background-repeat: repeat-x, repeat-y;
      background-size: 16px 2px, 2px 16px;
      transition: .3s all; }
    .swiper-custom-button-next:hover, .swiper-custom-button-prev:hover {
      background: var(--cl-sub); }
      .swiper-custom-button-next:hover path, .swiper-custom-button-prev:hover path {
        fill: var(--cl-w); }
  .swiper-custom-button-prev {
    left: var(--spaceBtn);
    transform: translate(-100%, -50%) rotate(90deg); }
  .swiper-custom-button-next {
    right: var(--spaceBtn);
    transform: translate(100%, -50%) rotate(-90deg); }
  .swiper-custom-button .swiper-button-lock {
    display: none; }

.swiper-pagination-bullet {
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--cl-w);
  border: 1px solid var(--cl-w); }
  @media (min-width: 768px) {
    .swiper-pagination-bullet {
      width: 1.8rem;
      height: 1.8rem; } }
  .swiper-pagination-bullet-active {
    background-color: var(--cl-sub); }

.overflow {
  overflow: auto; }

.overflow-y {
  overflow-x: initial;
  overflow-y: auto; }

.overflow-x {
  overflow-y: initial;
  overflow-x: auto; }

.overflow, .overflow-y, .overflow-x {
  max-width: 100%;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .overflow::-webkit-scrollbar, .overflow-y::-webkit-scrollbar, .overflow-x::-webkit-scrollbar {
    width: 6px;
    height: 4px; }
  .overflow::-webkit-scrollbar-track, .overflow-y::-webkit-scrollbar-track, .overflow-x::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px var(--cl-gray);
    border-radius: 10px; }
  .overflow::-webkit-scrollbar-thumb, .overflow-y::-webkit-scrollbar-thumb, .overflow-x::-webkit-scrollbar-thumb {
    background: var(--cl-main);
    border-radius: 10px; }
  .overflow::-webkit-scrollbar-thumb:hover, .overflow-y::-webkit-scrollbar-thumb:hover, .overflow-x::-webkit-scrollbar-thumb:hover {
    background: var(--cl-sub); }

@keyframes fixedHeader {
  0% {
    top: 0%;
    transform: translateY(-100%); }
  100% {
    top: 0;
    transform: translateY(0);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .header.scroll {
    transform: translateY(-100%);
    animation: fixedHeader 1.5s ease forwards; }
    .header.scroll .header-top {
      height: 0;
      transform: scale(0);
      opacity: 0;
      visibility: hidden;
      padding: 0;
      margin: 0; }
  .header-top {
    color: var(--cl-w);
    background-color: var(--cl-main);
    display: none;
    padding: 1.2rem 0;
    transition: .5s all;
    transform-origin: top center; }
    @media (min-width: 992px) {
      .header-top {
        display: block; } }
    .header-top .container,
    .header-top .left,
    .header-top .right {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
    .header-top .container {
      justify-content: center; }
      @media (min-width: 992px) {
        .header-top .container {
          justify-content: space-between; } }
    .header-top .left {
      display: none; }
      @media (min-width: 992px) {
        .header-top .left {
          display: flex; } }
    .header-top .socials,
    .header-top .search {
      position: relative;
      margin-right: 2rem;
      padding-right: 2rem; }
      .header-top .socials::after,
      .header-top .search::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        width: 2px;
        height: 19px;
        border-radius: 2px;
        background-color: #FFF; }
    .header-top .socials {
      display: flex; }
      .header-top .socials-item {
        display: block;
        transition: .3s all;
        cursor: pointer; }
        .header-top .socials-item:not(:first-child) {
          margin-left: 1.6rem; }
        .header-top .socials-item path {
          fill: var(--cl-w);
          transition: .3s all; }
        .header-top .socials-item:hover path {
          fill: var(--cl-sub); }
    .header-top .search {
      display: flex;
      align-items: center; }
      .header-top .search input,
      .header-top .search button {
        background: none;
        border: none;
        outline: none;
        color: var(--cl-w);
        font-weight: 700;
        line-height: 1.4; }
      .header-top .search input {
        width: 18rem; }
        .header-top .search input::-webkit-input-placeholder {
          color: var(--cl-w); }
        .header-top .search input::-ms-input-placeholder {
          color: var(--cl-w); }
        .header-top .search input::placeholder {
          color: var(--cl-w); }
        @media (min-width: 1200px) {
          .header-top .search input {
            width: 22rem; } }
      .header-top .search button path,
      .header-top .search button g,
      .header-top .search button circle {
        transition: .2s all; }
        .header-top .search button path[stroke]:not([stroke="none"]),
        .header-top .search button g[stroke]:not([stroke="none"]),
        .header-top .search button circle[stroke]:not([stroke="none"]) {
          stroke: #fff; }
        .header-top .search button path[fill]:not([fill="none"]),
        .header-top .search button g[fill]:not([fill="none"]),
        .header-top .search button circle[fill]:not([fill="none"]) {
          fill: #fff; }
      .header-top .search button:hover path[stroke]:not([stroke="none"]),
      .header-top .search button:hover g[stroke]:not([stroke="none"]),
      .header-top .search button:hover circle[stroke]:not([stroke="none"]) {
        stroke: var(--cl-sub); }
      .header-top .search button:hover path[fill]:not([fill="none"]),
      .header-top .search button:hover g[fill]:not([fill="none"]),
      .header-top .search button:hover circle[fill]:not([fill="none"]) {
        fill: var(--cl-sub); }
    .header-top .phone + .languages {
      margin-left: 3rem; }
      @media (min-width: 1200px) {
        .header-top .phone + .languages {
          margin-left: 3.8rem; } }
    .header-top .right {
      justify-self: flex-end;
      justify-items: flex-end; }
    .header-top .phone {
      color: var(--cl-w);
      font-weight: 700;
      display: flex;
      align-items: center; }
      .header-top .phone svg {
        margin-right: 1.6rem; }
    .header-top .languages {
      display: flex; }
      .header-top .languages-item {
        display: block;
        cursor: pointer;
        transition: .3s all;
        position: relative; }
        .header-top .languages-item::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #1B2335;
          opacity: 0; }
        .header-top .languages-item.active {
          user-select: none;
          cursor: initial; }
          .header-top .languages-item.active::after {
            content: "";
            opacity: .5; }
        .header-top .languages-item:hover {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
        .header-top .languages-item img {
          height: 100%; }
      .header-top .languages .languages-item + .languages-item {
        margin-left: 1.6rem; }
  .header-bottom {
    background-color: #F1F2F2;
    position: relative; }
    @media (min-width: 992px) {
      .header-bottom {
        background-color: var(--cl-w); } }
    .header-bottom .menu {
      display: none;
      justify-content: space-between;
      align-items: stretch;
      --kc: 2rem; }
      @media (min-width: 992px) {
        .header-bottom .menu {
          display: flex; } }
      .header-bottom .menu-left, .header-bottom .menu-right {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center; }
      @media (min-width: 1200px) {
        .header-bottom .menu {
          --kc: 5rem; } }
      .header-bottom .menu-left {
        margin-right: var(--kc); }
      .header-bottom .menu-right {
        margin-left: var(--kc); }
      .header-bottom .menu-center {
        padding: 1.2rem 0; }
      .header-bottom .menu-item {
        list-style-type: none;
        height: 100%;
        padding: 1.2rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative; }
        .header-bottom .menu-item:hover > .menu-item__link {
          color: var(--cl-sub); }
          .header-bottom .menu-item:hover > .menu-item__link::after {
            width: 100%; }
        .header-bottom .menu-item:hover > .menu-item__sub {
          transform: translateY(0%);
          opacity: 1;
          z-index: -1;
          visibility: visible;
          user-select: initial; }
        .header-bottom .menu-item__link {
          font-weight: 700;
          color: var(--cl-black);
          text-transform: uppercase;
          transition: .3s all;
          display: inline-block;
          position: relative; }
          .header-bottom .menu-item__link::after {
            content: "";
            position: absolute;
            bottom: -.8rem;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: .3s all;
            transform-origin: center;
            width: 0%;
            height: 1px;
            background: linear-gradient(to right, #293D9C 50%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-repeat: repeat-x;
            background-size: 8px 1px; }
          .header-bottom .menu-item__link.active {
            color: var(--cl-sub); }
            .header-bottom .menu-item__link.active::after {
              width: 100%; }
          .header-bottom .menu-item__link.show ~ .menu-item__sub {
            transform: translateY(0%);
            opacity: 1;
            visibility: visible;
            user-select: initial; }
        .header-bottom .menu-item__sub {
          display: block;
          position: absolute;
          top: calc(100% - 1px);
          left: 0px;
          min-width: 22rem;
          background-color: var(--cl-w);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          border-bottom: 3px solid var(--cl-main);
          padding: 2rem 0rem 3rem 0rem;
          list-style-type: none;
          transition: .6s all ease-in-out;
          transform: translateY(-100%);
          opacity: 0;
          z-index: -1;
          visibility: hidden;
          user-select: none; }
          .header-bottom .menu-item__sub-link {
            padding: 0 2rem;
            font-weight: 700;
            color: var(--cl-black);
            line-height: 1.3;
            transition: .2s all ease-in-out;
            position: relative; }
            .header-bottom .menu-item__sub-link > * {
              font-weight: 700;
              color: var(--cl-black);
              line-height: 1.3;
              transition: .2s all ease-in-out; }
              @media (min-width: 1200px) {
                .header-bottom .menu-item__sub-link > * {
                  white-space: nowrap; } }
            .header-bottom .menu-item__sub-link:hover {
              color: var(--cl-sub); }
              .header-bottom .menu-item__sub-link:hover > * {
                color: var(--cl-sub); }
            .header-bottom .menu-item__sub-link:not(:first-child) {
              margin-top: 1rem; }
            .header-bottom .menu-item__sub-link ul {
              position: absolute;
              top: -2rem;
              left: 100%;
              transition: .6s all ease-in-out;
              transform: translateX(-40%);
              opacity: 0;
              z-index: -2; }
            .header-bottom .menu-item__sub-link:hover > ul {
              transform: translateX(0%);
              opacity: 1;
              z-index: -2;
              visibility: visible;
              user-select: initial; }
  .header .mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem 0; }
    @media (min-width: 992px) {
      .header .mobile {
        display: none; } }
    .header .mobile-logo svg {
      height: 5.6rem; }
    .header .mobile-hamburger,
    .header .mobile .close {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 2.3rem;
      height: 1.9rem;
      --line: 2px; }
      @media (min-width: 768px) {
        .header .mobile-hamburger,
        .header .mobile .close {
          --line: 3px; } }
      .header .mobile-hamburger .line,
      .header .mobile .close .line {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        user-select: none;
        width: 100%;
        height: 0px;
        border-top: var(--line) solid var(--cl-main);
        border-radius: 12px;
        transition: .3s all; }
        .header .mobile-hamburger .line:first-child,
        .header .mobile .close .line:first-child {
          top: 0;
          bottom: auto;
          transform: translate(-50%, 0); }
        .header .mobile-hamburger .line:last-child,
        .header .mobile .close .line:last-child {
          top: auto;
          bottom: 0;
          transform: translate(-50%, 0); }
      .header .mobile-hamburger.show,
      .header .mobile .close.show {
        width: 2.4rem; }
        .header .mobile-hamburger.show .line,
        .header .mobile .close.show .line {
          opacity: 0;
          visibility: hidden; }
        .header .mobile-hamburger.show .line:first-child,
        .header .mobile .close.show .line:first-child {
          opacity: 1;
          visibility: visible;
          top: 50%;
          bottom: auto;
          transform: translate(-50%, -50%) rotate(45deg); }
        .header .mobile-hamburger.show .line:last-child,
        .header .mobile .close.show .line:last-child {
          opacity: 1;
          visibility: visible;
          top: 50%;
          bottom: auto;
          transform: translate(-50%, -50%) rotate(-45deg); }
    .header .mobile .close .line {
      border-top: var(--line) solid var(--cl-black); }
    .header .mobile .languages {
      display: flex; }
      .header .mobile .languages-item {
        display: block;
        cursor: pointer;
        transition: .3s all;
        position: relative; }
        .header .mobile .languages-item::after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #1B2335;
          opacity: 0; }
        .header .mobile .languages-item.active {
          user-select: none;
          cursor: initial; }
          .header .mobile .languages-item.active::after {
            content: "";
            opacity: .5; }
        .header .mobile .languages-item:hover {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
        .header .mobile .languages-item img {
          height: 100%; }
      .header .mobile .languages .languages-item + .languages-item {
        margin-left: 1.6rem; }
    .header .mobile .search {
      display: flex;
      align-items: center; }
      .header .mobile .search input,
      .header .mobile .search button {
        background: none;
        border: none;
        outline: none;
        color: var(--cl-main);
        line-height: 1.4;
        font-size: 16px; }
      .header .mobile .search input {
        flex: 1;
        width: 18rem; }
        .header .mobile .search input::-webkit-input-placeholder {
          color: var(--cl-main); }
        .header .mobile .search input::-ms-input-placeholder {
          color: var(--cl-main); }
        .header .mobile .search input::placeholder {
          color: var(--cl-main); }
        @media (min-width: 1200px) {
          .header .mobile .search input {
            width: 22rem; } }
      .header .mobile .search button path,
      .header .mobile .search button g,
      .header .mobile .search button circle {
        transition: .2s all; }
        .header .mobile .search button path[stroke]:not([stroke="none"]),
        .header .mobile .search button g[stroke]:not([stroke="none"]),
        .header .mobile .search button circle[stroke]:not([stroke="none"]) {
          stroke: var(--cl-main); }
        .header .mobile .search button path[fill]:not([fill="none"]),
        .header .mobile .search button g[fill]:not([fill="none"]),
        .header .mobile .search button circle[fill]:not([fill="none"]) {
          fill: var(--cl-main); }
      .header .mobile .search button:hover path[stroke]:not([stroke="none"]),
      .header .mobile .search button:hover g[stroke]:not([stroke="none"]),
      .header .mobile .search button:hover circle[stroke]:not([stroke="none"]) {
        stroke: var(--cl-sub); }
      .header .mobile .search button:hover path[fill]:not([fill="none"]),
      .header .mobile .search button:hover g[fill]:not([fill="none"]),
      .header .mobile .search button:hover circle[fill]:not([fill="none"]) {
        fill: var(--cl-sub); }
    .header .mobile-nav {
      position: absolute;
      top: 0%;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 100vh;
      max-width: 100%;
      opacity: 1;
      visibility: visible;
      transform: translate(-100%, 0);
      transform-origin: left center;
      transition: .3s all linear;
      background-color: #00000000;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
      @media (min-width: 992px) {
        .header .mobile-nav {
          display: none; } }
      .header .mobile-nav.show {
        opacity: 1;
        visibility: visible;
        transform: translate(0%, 0);
        background-color: #00000066; }
      .header .mobile-nav .nav {
        width: 40rem;
        max-width: 100%;
        height: 100%;
        transition: .3s all linear;
        padding: 2.5rem 4rem 3rem 3.6rem;
        background-color: var(--cl-w);
        overflow-y: auto;
        overflow-x: hidden;
        --wScroll: 5px;
        /* width */
        /* Track */
        /* Handle */
        /* Handle on hover */ }
        @media (min-width: 576px) {
          .header .mobile-nav .nav {
            padding: 2.5rem 8rem 3rem 3.6rem; } }
        @media (min-width: 992px) {
          .header .mobile-nav .nav {
            --wScroll: 7px; } }
        .header .mobile-nav .nav::-webkit-scrollbar {
          width: var(--wScroll); }
        .header .mobile-nav .nav::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px var(--cl-lv-5);
          border-radius: var(--wScroll); }
        .header .mobile-nav .nav::-webkit-scrollbar-thumb {
          background: var(--cl-main);
          border-radius: var(--wScroll); }
        .header .mobile-nav .nav::-webkit-scrollbar-thumb:hover {
          background: var(--cl-lv-3); }
        .header .mobile-nav .nav > *:not(:first-child) {
          margin-top: 2rem; }
        .header .mobile-nav .nav-top {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between; }
        .header .mobile-nav .nav-search input {
          color: var(--cl-main); }
        .header .mobile-nav .nav-menu {
          display: flex;
          flex-direction: column;
          list-style: none; }
          .header .mobile-nav .nav-menu-item {
            border-top: 1px solid #E1E1E1; }
            .header .mobile-nav .nav-menu-item:last-child {
              border-bottom: 1px solid #E1E1E1; }
            .header .mobile-nav .nav-menu-item.active .nav-menu__link > a {
              color: var(--cl-sub);
              font-weight: 700; }
            .header .mobile-nav .nav-menu-item.active .nav-menu__link svg {
              transform: rotate(180deg); }
            .header .mobile-nav .nav-menu-item.active .nav-menu__sub-link .nav-menu__sub {
              position: relative; }
              .header .mobile-nav .nav-menu-item.active .nav-menu__sub-link .nav-menu__sub::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 4px;
                height: 100%;
                background-color: var(--cl-sub); }
            .header .mobile-nav .nav-menu-item.active .nav-menu__sub {
              opacity: 1;
              height: auto;
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              visibility: initial; }
          .header .mobile-nav .nav-menu__link {
            display: flex;
            justify-content: space-between;
            align-items: center; }
            .header .mobile-nav .nav-menu__link > a {
              cursor: pointer;
              padding: 12px 0;
              color: var(--cl-main);
              font-weight: 400;
              text-transform: uppercase;
              font-size: 1.6rem;
              display: flex;
              flex-direction: row;
              align-items: center;
              transition: .5s ease; }
              .header .mobile-nav .nav-menu__link > a span {
                flex: 1; }
            .header .mobile-nav .nav-menu__link svg {
              transition: .5s ease; }
            .header .mobile-nav .nav-menu__link:focus > a, .header .mobile-nav .nav-menu__link:hover > a, .header .mobile-nav .nav-menu__link.active > a {
              color: var(--cl-sub);
              font-weight: 700; }
          .header .mobile-nav .nav-menu__sub {
            height: 0;
            opacity: 0;
            transition: all .5s ease;
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            visibility: hidden; }
          .header .mobile-nav .nav-menu__sub {
            padding-left: 1.2rem;
            list-style-type: none; }
            .header .mobile-nav .nav-menu__sub li.active > a {
              color: var(--cl-sub);
              font-weight: 700; }
            .header .mobile-nav .nav-menu__sub li.active svg {
              transform: rotate(180deg); }
            .header .mobile-nav .nav-menu__sub li.active ul {
              opacity: 1;
              height: auto;
              clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              visibility: initial; }
            .header .mobile-nav .nav-menu__sub li a {
              cursor: pointer;
              padding: 8px 0;
              color: var(--cl-main);
              font-weight: 400;
              text-transform: uppercase;
              font-size: 1.5rem;
              display: flex;
              flex-direction: row;
              align-items: center; }
              .header .mobile-nav .nav-menu__sub li a span {
                flex: 1; }
              .header .mobile-nav .nav-menu__sub li a svg {
                transition: .5s ease; }
            .header .mobile-nav .nav-menu__sub li ul {
              height: 0;
              opacity: 0;
              transition: all .5s ease;
              clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
              visibility: hidden; }

.banner-video, .banner-item {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 64%;
  display: block; }
  @media (min-width: 768px) {
    .banner-video, .banner-item {
      padding-top: 31.25%; } }
  .banner-video video, .banner-video img, .banner-item video, .banner-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.banner.bannerHome .banner-video, .banner.bannerHome .banner-item {
  padding-top: 64%; }
  @media (min-width: 768px) {
    .banner.bannerHome .banner-video, .banner.bannerHome .banner-item {
      padding-top: 31.25%; } }

.banner-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #171717;
  opacity: .3; }

.banner .playYtb {
  width: 5.2rem;
  height: 5.2rem;
  padding-top: 0;
  opacity: .3; }
  @media (min-width: 768px) {
    .banner .playYtb {
      width: 6.4rem;
      height: 6.4rem;
      padding-top: 0; } }
  @media (min-width: 992px) {
    .banner .playYtb {
      width: 8.8rem;
      height: 8.8rem;
      padding-top: 0; } }

.footer-bottom {
  height: 6rem;
  width: 100%;
  display: block;
  background-color: var(--cl-main); }

.footer-top-wrapper {
  justify-content: space-between; }

.footer-top__item:nth-of-type(1) {
  margin-bottom: 3rem;
  width: 100%; }
  @media (min-width: 576px) {
    .footer-top__item:nth-of-type(1) {
      width: 46%; } }
  @media (min-width: 768px) {
    .footer-top__item:nth-of-type(1) {
      width: 100%; } }
  @media (min-width: 1200px) {
    .footer-top__item:nth-of-type(1) {
      margin-bottom: auto;
      width: 20%; } }
  @media (min-width: 1336px) {
    .footer-top__item:nth-of-type(1) {
      width: 18%; } }

.footer-top__item:nth-of-type(2) {
  width: 100%;
  margin-bottom: 3rem; }
  @media (min-width: 576px) {
    .footer-top__item:nth-of-type(2) {
      width: 46%; } }
  @media (min-width: 768px) {
    .footer-top__item:nth-of-type(2) {
      width: 33%;
      margin-bottom: 0rem; } }
  @media (min-width: 992px) {
    .footer-top__item:nth-of-type(2) {
      width: 30%; } }
  @media (min-width: 1200px) {
    .footer-top__item:nth-of-type(2) {
      width: 26%; } }
  @media (min-width: 1336px) {
    .footer-top__item:nth-of-type(2) {
      width: 26%; } }

.footer-top__item:nth-of-type(3) {
  width: 100%;
  margin-bottom: 3rem; }
  @media (min-width: 576px) {
    .footer-top__item:nth-of-type(3) {
      width: 46%;
      margin-bottom: 0rem; } }
  @media (min-width: 768px) {
    .footer-top__item:nth-of-type(3) {
      width: 33%; } }
  @media (min-width: 992px) {
    .footer-top__item:nth-of-type(3) {
      width: 30%; } }
  @media (min-width: 1200px) {
    .footer-top__item:nth-of-type(3) {
      width: 22%; } }
  @media (min-width: 1336px) {
    .footer-top__item:nth-of-type(3) {
      width: 20%; } }

.footer-top__item:nth-of-type(4) {
  width: 100%; }
  @media (min-width: 576px) {
    .footer-top__item:nth-of-type(4) {
      width: 46%; } }
  @media (min-width: 768px) {
    .footer-top__item:nth-of-type(4) {
      width: 33%; } }
  @media (min-width: 992px) {
    .footer-top__item:nth-of-type(4) {
      width: 30%; } }
  @media (min-width: 1200px) {
    .footer-top__item:nth-of-type(4) {
      width: 22%; } }
  @media (min-width: 1336px) {
    .footer-top__item:nth-of-type(4) {
      width: 20%; } }

.footer-copyright {
  text-align: center;
  line-height: 1.4;
  padding-top: 1rem; }
  .footer-copyright * {
    font-size: 1.62rem;
    color: var(--cl-black); }
    @media (min-width: 768px) {
      .footer-copyright * {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .footer-copyright * {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .footer-copyright * {
        font-size: 1.8rem; } }

.footer-item + .footer-item {
  margin-top: 3rem; }

.footer-item {
  max-width: 40rem;
  margin: 0 auto;
  text-align: center; }
  @media (min-width: 576px) {
    .footer-item {
      text-align: left; } }
  .footer-item__logo, .footer-item__bct {
    display: block;
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 576px) {
      .footer-item__logo, .footer-item__bct {
        margin-left: initial;
        margin-right: initial; } }
    @media (min-width: 768px) {
      .footer-item__logo, .footer-item__bct {
        margin-left: auto;
        margin-right: auto;
        max-width: 22rem; } }
    @media (min-width: 1200px) {
      .footer-item__logo, .footer-item__bct {
        margin-left: auto;
        margin-right: auto;
        max-width: 30rem; } }
  .footer-item__title {
    padding-bottom: 1.2rem;
    margin-bottom: 1.2rem;
    position: relative; }
    .footer-item__title > * {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 2.16rem;
      line-height: 1.5;
      color: var(--cl-main); }
      @media (min-width: 768px) {
        .footer-item__title > * {
          font-size: 2.21rem; } }
      @media (min-width: 1200px) {
        .footer-item__title > * {
          font-size: 2.26rem; } }
      @media (min-width: 1336px) {
        .footer-item__title > * {
          font-size: 2.4rem; } }
    .footer-item__title::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, #05ACEB 80%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-repeat: repeat-x;
      background-size: 20px 2px; }
  .footer-item__info {
    display: flex;
    flex-direction: row;
    justify-content: center; }
    @media (min-width: 576px) {
      .footer-item__info {
        justify-content: flex-start; } }
    .footer-item__info > * {
      display: inline-block; }
    .footer-item__info .icon {
      margin-right: 2rem; }
    .footer-item__info .desc {
      color: var(--cl-black);
      font-size: 1.62rem; }
      @media (min-width: 768px) {
        .footer-item__info .desc {
          font-size: 1.66rem; } }
      @media (min-width: 1200px) {
        .footer-item__info .desc {
          font-size: 1.7rem; } }
      @media (min-width: 1336px) {
        .footer-item__info .desc {
          font-size: 1.8rem; } }
  .footer-item__info + .footer-item__info {
    margin-top: 3rem; }
  .footer-item__links {
    display: flex;
    flex-direction: column; }
    .footer-item__links .link:not(:first-child) {
      margin-top: 2rem; }
    .footer-item__links .link {
      font-weight: 700;
      font-size: 1.62rem;
      color: var(--cl-black); }
      @media (min-width: 768px) {
        .footer-item__links .link {
          font-size: 1.66rem; } }
      @media (min-width: 1200px) {
        .footer-item__links .link {
          font-size: 1.7rem; } }
      @media (min-width: 1336px) {
        .footer-item__links .link {
          font-size: 1.8rem; } }
  .footer-item__opt {
    position: relative;
    max-width: 100%;
    width: 100%; }
    .footer-item__opt select {
      width: 100%;
      white-space: nowrap;
      padding: 1.2rem 2rem;
      line-height: 1.4;
      border: none;
      background-color: #E3E3E3;
      color: var(--cl-gray);
      appearance: none;
      text-align: center; }
      @media (min-width: 576px) {
        .footer-item__opt select {
          text-align: left; } }
    @media (min-width: 576px) {
      .footer-item__opt {
        min-width: 220px;
        width: auto; } }
    .footer-item__opt::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 12px solid #05ACEB;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      z-index: 1;
      pointer-events: none; }
  .footer-item__socials {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    margin-top: -4px;
    justify-content: center; }
    @media (min-width: 576px) {
      .footer-item__socials {
        justify-content: flex-start; } }
    .footer-item__socials .social {
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 4px; }
      .footer-item__socials .social * {
        user-select: none; }
      .footer-item__socials .social *:not([fill="#fff"]) {
        fill: var(--cl-main);
        transition: .3s all; }
      .footer-item__socials .social:hover *:not([fill="#fff"]) {
        fill: var(--cl-sub); }

.footer-fixed {
  position: fixed;
  right: 2vw;
  --sizeFixed: 40px;
  bottom: 8rem;
  transition: bottom .2s linear;
  font-size: var(--sizeFixed);
  z-index: 1001; }
  @media (min-width: 768px) {
    .footer-fixed {
      --sizeFixed: 60px;
      bottom: 8rem; } }
  .footer-fixed.showed-scroll {
    bottom: calc(8rem + 40px + 18px); }
    @media (min-width: 768px) {
      .footer-fixed.showed-scroll {
        bottom: calc(8rem + 60px + 18px); } }
  .footer-fixed__thumb {
    width: var(--sizeFixed);
    height: var(--sizeFixed);
    border-radius: 100px;
    color: var(--cl-sub);
    background-color: var(--cl-sub);
    transform: translate3d(0, 0, 0) scale(1);
    cursor: pointer; }
    .footer-fixed__thumb::before, .footer-fixed__thumb::after {
      position: absolute;
      content: ""; }
    .footer-fixed__thumb::before {
      top: 0;
      left: 0;
      width: 1em;
      height: 1em;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 100%;
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(0); }
    .footer-fixed__thumb::after {
      top: 0.25em;
      left: 0.25em;
      width: 0.5em;
      height: 0.5em;
      background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23FFF' d='M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z'/%3E%3C/svg%3E%0A");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      transform: translate3d(0, 0, 0); }
    .footer-fixed__thumb.is-animating {
      animation: phone-outer 3000ms infinite; }
      .footer-fixed__thumb.is-animating::before {
        animation: phone-inner 3000ms infinite; }
      .footer-fixed__thumb.is-animating::after {
        animation: phone-icon 3000ms infinite; }
  .footer-fixed-ls {
    position: absolute;
    bottom: calc(100% + 2rem);
    left: 0;
    min-width: 100%;
    display: flex;
    flex-direction: column-reverse;
    transition: .16s all linear;
    opacity: 0;
    visibility: hidden; }
    .footer-fixed-ls .item {
      transform: translateY(calc((100% + 2rem) * var(--i, 1)));
      transition: transform 0.3s linear;
      margin-top: 2rem;
      width: 100%; }
      .footer-fixed-ls .item a, .footer-fixed-ls .item img, .footer-fixed-ls .item svg {
        display: block;
        width: 100%;
        height: auto;
        object-fit: contain; }
      .footer-fixed-ls .item .phone-i {
        display: block;
        margin: 0;
        width: 1em;
        height: 1em;
        font-size: var(--sizeFixed);
        z-index: 2;
        background-color: var(--cl-sub);
        border-radius: 0.5em;
        box-shadow: 0 0 0 0em rgba(var(--cl-sub), 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
        transform: translate3d(0, 0, 0) scale(1); }
        .footer-fixed-ls .item .phone-i::before, .footer-fixed-ls .item .phone-i::after {
          position: absolute;
          content: ""; }
        .footer-fixed-ls .item .phone-i::before {
          top: 0;
          left: 0;
          width: 1em;
          height: 1em;
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 100%;
          opacity: 1;
          transform: translate3d(0, 0, 0) scale(0); }
        .footer-fixed-ls .item .phone-i::after {
          top: 0.25em;
          left: 0.25em;
          width: 0.5em;
          height: 0.5em;
          background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
          transform: translate3d(0, 0, 0); }
        .footer-fixed-ls .item .phone-i.is-animating {
          animation: phone-outer 3000ms infinite; }
          .footer-fixed-ls .item .phone-i.is-animating::before {
            animation: phone-inner 3000ms infinite; }
          .footer-fixed-ls .item .phone-i.is-animating::after {
            animation: phone-icon 3000ms infinite; }
  .footer-fixed.is-showed .footer-fixed-ls {
    opacity: 1;
    visibility: visible; }
    .footer-fixed.is-showed .footer-fixed-ls .item {
      transform: translateY(0%); }
  .footer-fixed.is-showed .footer-fixed__thumb {
    color: var(--cl-main);
    background-color: var(--cl-main); }

.breadcrumbs {
  width: 100%; }
  .breadcrumbs-list {
    display: flex;
    align-items: center;
    list-style-type: none; }
  .breadcrumbs-item {
    display: flex;
    align-items: center;
    line-height: 1.4;
    white-space: nowrap; }
    .breadcrumbs-item > * {
      font-size: 1.44rem;
      color: var(--cl-main);
      margin-right: 12px;
      font-weight: 400; }
      @media (min-width: 768px) {
        .breadcrumbs-item > * {
          font-size: 1.47rem; } }
      @media (min-width: 1200px) {
        .breadcrumbs-item > * {
          font-size: 1.5rem; } }
      @media (min-width: 1336px) {
        .breadcrumbs-item > * {
          font-size: 1.6rem; } }
    .breadcrumbs-item * {
      transition: .3s all linear; }
    .breadcrumbs-item.active .breadcrumbs-item__link {
      cursor: initial;
      pointer-events: none; }
    .breadcrumbs-item:hover .breadcrumbs-item__link {
      color: var(--cl-black); }

[data-popup] {
  cursor: pointer; }

.popup-careers {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  transition: .16s all linear; }
  .popup-careers.show {
    display: flex; }
    .popup-careers.show .modal {
      transform: scale(0) rotate(-90deg);
      animation-delay: .2s;
      animation: modalPopup .3s linear forwards; }
  .popup-careers::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: #171717;
    opacity: .5;
    border: 1px solid #707070; }
  .popup-careers * {
    transition: .16s all linear; }
  .popup-careers .modal {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    transform: scale(0); }
    .popup-careers .modal-wrapper {
      background-color: white;
      max-width: 94vw;
      width: 90rem;
      min-height: 40rem;
      height: auto;
      max-height: 84vh; }
      @media (min-width: 992px) {
        .popup-careers .modal-wrapper {
          margin-top: 2rem;
          margin-right: 2rem; } }
      @media (min-width: 1200px) {
        .popup-careers .modal-wrapper {
          margin-top: 3rem;
          margin-right: 3rem; } }
    .popup-careers .modal-close {
      position: absolute;
      top: -4rem;
      right: 0;
      width: 4rem;
      height: 4rem;
      z-index: 1;
      border-radius: 50%;
      background-color: transparent;
      transition: .2s all linear;
      cursor: pointer; }
      @media (min-width: 992px) {
        .popup-careers .modal-close {
          background-color: #FFFFFF;
          top: 0;
          right: 0;
          width: 4rem;
          height: 4rem; } }
      @media (min-width: 1200px) {
        .popup-careers .modal-close {
          width: 6rem;
          height: 6rem; } }
      .popup-careers .modal-close::before, .popup-careers .modal-close::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2.2rem;
        height: 0;
        transform: translate(-50%, -50%);
        border-top: 2px solid #FFF;
        transition: .2s all linear;
        user-select: none;
        pointer-events: none; }
        @media (min-width: 768px) {
          .popup-careers .modal-close::before, .popup-careers .modal-close::after {
            width: 42%; } }
        @media (min-width: 992px) {
          .popup-careers .modal-close::before, .popup-careers .modal-close::after {
            border-top: 2px solid #707070; } }
      .popup-careers .modal-close::before {
        transform: translate(-50%, -50%) rotate(-45deg); }
      .popup-careers .modal-close::after {
        transform: translate(-50%, -50%) rotate(45deg); }
      .popup-careers .modal-close:hover::before {
        transform: translate(-50%, -50%) rotate(-135deg); }
      .popup-careers .modal-close:hover::after {
        transform: translate(-50%, -50%) rotate(-45deg); }
    .popup-careers .modal .title {
      display: block;
      width: 100%;
      padding: 2rem 6rem;
      background-color: var(--cl-main);
      text-align: center; }
      .popup-careers .modal .title > * {
        color: var(--cl-w);
        font-size: 1.62rem;
        font-weight: 700;
        line-height: 1.4; }
        @media (min-width: 768px) {
          .popup-careers .modal .title > * {
            font-size: 1.66rem; } }
        @media (min-width: 1200px) {
          .popup-careers .modal .title > * {
            font-size: 1.7rem; } }
        @media (min-width: 1336px) {
          .popup-careers .modal .title > * {
            font-size: 1.8rem; } }
    .popup-careers .modal .formBrn {
      padding-left: 3rem;
      padding-right: 3rem; }
      @media (min-width: 768px) {
        .popup-careers .modal .formBrn {
          padding-left: 4rem;
          padding-right: 4rem; } }
      @media (min-width: 992px) {
        .popup-careers .modal .formBrn {
          padding-left: 6rem;
          padding-right: 6rem; } }
      .popup-careers .modal .formBrn input, .popup-careers .modal .formBrn select, .popup-careers .modal .formBrn textarea, .popup-careers .modal .formBrn button, .popup-careers .modal .formBrn .braney-more {
        border-radius: 10px; }
      .popup-careers .modal .formBrn .inputSubmit {
        width: 100%;
        padding-right: 0; }

@keyframes dotAnimation {
  from {
    transform: translateY(-8px); }
  to {
    transform: translateY(0); } }

.tools {
  position: fixed;
  z-index: 999;
  top: 12rem;
  left: 0;
  display: flex;
  flex-direction: row-reverse;
  transition: .3s all linear; }
  @media (min-width: 992px) {
    .tools {
      top: 24rem;
      transform: translateX(-14rem); } }
  .tools.show {
    transform: translateX(-110%); }
    @media (min-width: 992px) {
      .tools.show {
        transform: translateX(0); } }
    .tools.show .tools-pc {
      height: auto;
      border-top: 2rem solid var(--cl-main);
      border-bottom: 2rem solid var(--cl-main);
      padding: 1rem 0;
      box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16); }
    .tools.show + .tools-mobile {
      display: flex; }
      @media (min-width: 992px) {
        .tools.show + .tools-mobile {
          display: none; } }
      .tools.show + .tools-mobile .modal {
        transform: scale(0) rotate(-90deg);
        animation-delay: .2s;
        animation: modalPopup .3s linear forwards; }
  .tools.scroll {
    top: 12rem; }
  .tools-btn {
    background-color: var(--cl-main);
    width: 6rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%; }
    @media (min-width: 992px) {
      .tools-btn {
        width: 7.7rem;
        height: 7.7rem; } }
    .tools-btn .btn {
      cursor: pointer;
      position: relative;
      width: 54%;
      height: 54%;
      background-color: var(--cl-sub);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center; }
      .tools-btn .btn span {
        width: 15.7%;
        height: 15.7%;
        background-color: var(--cl-w);
        border-radius: 50%;
        user-select: none;
        pointer-events: none;
        margin-left: .2rem;
        margin-right: .2rem;
        animation: bounceDot 1s infinite;
        animation-delay: var(--i); }
  .tools-pc {
    width: 14rem;
    background-color: white;
    display: none;
    max-height: 64vh;
    height: 0;
    border-top: none;
    border-bottom: none;
    padding: 0 0;
    box-shadow: none;
    transition: .16s all; }
    @media (min-width: 992px) {
      .tools-pc {
        display: flex;
        flex-direction: column; } }
    .tools-pc__item {
      cursor: pointer;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      padding: 1.5rem 2rem; }
      .tools-pc__item * {
        transition: .16s all; }
      .tools-pc__item .icon {
        margin-bottom: 1rem; }
      .tools-pc__item > * {
        font-size: 1.35rem;
        font-weight: 700;
        color: var(--cl-black); }
        @media (min-width: 768px) {
          .tools-pc__item > * {
            font-size: 1.38rem; } }
        @media (min-width: 1200px) {
          .tools-pc__item > * {
            font-size: 1.41rem; } }
        @media (min-width: 1336px) {
          .tools-pc__item > * {
            font-size: 1.5rem; } }
      .tools-pc__item:hover {
        background-color: var(--cl-sub); }
        .tools-pc__item:hover svg [stroke]:not([stroke="none"]) {
          stroke: var(--cl-w); }
        .tools-pc__item:hover svg [fill]:not([fill="none"]) {
          fill: var(--cl-w); }
        .tools-pc__item:hover > * {
          color: var(--cl-w); }
  .tools-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    transition: .16s all linear; }
    .tools-mobile.show {
      display: flex; }
      .tools-mobile.show .modal {
        transform: scale(0) rotate(-90deg);
        animation-delay: .2s;
        animation: modalPopup .3s linear forwards; }
    .tools-mobile::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: #171717;
      opacity: .5;
      border: 1px solid #707070; }
    .tools-mobile * {
      transition: .16s all linear; }
    .tools-mobile .modal {
      width: 30rem;
      height: 30rem;
      position: relative;
      border: 3px solid var(--cl-main);
      box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16);
      background-color: var(--cl-w);
      border-radius: 50%;
      z-index: 1;
      transform: scale(0);
      display: block; }
      @media (min-width: 768px) {
        .tools-mobile .modal {
          width: 36rem;
          height: 36rem; } }
      .tools-mobile .modal-close {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4rem;
        height: 4rem;
        z-index: 11;
        cursor: pointer;
        background-color: var(--cl-sub);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media (min-width: 768px) {
          .tools-mobile .modal-close {
            width: 4.6rem;
            height: 4.6rem; } }
        .tools-mobile .modal-close::before, .tools-mobile .modal-close::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 54%;
          height: 0;
          transform: translate(-50%, -50%);
          border-top: 2px solid #FFF;
          transition: .2s all linear;
          user-select: none;
          pointer-events: none; }
          @media (min-width: 992px) {
            .tools-mobile .modal-close::before, .tools-mobile .modal-close::after {
              border-top: 2px solid #707070; } }
        .tools-mobile .modal-close::before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        .tools-mobile .modal-close::after {
          transform: translate(-50%, -50%) rotate(45deg); }
        .tools-mobile .modal-close:hover::before {
          transform: translate(-50%, -50%) rotate(-135deg); }
        .tools-mobile .modal-close:hover::after {
          transform: translate(-50%, -50%) rotate(-45deg); }
      .tools-mobile .modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center; }
        .tools-mobile .modal-wrapper li {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
          .tools-mobile .modal-wrapper li a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: var(--cl-black);
            font-weight: 700; }
          .tools-mobile .modal-wrapper li .icon {
            margin-bottom: .6rem;
            width: 4rem;
            height: 4rem; }
            .tools-mobile .modal-wrapper li .icon svg {
              width: 100%;
              height: 100%; }
            @media (min-width: 768px) {
              .tools-mobile .modal-wrapper li .icon {
                width: 4.2rem;
                height: 4.2rem; } }
          .tools-mobile .modal-wrapper li span {
            font-weight: 700;
            font-size: 10px;
            color: var(--cl-black); }
            @media (min-width: 768px) {
              .tools-mobile .modal-wrapper li span {
                font-size: 12px; } }
        .tools-mobile .modal-wrapper li:nth-of-type(1) {
          top: 76%;
          left: 66%; }
        .tools-mobile .modal-wrapper li:nth-of-type(4) {
          top: 76%;
          left: 34%; }
        .tools-mobile .modal-wrapper li:nth-of-type(2) {
          top: 46%;
          left: 78%; }
        .tools-mobile .modal-wrapper li:nth-of-type(3) {
          top: 46%;
          left: 22%; }
        .tools-mobile .modal-wrapper li:nth-of-type(5) {
          top: 22%;
          left: 50%; }

.scroll-to-top {
  z-index: 300;
  position: fixed;
  bottom: 8rem;
  right: 2vw;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .5s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: linear-gradient(to right, var(--cl-main) 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--cl-main) 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, var(--cl-main) 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--cl-main) 80%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 20px 2px, 2px 20px;
  transition: .3s all linear; }
  @media (min-width: 768px) {
    .scroll-to-top {
      bottom: 8rem;
      width: 60px;
      height: 60px; } }
  .scroll-to-top svg {
    transform-origin: center; }
  .scroll-to-top svg {
    width: 52%; }
  .scroll-to-top:hover {
    background-color: var(--cl-main); }
    .scroll-to-top:hover path[fill] {
      fill: var(--cl-w); }
    .scroll-to-top:hover path[stroke] {
      stroke: var(--cl-w); }

.idx-1 {
  position: relative;
  background-color: var(--cl-w);
  max-width: 100%;
  overflow: hidden; }
  .idx-1-bg_right, .idx-1-bg_left {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    opacity: .16; }
    @media (min-width: 992px) {
      .idx-1-bg_right, .idx-1-bg_left {
        opacity: 1;
        object-fit: contain; } }
  .idx-1-bg_right {
    object-position: right center;
    height: 100%;
    bottom: 0;
    right: 0;
    width: 100%;
    display: none; }
    @media (min-width: 992px) {
      .idx-1-bg_right {
        width: 48%;
        display: block; } }
  .idx-1-bg_left {
    width: 100%;
    height: 100%;
    object-position: left center; }
  .idx-1-wrapper {
    position: relative;
    z-index: 2; }
    .idx-1-wrapper > *:not(:first-child) {
      margin-top: 1.6rem; }
      @media (min-width: 768px) {
        .idx-1-wrapper > *:not(:first-child) {
          margin-top: 2rem; } }
  .idx-1-links {
    margin-top: 3.2rem !important; }
    @media (min-width: 768px) {
      .idx-1-links {
        margin-top: 4rem !important; } }
  @media (min-width: 992px) {
    .idx-1 .braney-title {
      width: 86%; } }
  @media (min-width: 1200px) {
    .idx-1 .braney-title {
      width: 82%; } }

.idx-2 {
  max-width: 100%;
  overflow: hidden; }
  .idx-2 .braney-subtitle {
    justify-content: center;
    text-align: center; }
  .idx-2 .braney-title {
    margin-top: 2rem;
    text-align: center; }
  .idx-2-swiper {
    position: relative; }

.idx-3 {
  position: relative;
  max-width: 100%;
  overflow: hidden; }
  .idx-3-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2; }
    @media (min-width: 992px) {
      .idx-3-bg {
        height: 380px; } }
    @media (min-width: 1200px) {
      .idx-3-bg {
        height: 440px; } }
    .idx-3-bg img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block; }
    .idx-3-bg::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0000004D; }
  .idx-3 .braney-title {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 576px) {
      .idx-3 .braney-title {
        width: 74%; } }
    @media (min-width: 768px) {
      .idx-3 .braney-title {
        width: 64%; } }
    @media (min-width: 768px) {
      .idx-3 .braney-title {
        width: 64%; } }
    @media (min-width: 992px) {
      .idx-3 .braney-title {
        width: 48%; } }
    .idx-3 .braney-title > * {
      color: var(--cl-w);
      line-height: 1.4; }
  .idx-3-swiper {
    position: relative; }
    .idx-3-swiper .swiper-custom-button-next,
    .idx-3-swiper .swiper-custom-button-prev {
      top: calc(50% + 4.8rem); }
    @media (max-width: 991px) {
      .idx-3-swiper {
        padding-right: 6rem;
        padding-left: 6rem; } }
    @media (max-width: 767px) {
      .idx-3-swiper {
        padding-right: 4rem;
        padding-left: 4rem; } }
  @media (max-width: 991px) {
    .idx-3 {
      color: var(--cl-w) !important; }
      .idx-3 .braney-more > * {
        color: var(--cl-w); } }

.idx-4 {
  max-width: 100%;
  overflow: hidden; }
  @media (max-width: 991px) {
    .idx-4 {
      padding-top: 6.4rem; } }
  @media (max-width: 991px) and (min-width: 768px) {
    .idx-4 {
      padding-top: 6.72rem; } }
  @media (max-width: 991px) and (min-width: 992px) {
    .idx-4 {
      padding-top: 7.2rem; } }
  @media (max-width: 991px) and (min-width: 1336px) {
    .idx-4 {
      padding-top: 8rem; } }
  .idx-4-top {
    margin-top: 2rem;
    align-items: flex-end; }
    .idx-4-top .braney-more {
      text-align: right;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center; }
      @media (min-width: 768px) {
        .idx-4-top .braney-more {
          justify-content: flex-end;
          align-items: flex-end;
          text-align: right; } }
  .idx-4-tabs .tab-item {
    cursor: pointer;
    margin-bottom: 2rem;
    justify-content: center;
    text-align: center; }
    @media (min-width: 768px) {
      .idx-4-tabs .tab-item {
        margin-bottom: 0;
        justify-content: flex-start;
        text-align: left; } }
    .idx-4-tabs .tab-item > * {
      display: inline-block;
      transition: .3s all;
      position: relative;
      line-height: 1.5;
      padding-bottom: 8px; }
      .idx-4-tabs .tab-item > *::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 2px;
        border-bottom: 1px dashed var(--cl-main);
        transition: .3s all;
        transform-origin: center; }
    .idx-4-tabs .tab-item.active > * {
      color: var(--cl-main);
      opacity: 1; }
      .idx-4-tabs .tab-item.active > *::after {
        width: 100%; }
    .idx-4-tabs .tab-item:hover > * {
      color: var(--cl-main);
      opacity: .9; }
  .idx-4-swiper {
    position: relative;
    display: none; }
    .idx-4-swiper .swiper-wrapper {
      padding: 6px 3px 6px 3px; }
    .idx-4-swiper.active {
      display: block; }

.idx-5 {
  background-color: var(--cl-lv-8);
  max-width: 100%;
  overflow: hidden;
  position: relative; }
  .idx-5 .swiper-wrapper {
    padding: 6px 3px 6px 3px; }
  .idx-5-bg {
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover; }
    @media (min-width: 992px) {
      .idx-5-bg {
        object-fit: contain; } }
  .idx-5 .container {
    position: relative;
    z-index: 1; }
  .idx-5 .braney-title {
    width: 90%;
    margin-left: auto;
    margin-right: auto; }
    @media (min-width: 992px) {
      .idx-5 .braney-title {
        width: 100%; } }
  .idx-5 .swiper-wrapper {
    transition-timing-function: linear !important; }

.idx-6 {
  position: relative;
  padding: 2rem;
  max-width: 100%;
  overflow: hidden; }
  @media (min-width: 992px) {
    .idx-6 {
      padding: 4rem; } }
  .idx-6 > .row {
    align-items: flex-start; }
  .idx-6-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; }
  .idx-6-maps {
    padding: 1.5rem;
    background-color: var(--cl-lv-7); }
  .idx-6-content {
    background-color: var(--cl-w);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 2rem 3rem;
    overflow: auto;
    max-height: 440px;
    --wScroll: 5px;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */ }
    @media (min-width: 992px) {
      .idx-6-content {
        padding: 4rem 6rem; } }
    @media (min-width: 992px) {
      .idx-6-content {
        max-height: initial; } }
    @media (min-width: 992px) {
      .idx-6-content {
        --wScroll: 7px; } }
    .idx-6-content::-webkit-scrollbar {
      width: var(--wScroll); }
    .idx-6-content::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px var(--cl-lv-5);
      border-radius: var(--wScroll); }
    .idx-6-content::-webkit-scrollbar-thumb {
      background: var(--cl-main);
      border-radius: var(--wScroll); }
    .idx-6-content::-webkit-scrollbar-thumb:hover {
      background: var(--cl-lv-3); }
    .idx-6-content .item ~ .item {
      margin-top: 2rem; }
    .idx-6-content .item-content {
      color: var(--cl-gray);
      font-size: 1.44rem;
      line-height: 1.8; }
      @media (min-width: 768px) {
        .idx-6-content .item-content {
          font-size: 1.47rem; } }
      @media (min-width: 1200px) {
        .idx-6-content .item-content {
          font-size: 1.5rem; } }
      @media (min-width: 1336px) {
        .idx-6-content .item-content {
          font-size: 1.6rem; } }
      .idx-6-content .item-content h1, .idx-6-content .item-content h2, .idx-6-content .item-content h3, .idx-6-content .item-content h4, .idx-6-content .item-content h5, .idx-6-content .item-content h6 {
        color: var(--cl-black);
        margin-bottom: 1rem; }
        .idx-6-content .item-content h1 a, .idx-6-content .item-content h2 a, .idx-6-content .item-content h3 a, .idx-6-content .item-content h4 a, .idx-6-content .item-content h5 a, .idx-6-content .item-content h6 a {
          font-weight: 700;
          font-size: 1.8rem;
          color: var(--cl-black);
          display: inline-block;
          transition: .3s all; }
          @media (min-width: 768px) {
            .idx-6-content .item-content h1 a, .idx-6-content .item-content h2 a, .idx-6-content .item-content h3 a, .idx-6-content .item-content h4 a, .idx-6-content .item-content h5 a, .idx-6-content .item-content h6 a {
              font-size: 1.84rem; } }
          @media (min-width: 1200px) {
            .idx-6-content .item-content h1 a, .idx-6-content .item-content h2 a, .idx-6-content .item-content h3 a, .idx-6-content .item-content h4 a, .idx-6-content .item-content h5 a, .idx-6-content .item-content h6 a {
              font-size: 1.88rem; } }
          @media (min-width: 1336px) {
            .idx-6-content .item-content h1 a, .idx-6-content .item-content h2 a, .idx-6-content .item-content h3 a, .idx-6-content .item-content h4 a, .idx-6-content .item-content h5 a, .idx-6-content .item-content h6 a {
              font-size: 2rem; } }
          .idx-6-content .item-content h1 a:hover, .idx-6-content .item-content h2 a:hover, .idx-6-content .item-content h3 a:hover, .idx-6-content .item-content h4 a:hover, .idx-6-content .item-content h5 a:hover, .idx-6-content .item-content h6 a:hover {
            color: var(--cl-sub); }
    .idx-6-content .item-contact {
      margin-top: 1rem;
      font-size: 1.62rem;
      color: var(--cl-black);
      line-height: 1.8; }
      @media (min-width: 768px) {
        .idx-6-content .item-contact {
          font-size: 1.66rem; } }
      @media (min-width: 1200px) {
        .idx-6-content .item-contact {
          font-size: 1.7rem; } }
      @media (min-width: 1336px) {
        .idx-6-content .item-contact {
          font-size: 1.8rem; } }
      .idx-6-content .item-contact__bottom {
        display: flex;
        flex-direction: column; }
        @media (min-width: 1500px) {
          .idx-6-content .item-contact__bottom {
            flex-direction: row; } }
        .idx-6-content .item-contact__bottom > *:not(:first-child) {
          margin-left: 0; }
          @media (min-width: 1500px) {
            .idx-6-content .item-contact__bottom > *:not(:first-child) {
              margin-left: 6vw; } }

.s-1 {
  background-color: var(--cl-lv-10); }
  .s-1-wrapper > .row {
    justify-content: center; }

@media (min-width: 992px) {
  .sd-1 .wrapper-content {
    margin-left: 3rem; } }

@media (min-width: 1200px) {
  .sd-1 .wrapper-content {
    margin-left: 4rem; } }

.sd-1 .wrapper-content h1 {
  width: 100%; }
  @media (min-width: 1200px) {
    .sd-1 .wrapper-content h1 {
      width: 94%; } }

@media (min-width: 992px) {
  .sd-1 .tag, .sd-1 .socials {
    margin-left: 3rem; } }

@media (min-width: 1200px) {
  .sd-1 .tag, .sd-1 .socials {
    margin-left: 4rem; } }

.n-1 {
  position: relative;
  background-color: var(--cl-lv-10); }
  .n-1-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
    object-position: top center; }
    @media (min-width: 992px) {
      .n-1-bg {
        object-fit: contain; } }
  .n-1 > .container {
    position: relative;
    z-index: 1; }
  .n-1-tabs {
    margin-top: 2.4rem;
    margin-bottom: 4.8rem;
    display: flex;
    justify-content: center; }
    @media (min-width: 768px) {
      .n-1-tabs {
        margin-top: 2.52rem; } }
    @media (min-width: 992px) {
      .n-1-tabs {
        margin-top: 2.7rem; } }
    @media (min-width: 1336px) {
      .n-1-tabs {
        margin-top: 3rem; } }
    @media (min-width: 768px) {
      .n-1-tabs {
        margin-bottom: 5.04rem; } }
    @media (min-width: 992px) {
      .n-1-tabs {
        margin-bottom: 5.4rem; } }
    @media (min-width: 1336px) {
      .n-1-tabs {
        margin-bottom: 6rem; } }
    .n-1-tabs .tab-list {
      margin-left: auto;
      margin-right: auto;
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: var(--cl-w);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      flex-direction: column; }
      @media (min-width: 768px) {
        .n-1-tabs .tab-list {
          flex-direction: row; } }
    .n-1-tabs .tab-item {
      padding: 1.6rem 3rem;
      cursor: pointer; }
      @media (min-width: 992px) {
        .n-1-tabs .tab-item {
          padding: 1.6rem 8rem; } }
      .n-1-tabs .tab-item > * {
        font-weight: 700;
        font-size: 2.52rem; }
        @media (min-width: 768px) {
          .n-1-tabs .tab-item > * {
            font-size: 2.58rem; } }
        @media (min-width: 1200px) {
          .n-1-tabs .tab-item > * {
            font-size: 2.63rem; } }
        @media (min-width: 1336px) {
          .n-1-tabs .tab-item > * {
            font-size: 2.8rem; } }
      @media (max-width: 991px) {
        .n-1-tabs .tab-item:not(:first-child) {
          padding-top: 0; } }
      .n-1-tabs .tab-item > * {
        display: inline-block;
        transition: .3s all;
        position: relative;
        line-height: 1.5;
        padding-bottom: 8px; }
        .n-1-tabs .tab-item > *::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 2px;
          border-bottom: 1px dashed var(--cl-main);
          transition: .3s all;
          transform-origin: center; }
      .n-1-tabs .tab-item.active > * {
        color: var(--cl-main);
        opacity: 1; }
        .n-1-tabs .tab-item.active > *::after {
          width: 100%; }
      .n-1-tabs .tab-item:hover > * {
        color: var(--cl-main);
        opacity: .9; }
  .n-1-wrapper {
    position: relative;
    z-index: 1; }
  .n-1-option {
    display: none; }
    .n-1-option.active {
      display: block; }
    .n-1-option > .row {
      justify-content: center; }
    @media (max-width: 767px) {
      .n-1-option .box-article-block {
        --ratioHeight: 144.45%;
        --ratioHeightHover: 60%; } }
    @media (max-width: 575px) {
      .n-1-option .box-article-block {
        --ratioHeight: 132%; } }

.nd-1-title > * {
  font-size: 2.62rem;
  color: #171717;
  line-height: 1.6; }
  @media (min-width: 768px) {
    .nd-1-title > * {
      font-size: 2.75rem; } }
  @media (min-width: 1200px) {
    .nd-1-title > * {
      font-size: 2.88rem; } }
  @media (min-width: 1336px) {
    .nd-1-title > * {
      font-size: 3.2rem; } }

.nd-1-time {
  font-size: 1.62rem;
  font-style: italic;
  color: var(--cl-main);
  margin-top: 2rem; }
  @media (min-width: 768px) {
    .nd-1-time {
      font-size: 1.66rem; } }
  @media (min-width: 1200px) {
    .nd-1-time {
      font-size: 1.7rem; } }
  @media (min-width: 1336px) {
    .nd-1-time {
      font-size: 1.8rem; } }

.nd-1 .wrapper-content {
  margin-top: 2rem; }

@media (min-width: 992px) {
  .nd-1 .spaceRight {
    margin-right: 3rem; } }

@media (min-width: 1200px) {
  .nd-1 .spaceRight {
    margin-right: 4rem; } }

.nd-1-nav {
  width: 100%;
  padding: 4rem;
  border-top: 5px solid var(--cl-main); }
  @media (max-width: 991px) {
    .nd-1-nav {
      margin-top: 4.8rem; } }
  @media (max-width: 991px) and (min-width: 768px) {
    .nd-1-nav {
      margin-top: 5.04rem; } }
  @media (max-width: 991px) and (min-width: 992px) {
    .nd-1-nav {
      margin-top: 5.4rem; } }
  @media (max-width: 991px) and (min-width: 1336px) {
    .nd-1-nav {
      margin-top: 6rem; } }
  .nd-1-nav .nav-bar-item > * {
    font-size: 1.62rem;
    font-weight: 700; }
    @media (min-width: 768px) {
      .nd-1-nav .nav-bar-item > * {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .nd-1-nav .nav-bar-item > * {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .nd-1-nav .nav-bar-item > * {
        font-size: 1.8rem; } }

.nd-1-relate {
  padding-top: 4.8rem; }
  @media (min-width: 768px) {
    .nd-1-relate {
      padding-top: 5.04rem; } }
  @media (min-width: 992px) {
    .nd-1-relate {
      padding-top: 5.4rem; } }
  @media (min-width: 1336px) {
    .nd-1-relate {
      padding-top: 6rem; } }

.nd-1-relate {
  display: none; }
  .nd-1-relate.active {
    display: block; }
  .nd-1-relate__title > * {
    font-size: 2.62rem;
    color: var(--cl-gray); }
    @media (min-width: 768px) {
      .nd-1-relate__title > * {
        font-size: 2.75rem; } }
    @media (min-width: 1200px) {
      .nd-1-relate__title > * {
        font-size: 2.88rem; } }
    @media (min-width: 1336px) {
      .nd-1-relate__title > * {
        font-size: 3.2rem; } }
  .nd-1-relate-boxes {
    padding-top: 3.2rem;
    position: relative; }
    @media (min-width: 768px) {
      .nd-1-relate-boxes {
        padding-top: 3.36rem; } }
    @media (min-width: 992px) {
      .nd-1-relate-boxes {
        padding-top: 3.6rem; } }
    @media (min-width: 1336px) {
      .nd-1-relate-boxes {
        padding-top: 4rem; } }
    @media (min-width: 992px) {
      .nd-1-relate-boxes .swiper-custom-button {
        display: none; } }
    @media (min-width: 992px) {
      .nd-1-relate-boxes .swiper {
        overflow: initial; }
      .nd-1-relate-boxes .swiper-wrapper {
        flex-direction: column; }
      .nd-1-relate-boxes .swiper-slide:not(:first-child) {
        margin-top: 4rem; } }

.contact-1 {
  position: relative;
  background-color: var(--cl-lv-10); }
  .contact-1 > .container {
    position: relative;
    z-index: 1; }
  .contact-1-bg {
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover; }
    @media (min-width: 768px) {
      .contact-1-bg {
        object-fit: contain; } }

.contact-2 {
  background-color: var(--cl-lv-10); }
  .contact-2 > .container {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background-color: white;
    padding-left: 3rem;
    padding-right: 3rem; }
    @media (min-width: 768px) {
      .contact-2 > .container {
        padding-left: 6rem;
        padding-right: 6rem; } }
    @media (min-width: 992px) {
      .contact-2 > .container {
        padding-left: 8rem;
        padding-right: 8rem; } }
    .contact-2 > .container form input, .contact-2 > .container form textarea, .contact-2 > .container form select, .contact-2 > .container form button {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
      white-space: nowrap; }
    .contact-2 > .container form input, .contact-2 > .container form textarea, .contact-2 > .container form select,
    .contact-2 > .container form button,
    .contact-2 > .container form .inputSubmit .braney-more {
      border-radius: 10px; }
    @media (min-width: 768px) {
      .contact-2 > .container form .inputSubmit {
        width: 100%;
        padding-right: 0; } }

.about-1 {
  max-width: 100%;
  overflow: hidden;
  background-color: var(--cl-lv-10); }
  .about-1__img {
    position: relative;
    height: 100%; }
    @media (min-width: 992px) {
      .about-1__img {
        margin-right: 2.6rem; } }
    .about-1__img .img {
      display: block;
      height: 100%;
      object-fit: cover;
      max-height: 80rem; }
    .about-1__img .logo {
      object-fit: contain;
      position: absolute;
      top: 4vh;
      left: 2rem;
      width: 18vw;
      height: auto;
      z-index: 1;
      display: block;
      transform-origin: center center; }
      @media (min-width: 992px) {
        .about-1__img .logo {
          width: 40%;
          transform: translate(calc(-50% - 2rem), 0); } }
      .about-1__img .logo img {
        display: block; }
  .about-1 .wrapper-content {
    margin-top: 2.4rem;
    color: var(--cl-black); }
    .about-1 .wrapper-content p {
      color: var(--cl-black);
      line-height: 1.6; }

@keyframes activeImg {
  0% {
    z-index: -1;
    opacity: 0;
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%); }
  30% {
    z-index: 0;
    opacity: 0;
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%); }
  100% {
    opacity: 1;
    z-index: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); } }

.about-2 {
  position: relative;
  background-color: var(--cl-lv-9); }
  .about-2 > * {
    position: relative;
    z-index: 1; }
  .about-2 * {
    transition: 0.3s all; }
  .about-2-bg {
    z-index: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: right center; }
  .about-2-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center; }
    @media (min-width: 768px) {
      .about-2-wrapper {
        justify-content: flex-end; } }
    .about-2-wrapper .left {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      overflow: hidden; }
      @media (min-width: 768px) {
        .about-2-wrapper .left {
          width: 70%; } }
      .about-2-wrapper .left img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        transition: 0.6s all ease-in-out; }
        .about-2-wrapper .left img[data-select] {
          z-index: -1;
          opacity: 0; }
          .about-2-wrapper .left img[data-select].active {
            opacity: 1;
            z-index: 1; }
    .about-2-wrapper .right {
      padding: 4rem 0;
      width: 90%; }
      @media (min-width: 992px) {
        .about-2-wrapper .right {
          width: 60%; } }
      @media (min-width: 1336px) {
        .about-2-wrapper .right {
          width: 50%; } }
      @media (min-width: 1500px) {
        .about-2-wrapper .right {
          width: 46%; } }
    .about-2-wrapper .statements {
      background-color: var(--cl-w);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      min-height: auto;
      padding: 4rem 4rem; }
      @media (min-width: 768px) {
        .about-2-wrapper .statements {
          padding: 4rem 5rem; } }
      @media (min-width: 1336px) {
        .about-2-wrapper .statements {
          padding: 4rem 6rem; } }
      @media (min-width: 1500px) {
        .about-2-wrapper .statements {
          padding: 4rem 8rem; } }
      .about-2-wrapper .statements-opts {
        margin-top: 2rem;
        display: none; }
        .about-2-wrapper .statements-opts.active {
          display: block; }
      .about-2-wrapper .statements-btns {
        width: 100%;
        margin-top: 2rem;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        flex-direction: column; }
        @media (min-width: 768px) {
          .about-2-wrapper .statements-btns {
            width: 100%;
            flex-direction: row;
            justify-content: space-between; } }
        .about-2-wrapper .statements-btns::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, 0);
          width: calc(100% - 3rem);
          height: 0;
          border-top: 2px solid #707070;
          z-index: 0;
          display: none; }
          @media (min-width: 768px) {
            .about-2-wrapper .statements-btns::after {
              display: block;
              content: ""; } }
        .about-2-wrapper .statements-btns > * {
          position: relative;
          z-index: 1; }
        .about-2-wrapper .statements-btns__item {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1.2rem 1.6rem;
          min-width: 100%;
          transition: 0.3s all;
          font-weight: 700;
          font-size: 1.34rem;
          line-height: 1.6;
          background-color: var(--cl-sub);
          color: var(--cl-w);
          border: 2px solid var(--cl-gray);
          transition: 0.3s all; }
          @media (min-width: 768px) {
            .about-2-wrapper .statements-btns__item {
              min-width: 148px; } }
          .about-2-wrapper .statements-btns__item svg {
            margin-right: 0rem;
            transform: scaleX(0);
            transition: 0.3s all; }
          .about-2-wrapper .statements-btns__item span {
            white-space: nowrap; }
          .about-2-wrapper .statements-btns__item * {
            transition: 0.3s all; }
          @media (min-width: 768px) {
            .about-2-wrapper .statements-btns__item {
              font-size: 1.35rem; } }
          @media (min-width: 1200px) {
            .about-2-wrapper .statements-btns__item {
              font-size: 1.38rem; } }
          @media (min-width: 1336px) {
            .about-2-wrapper .statements-btns__item {
              font-size: 1.4rem; } }
          .about-2-wrapper .statements-btns__item path {
            transition: 0.3s all; }
          .about-2-wrapper .statements-btns__item::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            background: linear-gradient(to right, #303090 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(#303090 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #303090 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(#303090 80%, rgba(255, 255, 255, 0) 0%);
            background-position: top, right, bottom, left;
            background-repeat: repeat-x, repeat-y;
            background-size: 0px 0px, 0px 0px;
            transform-origin: center;
            transition: 1.6s all; }
          .about-2-wrapper .statements-btns__item::before {
            content: "";
            position: absolute;
            top: 3px;
            left: 3px;
            right: 3px;
            bottom: 3px;
            background-color: transparent;
            z-index: -1;
            transition: 0.3s all; }
            @media (min-width: 768px) {
              .about-2-wrapper .statements-btns__item::before {
                top: 4px;
                left: 4px;
                right: 4px;
                bottom: 4px; } }
          .about-2-wrapper .statements-btns__item.active {
            color: var(--cl-w);
            background-color: transparent;
            border: 2px solid transparent;
            position: relative; }
            .about-2-wrapper .statements-btns__item.active svg {
              margin-right: 0.8rem;
              transform: scaleX(1); }
            .about-2-wrapper .statements-btns__item.active::before {
              background-color: var(--cl-sub); }
            .about-2-wrapper .statements-btns__item.active::after {
              background-size: 16px 1px, 1px 16px; }
              @media (min-width: 768px) {
                .about-2-wrapper .statements-btns__item.active::after {
                  background-size: 20px 2px, 2px 20px; } }
        .about-2-wrapper .statements-btns .statements-btns__item + .statements-btns__item {
          margin-top: 12px; }
          @media (min-width: 768px) {
            .about-2-wrapper .statements-btns .statements-btns__item + .statements-btns__item {
              margin-top: 0;
              margin-left: 3rem; } }
          @media (min-width: 992px) {
            .about-2-wrapper .statements-btns .statements-btns__item + .statements-btns__item {
              margin-left: 3rem; } }
    .about-2-wrapper .wrapper-content {
      margin-top: 2rem;
      color: var(--cl-black); }
      .about-2-wrapper .wrapper-content p {
        color: var(--cl-black);
        line-height: 1.6; }

.about-3 {
  background-color: var(--cl-lv-10); }
  .about-3-swiper, .about-3-swiper2 {
    position: relative; }
    .about-3-swiper .swiper, .about-3-swiper2 .swiper {
      padding: 10px;
      margin: 0 -10px; }
  .about-3__item {
    display: block;
    padding: 0 2.6rem 5rem 2.6rem;
    position: relative;
    cursor: pointer; }
    .about-3__item::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60%;
      background-color: #FFF;
      z-index: -1;
      box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.16); }
    .about-3__item img {
      display: block; }
    .about-3__item:hover img {
      animation-name: bounce;
      animation-duration: 1s;
      transform-origin: center bottom; }

.about-4 {
  position: relative; }
  .about-4-bg {
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover; }
    @media (min-width: 992px) {
      .about-4-bg {
        object-fit: contain; } }
  .about-4-swiper {
    position: relative; }
    .about-4-swiper .swiper {
      padding: 6px 3px; }
    .about-4-swiper .swiper-custom-button-prev,
    .about-4-swiper .swiper-custom-button-next {
      top: 13.5rem; }
  .about-4__item {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: default; }
    .about-4__item .desc {
      cursor: inherit;
      display: block;
      padding: 5rem 6rem 7rem 6rem;
      background-color: var(--cl-w);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px; }
      @media (min-width: 576px) {
        .about-4__item .desc {
          padding: 5rem 6rem 7rem 6rem; } }
      .about-4__item .desc .overflow-y {
        height: 15rem; }
      .about-4__item .desc * {
        color: #707070;
        font-weight: 500;
        font-size: 1.62rem;
        line-height: 1.5;
        transition: .3s all; }
        @media (min-width: 768px) {
          .about-4__item .desc * {
            font-size: 1.66rem; } }
        @media (min-width: 1200px) {
          .about-4__item .desc * {
            font-size: 1.7rem; } }
        @media (min-width: 1336px) {
          .about-4__item .desc * {
            font-size: 1.8rem; } }
    .about-4__item .avt {
      position: relative;
      margin-top: -5rem;
      width: 10rem;
      height: 10rem;
      border-radius: 50%; }
      .about-4__item .avt > * {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center; }
    .about-4__item .name {
      margin-top: 1rem; }
      .about-4__item .name > * {
        color: #00396D;
        font-weight: 700;
        font-size: 1.62rem;
        line-height: 1.5; }
        @media (min-width: 768px) {
          .about-4__item .name > * {
            font-size: 1.66rem; } }
        @media (min-width: 1200px) {
          .about-4__item .name > * {
            font-size: 1.7rem; } }
        @media (min-width: 1336px) {
          .about-4__item .name > * {
            font-size: 1.8rem; } }
    .about-4__item .major {
      margin-top: .4rem; }
      .about-4__item .major > * {
        color: #4B4B4B;
        font-weight: 500;
        font-size: 1.44rem;
        line-height: 1.3; }
        @media (min-width: 768px) {
          .about-4__item .major > * {
            font-size: 1.47rem; } }
        @media (min-width: 1200px) {
          .about-4__item .major > * {
            font-size: 1.5rem; } }
        @media (min-width: 1336px) {
          .about-4__item .major > * {
            font-size: 1.6rem; } }
    .about-4__item .name, .about-4__item .major, .about-4__item .avt {
      margin-left: 6rem; }
    .about-4__item:hover .desc {
      animation-name: bounce;
      animation-duration: 1s;
      transform-origin: center bottom; }
      .about-4__item:hover .desc > * {
        position: relative;
        z-index: 11;
        color: #000; }

.st-1 {
  background-color: var(--cl-lv-10); }
  .st-1-teams .team {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 320px; }
    @media (min-width: 992px) {
      .st-1-teams .team {
        max-width: initial; } }
    .st-1-teams .team .avt {
      display: block;
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 100%; }
      .st-1-teams .team .avt > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
    .st-1-teams .team .infor {
      position: relative;
      z-index: 1;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      transform: translateY(-50%);
      background-color: var(--cl-w);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 2rem 1.2rem; }
      .st-1-teams .team .infor .name {
        color: var(--cl-black);
        font-weight: 700;
        font-size: 2.16rem;
        line-height: 1.4; }
        @media (min-width: 768px) {
          .st-1-teams .team .infor .name {
            font-size: 2.21rem; } }
        @media (min-width: 1200px) {
          .st-1-teams .team .infor .name {
            font-size: 2.26rem; } }
        @media (min-width: 1336px) {
          .st-1-teams .team .infor .name {
            font-size: 2.4rem; } }
      .st-1-teams .team .infor-details {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        transition: .6s all;
        overflow: hidden; }
      .st-1-teams .team .infor .office {
        position: relative;
        transition: .6s all;
        padding: .8rem 1rem;
        min-width: 12rem;
        max-height: 40px;
        background-color: #293D9C;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; }
        .st-1-teams .team .infor .office > * {
          font-weight: 700;
          font-size: 1.44rem;
          color: var(--cl-w); }
          @media (min-width: 768px) {
            .st-1-teams .team .infor .office > * {
              font-size: 1.47rem; } }
          @media (min-width: 1200px) {
            .st-1-teams .team .infor .office > * {
              font-size: 1.5rem; } }
          @media (min-width: 1336px) {
            .st-1-teams .team .infor .office > * {
              font-size: 1.6rem; } }
      .st-1-teams .team .infor .socials {
        display: flex;
        justify-content: center;
        align-items: center;
        transform-origin: left;
        transition: .6s all;
        width: 0;
        clip-path: polygon(0 0, 0 53%, 0 100%, 0% 100%, 0 52%, 0% 0%); }
        .st-1-teams .team .infor .socials-item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 4rem;
          height: 4rem;
          background-color: #05ACEB;
          transition: .6s all; }
          .st-1-teams .team .infor .socials-item:hover {
            background-color: #1F6BB4;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    .st-1-teams .team:hover .infor-details .socials {
      width: 12rem;
      clip-path: polygon(100% 0%, 125% 50%, 100% 100%, -25% 100%, 0% 50%, -25% 0%); }

.careers-1 {
  background-color: #F6F6F6; }
  .careers-1__searches .row {
    justify-content: center; }
  .careers-1 .post--top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center; }
  .careers-1 .post__title > *, .careers-1 .post__deadline > * {
    line-height: 1.4;
    font-size: 1.8rem; }
    @media (min-width: 768px) {
      .careers-1 .post__title > *, .careers-1 .post__deadline > * {
        font-size: 1.84rem; } }
    @media (min-width: 1200px) {
      .careers-1 .post__title > *, .careers-1 .post__deadline > * {
        font-size: 1.88rem; } }
    @media (min-width: 1336px) {
      .careers-1 .post__title > *, .careers-1 .post__deadline > * {
        font-size: 2rem; } }
  .careers-1 .post__title {
    width: 100%; }
    @media (min-width: 768px) {
      .careers-1 .post__title {
        width: 75%; } }
    .careers-1 .post__title > * {
      color: var(--cl-main); }
  .careers-1 .post__deadline {
    width: 100%; }
    @media (min-width: 768px) {
      .careers-1 .post__deadline {
        width: 25%; } }
  .careers-1 .post-time {
    margin-top: .3rem;
    display: flex;
    align-items: center;
    font-size: 1.34rem; }
    @media (min-width: 768px) {
      .careers-1 .post-time {
        font-size: 1.35rem; } }
    @media (min-width: 1200px) {
      .careers-1 .post-time {
        font-size: 1.38rem; } }
    @media (min-width: 1336px) {
      .careers-1 .post-time {
        font-size: 1.4rem; } }
    .careers-1 .post-time time {
      color: #1F6BB4;
      margin-left: 1rem; }
  .careers-1 .post .hideContent {
    overflow: hidden;
    line-height: 1em;
    height: 6em; }
  .careers-1 .post .showContent {
    line-height: 1em;
    height: auto; }
  .careers-1 .post--bottom {
    margin-top: 2rem;
    transition: .4s all; }
    .careers-1 .post--bottom-title {
      font-weight: 700;
      color: var(--cl-black);
      font-size: 1.8rem; }
      @media (min-width: 768px) {
        .careers-1 .post--bottom-title {
          font-size: 1.84rem; } }
      @media (min-width: 1200px) {
        .careers-1 .post--bottom-title {
          font-size: 1.88rem; } }
      @media (min-width: 1336px) {
        .careers-1 .post--bottom-title {
          font-size: 2rem; } }
  .careers-1 .post .wrapper-content {
    margin-top: 1rem;
    font-size: 1.44rem; }
    @media (min-width: 768px) {
      .careers-1 .post .wrapper-content {
        font-size: 1.47rem; } }
    @media (min-width: 1200px) {
      .careers-1 .post .wrapper-content {
        font-size: 1.5rem; } }
    @media (min-width: 1336px) {
      .careers-1 .post .wrapper-content {
        font-size: 1.6rem; } }
    .careers-1 .post .wrapper-content p {
      font-size: 1.44rem; }
      @media (min-width: 768px) {
        .careers-1 .post .wrapper-content p {
          font-size: 1.47rem; } }
      @media (min-width: 1200px) {
        .careers-1 .post .wrapper-content p {
          font-size: 1.5rem; } }
      @media (min-width: 1336px) {
        .careers-1 .post .wrapper-content p {
          font-size: 1.6rem; } }
  .careers-1 .post .show-more {
    margin-top: 1rem; }
    .careers-1 .post .show-more > * {
      font-size: 1.34rem;
      color: var(--cl-main);
      text-decoration: underline; }
      @media (min-width: 768px) {
        .careers-1 .post .show-more > * {
          font-size: 1.35rem; } }
      @media (min-width: 1200px) {
        .careers-1 .post .show-more > * {
          font-size: 1.38rem; } }
      @media (min-width: 1336px) {
        .careers-1 .post .show-more > * {
          font-size: 1.4rem; } }
  .careers-1 .post:not(:nth-of-type(3n+1)) {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid var(--cl-gray); }
    @media (min-width: 768px) {
      .careers-1 .post:not(:nth-of-type(3n+1)) {
        margin-top: 4rem;
        padding-top: 4rem; } }

.e-info-wrapper__left {
  height: 100%;
  position: relative; }
  .e-info-wrapper__left > img {
    height: 100%;
    object-fit: cover;
    object-position: center;
    max-height: 450px;
    min-height: 280px; }

.e-info-wrapper .braney-title {
  line-height: 1.6;
  flex: 1; }

.e-info-wrapper__content {
  margin-top: 1rem;
  font-size: 1.44rem;
  color: var(--cl-gray);
  line-height: 1.4; }
  @media (min-width: 768px) {
    .e-info-wrapper__content {
      font-size: 1.47rem; } }
  @media (min-width: 1200px) {
    .e-info-wrapper__content {
      font-size: 1.5rem; } }
  @media (min-width: 1336px) {
    .e-info-wrapper__content {
      font-size: 1.6rem; } }

.e-info-wrapper .submit-now {
  margin-top: 3.4rem; }

.e-info-wrapper__right {
  height: 100%;
  display: flex;
  flex-direction: column; }

.e-info:nth-of-type(n + 2):nth-of-type(2n+ 1) .e-info-wrapper {
  flex-direction: row-reverse; }

.e-info:nth-of-type(n + 2):nth-of-type(2n) {
  background-color: #F6F6F6; }

.e-2 {
  position: relative; }
  .e-2__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .e-2-wrapper__content {
    padding: 3rem 4rem;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    @media (min-width: 768px) {
      .e-2-wrapper__content {
        padding: 4rem 5rem; } }
    .e-2-wrapper__content .title > * {
      color: var(--cl-black);
      font-size: 1.8rem;
      font-weight: 700; }
      @media (min-width: 768px) {
        .e-2-wrapper__content .title > * {
          font-size: 1.84rem; } }
      @media (min-width: 1200px) {
        .e-2-wrapper__content .title > * {
          font-size: 1.88rem; } }
      @media (min-width: 1336px) {
        .e-2-wrapper__content .title > * {
          font-size: 2rem; } }
    .e-2-wrapper__content .content {
      margin-top: 2rem; }
      .e-2-wrapper__content .content > * {
        font-size: 1.8rem;
        color: var(--cl-black); }
        @media (min-width: 768px) {
          .e-2-wrapper__content .content > * {
            font-size: 1.84rem; } }
        @media (min-width: 1200px) {
          .e-2-wrapper__content .content > * {
            font-size: 1.88rem; } }
        @media (min-width: 1336px) {
          .e-2-wrapper__content .content > * {
            font-size: 2rem; } }

.cd-1 {
  background-color: #F6F6F6; }
  .cd-1 .wrapper-content {
    color: var(--cl-black); }
    .cd-1 .wrapper-content p {
      color: var(--cl-black); }

.o-1 {
  background-color: #F6F6F6; }
  .o-1-map {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    padding-top: 80%;
    background-color: var(--cl-w); }
    .o-1-map iframe {
      position: absolute;
      top: 1rem;
      left: 1rem;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem); }
  .o-1__left .braney-desc {
    margin-top: 2rem;
    font-size: 1.44rem;
    color: var(--cl-gray); }
    @media (min-width: 768px) {
      .o-1__left .braney-desc {
        font-size: 1.47rem; } }
    @media (min-width: 1200px) {
      .o-1__left .braney-desc {
        font-size: 1.5rem; } }
    @media (min-width: 1336px) {
      .o-1__left .braney-desc {
        font-size: 1.6rem; } }
    .o-1__left .braney-desc p {
      font-size: 1.44rem;
      color: var(--cl-gray); }
      @media (min-width: 768px) {
        .o-1__left .braney-desc p {
          font-size: 1.47rem; } }
      @media (min-width: 1200px) {
        .o-1__left .braney-desc p {
          font-size: 1.5rem; } }
      @media (min-width: 1336px) {
        .o-1__left .braney-desc p {
          font-size: 1.6rem; } }
  @media (min-width: 992px) {
    .o-1__right > * {
      margin-left: 4.2rem; } }
  .o-1-images__item {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 80%; }
    .o-1-images__item img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .o-1-addr {
    padding: 3rem 4rem 3.3rem 4rem;
    position: relative;
    background-color: var(--cl-w);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    @media (min-width: 576px) {
      .o-1-addr {
        padding: 4rem 8rem 4.5rem 8rem; } }
    @media (min-width: 992px) {
      .o-1-addr {
        padding: 4rem 5rem 4.5rem 5rem; } }
    .o-1-addr::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #303090; }
    .o-1-addr .name {
      font-weight: 700;
      font-size: 2.16rem;
      color: var(--cl-black); }
      @media (min-width: 768px) {
        .o-1-addr .name {
          font-size: 2.21rem; } }
      @media (min-width: 1200px) {
        .o-1-addr .name {
          font-size: 2.26rem; } }
      @media (min-width: 1336px) {
        .o-1-addr .name {
          font-size: 2.4rem; } }
    .o-1-addr .desc {
      margin-top: .8rem;
      font-size: 1.44rem; }
      @media (min-width: 768px) {
        .o-1-addr .desc {
          font-size: 1.47rem; } }
      @media (min-width: 1200px) {
        .o-1-addr .desc {
          font-size: 1.5rem; } }
      @media (min-width: 1336px) {
        .o-1-addr .desc {
          font-size: 1.6rem; } }
    .o-1-addr__item:not(:first-child) {
      padding-top: 1rem;
      margin-top: 1.2rem;
      border-top: 2px solid #DBDBDB; }

.lib-1 {
  background-color: var(--cl-lv-5); }
  .lib-1-tabs {
    margin-top: 2.4rem;
    margin-bottom: 4.8rem;
    display: flex;
    justify-content: center; }
    @media (min-width: 768px) {
      .lib-1-tabs {
        margin-top: 2.52rem; } }
    @media (min-width: 992px) {
      .lib-1-tabs {
        margin-top: 2.7rem; } }
    @media (min-width: 1336px) {
      .lib-1-tabs {
        margin-top: 3rem; } }
    @media (min-width: 768px) {
      .lib-1-tabs {
        margin-bottom: 5.04rem; } }
    @media (min-width: 992px) {
      .lib-1-tabs {
        margin-bottom: 5.4rem; } }
    @media (min-width: 1336px) {
      .lib-1-tabs {
        margin-bottom: 6rem; } }
    .lib-1-tabs .tab-list {
      margin-left: auto;
      margin-right: auto;
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: var(--cl-w);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      flex-direction: column; }
      @media (min-width: 768px) {
        .lib-1-tabs .tab-list {
          flex-direction: row; } }
    .lib-1-tabs .tab-item {
      padding: 1.6rem 3rem;
      cursor: pointer; }
      @media (min-width: 992px) {
        .lib-1-tabs .tab-item {
          padding: 1.6rem 8rem; } }
      .lib-1-tabs .tab-item > * {
        font-weight: 700;
        font-size: 2.52rem; }
        @media (min-width: 768px) {
          .lib-1-tabs .tab-item > * {
            font-size: 2.58rem; } }
        @media (min-width: 1200px) {
          .lib-1-tabs .tab-item > * {
            font-size: 2.63rem; } }
        @media (min-width: 1336px) {
          .lib-1-tabs .tab-item > * {
            font-size: 2.8rem; } }
      @media (max-width: 991px) {
        .lib-1-tabs .tab-item:not(:first-child) {
          padding-top: 0; } }
      .lib-1-tabs .tab-item > * {
        display: inline-block;
        transition: .3s all;
        position: relative;
        line-height: 1.5;
        padding-bottom: 8px; }
        .lib-1-tabs .tab-item > *::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 2px;
          border-bottom: 1px dashed var(--cl-main);
          transition: .3s all;
          transform-origin: center; }
      .lib-1-tabs .tab-item.active > * {
        color: var(--cl-main);
        opacity: 1; }
        .lib-1-tabs .tab-item.active > *::after {
          width: 100%; }
      .lib-1-tabs .tab-item:hover > * {
        color: var(--cl-main);
        opacity: .9; }
  .lib-1-option {
    display: none; }
    .lib-1-option.active {
      display: block; }
    .lib-1-option > .row {
      justify-content: center; }
    .lib-1-option__item .image {
      cursor: zoom-in; }
    .lib-1-option__item .image, .lib-1-option__item .video {
      position: relative;
      width: 100%;
      height: 0;
      padding-top: 100%;
      display: block; }
      .lib-1-option__item .image img, .lib-1-option__item .video img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .lib-1-option__item .image::after, .lib-1-option__item .video::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        transform-origin: left center;
        z-index: 1;
        transition: .3s all; }
      .lib-1-option__item .image:hover::after, .lib-1-option__item .video:hover::after {
        width: 100%; }
    .lib-1-option__item .video::after {
      content: none; }
    .lib-1-option__item .video .playYtb {
      z-index: 11;
      width: 18%;
      padding-top: 18%;
      height: 0; }

.p-1 {
  background-color: #F6F6F6; }

.systems-1 {
  background-color: #F6F6F6; }

.box-system {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  background: linear-gradient(to right, var(--cl-lv-1) 80%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 20px 2px;
  margin-bottom: 2px; }
  .box-system__img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-top: 62.5%; }
    .box-system__img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .box-system__content {
    flex: 1;
    text-align: center;
    width: 100%;
    padding: 2rem 0; }
    .box-system__content > * {
      width: calc(100% - 8rem);
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.8rem;
      color: var(--cl-black);
      margin: 0 auto;
      transition: .3s all; }
      @media (min-width: 768px) {
        .box-system__content > * {
          font-size: 1.84rem; } }
      @media (min-width: 1200px) {
        .box-system__content > * {
          font-size: 1.88rem; } }
      @media (min-width: 1336px) {
        .box-system__content > * {
          font-size: 2rem; } }
  .box-system:hover .box-system__content > * {
    color: var(--cl-sub); }

.logistics-2 {
  position: relative;
  background-color: var(--cl-lv-10);
  overflow: hidden; }
  .logistics-2 > .container {
    position: relative;
    z-index: 1; }
  .logistics-2-bg {
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    object-fit: cover; }
    @media (min-width: 768px) {
      .logistics-2-bg {
        object-fit: contain; } }
  .logistics-2--bgright {
    z-index: 0;
    position: absolute;
    top: 20%;
    right: 0%;
    width: auto;
    height: auto;
    max-height: 80%;
    transform: translate(28%, 0%);
    object-fit: contain; }
    @media (min-width: 768px) {
      .logistics-2--bgright {
        object-fit: contain; } }
  .logistics-2__item {
    padding-bottom: -2px; }

.box-logistic {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 4rem 5.2rem;
  position: relative;
  background: linear-gradient(to right, var(--cl-lv-2) 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--cl-lv-2) 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, var(--cl-lv-2) 80%, rgba(255, 255, 255, 0) 0%), linear-gradient(var(--cl-lv-2) 80%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 0px 2px, 2px 0px;
  transition: 1.2s all; }
  .box-logistic::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    z-index: 1;
    transition: .3s all; }
  .box-logistic .iconArrow {
    position: relative;
    z-index: 2;
    transition: .3s all; }
  .box-logistic .iconBox {
    position: relative;
    z-index: 2;
    width: 9rem;
    height: 9rem;
    border-radius: 10px;
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2.2rem;
    margin-right: 2.2rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: .3s all; }
    .box-logistic .iconBox > p {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .box-logistic .iconBox svg {
      width: calc(100% - 3rem);
      height: calc(100% - 3rem); }
  .box-logistic .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2; }
    .box-logistic .content > * {
      transition: .3s all; }
    .box-logistic .content .title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 2.16rem;
      color: var(--cl-black); }
      @media (min-width: 768px) {
        .box-logistic .content .title {
          font-size: 2.21rem; } }
      @media (min-width: 1200px) {
        .box-logistic .content .title {
          font-size: 2.26rem; } }
      @media (min-width: 1336px) {
        .box-logistic .content .title {
          font-size: 2.4rem; } }
    .box-logistic .content .desc {
      margin-top: 1.1rem;
      color: var(--cl-text);
      font-size: 1.62rem; }
      @media (min-width: 768px) {
        .box-logistic .content .desc {
          font-size: 1.66rem; } }
      @media (min-width: 1200px) {
        .box-logistic .content .desc {
          font-size: 1.7rem; } }
      @media (min-width: 1336px) {
        .box-logistic .content .desc {
          font-size: 1.8rem; } }
  .box-logistic:hover, .box-logistic.active {
    background-size: 20px 2px, 2px 20px; }
    .box-logistic:hover::after, .box-logistic.active::after {
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      background-color: #05ACEB; }
    .box-logistic:hover .iconArrow, .box-logistic.active .iconArrow {
      transform: rotate(90deg);
      transform-origin: center; }
      .box-logistic:hover .iconArrow path, .box-logistic.active .iconArrow path {
        fill: var(--cl-w); }
    .box-logistic:hover .content .title, .box-logistic.active .content .title {
      color: var(--cl-w); }
    .box-logistic:hover .content .desc, .box-logistic.active .content .desc {
      color: #F6F6F6; }

.incoterms-tabs * {
  transition: .3s all; }

.incoterms-tabs .tab-item {
  cursor: pointer;
  background-color: #F0F0F0;
  padding: 2rem 1.8rem;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px; }
  .incoterms-tabs .tab-item > * {
    color: var(--cl-gray);
    font-size: 1.62rem;
    font-weight: 700; }
    @media (min-width: 768px) {
      .incoterms-tabs .tab-item > * {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .incoterms-tabs .tab-item > * {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .incoterms-tabs .tab-item > * {
        font-size: 1.8rem; } }
  .incoterms-tabs .tab-item:hover, .incoterms-tabs .tab-item.active {
    background-color: #D6F7FF; }
    .incoterms-tabs .tab-item:hover > *, .incoterms-tabs .tab-item.active > * {
      color: var(--cl-sub); }

.incoterms-opt {
  display: none; }
  .incoterms-opt.active {
    display: block; }

.incoterms > .container > .row {
  flex-direction: row-reverse; }

@media (min-width: 992px) {
  .incoterms--left {
    margin-right: 3rem; } }

@media (min-width: 1200px) {
  .incoterms--left {
    margin-right: 4rem; } }

.box-incoterm {
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .box-incoterm__img {
    width: 100%;
    height: 0;
    padding-top: 46.4%;
    position: relative; }
    .box-incoterm__img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .box-incoterm__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 2rem;
    background-color: var(--cl-w); }
    @media (min-width: 768px) {
      .box-incoterm__content {
        padding: 3rem 4rem; } }
    .box-incoterm__content > * {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: 100%; }
      @media (min-width: 992px) {
        .box-incoterm__content > * {
          width: 80%; } }
    .box-incoterm__content .title > * {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 2.16rem;
      color: var(--cl-black); }
      @media (min-width: 768px) {
        .box-incoterm__content .title > * {
          font-size: 2.21rem; } }
      @media (min-width: 1200px) {
        .box-incoterm__content .title > * {
          font-size: 2.26rem; } }
      @media (min-width: 1336px) {
        .box-incoterm__content .title > * {
          font-size: 2.4rem; } }
    .box-incoterm__content .desc {
      margin-top: 1rem; }
      .box-incoterm__content .desc > * {
        font-size: 1.62rem;
        color: var(--cl-text); }
        @media (min-width: 768px) {
          .box-incoterm__content .desc > * {
            font-size: 1.66rem; } }
        @media (min-width: 1200px) {
          .box-incoterm__content .desc > * {
            font-size: 1.7rem; } }
        @media (min-width: 1336px) {
          .box-incoterm__content .desc > * {
            font-size: 1.8rem; } }
    .box-incoterm__content .braney-more {
      margin-top: 1.8rem; }

.box-incoterm:not(:nth-of-type(3n + 1)) {
  margin-top: 3rem; }
  @media (min-width: 992px) {
    .box-incoterm:not(:nth-of-type(3n + 1)) {
      margin-top: 4rem; } }

.k-space > .container > .row {
  flex-direction: row-reverse; }

@media (min-width: 992px) {
  .k-space--left {
    margin-right: 3rem; } }

@media (min-width: 1200px) {
  .k-space--left {
    margin-right: 4rem; } }

.k-1 .box-policy__img {
  padding-top: 62.5%; }
  @media (min-width: 768px) {
    .k-1 .box-policy__img {
      padding-top: 46.4%; } }

.k-1 .box-policy__content {
  width: calc(100% - 6rem);
  transform: translate(3rem, -50%);
  padding-top: 3rem;
  padding-bottom: 3rem; }
  @media (min-width: 768px) {
    .k-1 .box-policy__content {
      width: calc(100% - 22rem);
      transform: translate(11rem, -50%); } }
  .k-1 .box-policy__content > * {
    font-size: 2.16rem; }
    @media (min-width: 768px) {
      .k-1 .box-policy__content > * {
        font-size: 2.21rem; } }
    @media (min-width: 1200px) {
      .k-1 .box-policy__content > * {
        font-size: 2.26rem; } }
    @media (min-width: 1336px) {
      .k-1 .box-policy__content > * {
        font-size: 2.4rem; } }

.k-1 .box-policy:not(:first-child) {
  margin-top: 4rem; }

.kc-1-wrapper__item:not(:first-child) {
  margin-top: 4.8rem; }
  @media (min-width: 768px) {
    .kc-1-wrapper__item:not(:first-child) {
      margin-top: 5.04rem; } }
  @media (min-width: 992px) {
    .kc-1-wrapper__item:not(:first-child) {
      margin-top: 5.4rem; } }
  @media (min-width: 1336px) {
    .kc-1-wrapper__item:not(:first-child) {
      margin-top: 6rem; } }

.ku-1-table__item:not(:first-child) {
  margin-top: .8rem; }

.k-currency-time {
  font-size: 1.62rem; }
  @media (min-width: 768px) {
    .k-currency-time {
      font-size: 1.66rem; } }
  @media (min-width: 1200px) {
    .k-currency-time {
      font-size: 1.7rem; } }
  @media (min-width: 1336px) {
    .k-currency-time {
      font-size: 1.8rem; } }

.k-currency-table table {
  width: 100%; }
  .k-currency-table table th {
    text-align: center;
    padding: 1.4rem 1rem;
    background-color: #303090;
    font-size: 1.44rem;
    font-weight: 700;
    color: var(--cl-w); }
    @media (min-width: 768px) {
      .k-currency-table table th {
        font-size: 1.47rem; } }
    @media (min-width: 1200px) {
      .k-currency-table table th {
        font-size: 1.5rem; } }
    @media (min-width: 1336px) {
      .k-currency-table table th {
        font-size: 1.6rem; } }

.block-dictionary-tabs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -3px;
  margin-bottom: 40px; }
  .block-dictionary-tabs .tab-item {
    width: 50px;
    margin: 0 3px 5px 3px;
    padding: .5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: var(--cl-w);
    background-color: var(--cl-main);
    border-radius: 10px;
    text-transform: uppercase;
    transition: .3s ease;
    font-weight: 700;
    font-size: 1.62rem; }
    @media (min-width: 768px) {
      .block-dictionary-tabs .tab-item {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .block-dictionary-tabs .tab-item {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .block-dictionary-tabs .tab-item {
        font-size: 1.8rem; } }
    .block-dictionary-tabs .tab-item:hover, .block-dictionary-tabs .tab-item.active {
      background-color: var(--cl-sub); }

.block-dictionary-content * {
  font-size: 1.62rem;
  color: var(--cl-text) !important; }
  @media (min-width: 768px) {
    .block-dictionary-content * {
      font-size: 1.66rem; } }
  @media (min-width: 1200px) {
    .block-dictionary-content * {
      font-size: 1.7rem; } }
  @media (min-width: 1336px) {
    .block-dictionary-content * {
      font-size: 1.8rem; } }

.block-dictionary-content .content-item .title > * {
  font-weight: 700; }

.block-dictionary-content .content-item:not(:first-child) {
  margin-top: 4rem; }

.block-dictionary-content .wrapper-content > :not(:first-child) {
  margin-top: .3rem; }

.k-dictionary .block-dictionary-opts {
  display: none; }
  .k-dictionary .block-dictionary-opts.active {
    display: block; }

.f-1-tabs * {
  transition: .3s all; }

.f-1-tabs .tab-item {
  cursor: pointer;
  background-color: #F0F0F0;
  padding: 2rem 1.8rem;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px; }
  .f-1-tabs .tab-item > * {
    color: var(--cl-gray);
    font-size: 1.62rem;
    font-weight: 700; }
    @media (min-width: 768px) {
      .f-1-tabs .tab-item > * {
        font-size: 1.66rem; } }
    @media (min-width: 1200px) {
      .f-1-tabs .tab-item > * {
        font-size: 1.7rem; } }
    @media (min-width: 1336px) {
      .f-1-tabs .tab-item > * {
        font-size: 1.8rem; } }
  .f-1-tabs .tab-item:hover, .f-1-tabs .tab-item.active {
    background-color: #D6F7FF; }
    .f-1-tabs .tab-item:hover > *, .f-1-tabs .tab-item.active > * {
      color: var(--cl-sub); }

.f-1-opt {
  display: none; }
  .f-1-opt.active {
    display: block; }

.f-1 > .container > .row {
  flex-direction: row-reverse; }

@media (min-width: 992px) {
  .f-1--left {
    margin-right: 3rem; } }

@media (min-width: 1200px) {
  .f-1--left {
    margin-right: 4rem; } }

.box-download {
  display: flex;
  align-items: stretch;
  flex-direction: column; }
  @media (min-width: 576px) {
    .box-download {
      flex-direction: row; } }
  .box-download--left {
    width: 100%; }
    @media (min-width: 576px) {
      .box-download--left {
        width: 20rem; } }
  .box-download .img {
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 62.5%;
    position: relative; }
    .box-download .img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .box-download--right {
    flex: 1;
    display: flex;
    flex-direction: column; }
    @media (min-width: 576px) {
      .box-download--right {
        margin-left: 2rem; } }
    .box-download--right .title {
      flex: 1; }
  .box-download .title {
    margin-bottom: 2rem; }
    .box-download .title > * {
      font-weight: 700;
      font-size: 2.16rem;
      color: #707070;
      text-transform: uppercase;
      line-height: 1.6; }
      @media (min-width: 768px) {
        .box-download .title > * {
          font-size: 2.21rem; } }
      @media (min-width: 1200px) {
        .box-download .title > * {
          font-size: 2.26rem; } }
      @media (min-width: 1336px) {
        .box-download .title > * {
          font-size: 2.4rem; } }
  .box-download .braney-more {
    margin-bottom: .8rem; }
  .box-download + .box-download {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #707070; }

.searches-1 {
  background-color: #F6F6F6; }
  .searches-1__content > * {
    font-size: 2.16rem;
    color: #707070; }
    @media (min-width: 768px) {
      .searches-1__content > * {
        font-size: 2.21rem; } }
    @media (min-width: 1200px) {
      .searches-1__content > * {
        font-size: 2.26rem; } }
    @media (min-width: 1336px) {
      .searches-1__content > * {
        font-size: 2.4rem; } }

.results-item a {
  display: block; }

.results-item .link {
  font-size: 2.52rem;
  color: var(--cl-main);
  font-weight: 700;
  margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .results-item .link {
      font-size: 2.58rem; } }
  @media (min-width: 1200px) {
    .results-item .link {
      font-size: 2.63rem; } }
  @media (min-width: 1336px) {
    .results-item .link {
      font-size: 2.8rem; } }

.results-item:hover .link {
  color: var(--cl-sub); }

.results-item * {
  transition: .2s all;
  font-size: 1.98rem;
  color: #171717; }
  @media (min-width: 768px) {
    .results-item * {
      font-size: 2.02rem; } }
  @media (min-width: 1200px) {
    .results-item * {
      font-size: 2.21rem; } }
  @media (min-width: 1336px) {
    .results-item * {
      font-size: 2.2rem; } }

.si-1 .button-choose {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--cl-black);
  background-color: var(--cl-w);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 1.6rem 2.6rem;
  font-weight: 700;
  font-size: 1.8rem;
  cursor: pointer;
  transition: .3s all linear;
  user-select: none;
  white-space: wrap;
  text-align: center;
  line-height: 1.5; }
  @media (min-width: 768px) {
    .si-1 .button-choose {
      font-size: 1.84rem; } }
  @media (min-width: 1200px) {
    .si-1 .button-choose {
      font-size: 1.88rem; } }
  @media (min-width: 1336px) {
    .si-1 .button-choose {
      font-size: 2rem; } }
  .si-1 .button-choose.active, .si-1 .button-choose:hover, .si-1 .button-choose:focus {
    background-color: var(--cl-sub);
    color: var(--cl-w); }

.si-1__section {
  display: none; }
  .si-1__section.active {
    display: block; }
  .si-1__section-form .block-form:not(:first-child) {
    margin-top: 40px; }
  .si-1__section .braney-subtitle {
    margin-bottom: 2rem; }
    .si-1__section .braney-subtitle > * {
      font-size: 2.62rem; }
      @media (min-width: 768px) {
        .si-1__section .braney-subtitle > * {
          font-size: 2.75rem; } }
      @media (min-width: 1200px) {
        .si-1__section .braney-subtitle > * {
          font-size: 2.88rem; } }
      @media (min-width: 1336px) {
        .si-1__section .braney-subtitle > * {
          font-size: 3.2rem; } }

.submit-si {
  background-color: #F6F6F6; }
  .submit-si .braney-subtitle > * {
    font-size: 2.62rem; }
    @media (min-width: 768px) {
      .submit-si .braney-subtitle > * {
        font-size: 2.75rem; } }
    @media (min-width: 1200px) {
      .submit-si .braney-subtitle > * {
        font-size: 2.88rem; } }
    @media (min-width: 1336px) {
      .submit-si .braney-subtitle > * {
        font-size: 3.2rem; } }
  .submit-si-form [data-typeofshipping] {
    display: none; }
    .submit-si-form [data-typeofshipping].active {
      display: block; }
  .submit-si-form [data-typeofshipping="2"].active {
    display: flex; }

.qt-1 {
  margin-bottom: 40px; }
  .qt-1-wrapper .braney-subtitle > * {
    font-size: 2.62rem;
    font-weight: 400; }
    @media (min-width: 768px) {
      .qt-1-wrapper .braney-subtitle > * {
        font-size: 2.75rem; } }
    @media (min-width: 1200px) {
      .qt-1-wrapper .braney-subtitle > * {
        font-size: 2.88rem; } }
    @media (min-width: 1336px) {
      .qt-1-wrapper .braney-subtitle > * {
        font-size: 3.2rem; } }
  .qt-1-wrapper .braney-subtitle > :not(:first-child) {
    margin-left: 20px; }
  .qt-1 .ft-17, .qt-1 .qt-1-results .navibar-info dl dt, .qt-1-results .navibar-info dl .qt-1 dt, .qt-1 .qt-1-results .navibar-info dl dd, .qt-1-results .navibar-info dl .qt-1 dd, .qt-1 .qt-1-results .results-top > *, .qt-1-results .qt-1 .results-top > * {
    font-size: 15px; }
    @media (min-width: 768px) {
      .qt-1 .ft-17, .qt-1 .qt-1-results .navibar-info dl dt, .qt-1-results .navibar-info dl .qt-1 dt, .qt-1 .qt-1-results .navibar-info dl dd, .qt-1-results .navibar-info dl .qt-1 dd, .qt-1 .qt-1-results .results-top > *, .qt-1-results .qt-1 .results-top > * {
        font-size: 17px; } }
  .qt-1 .ft-16, .qt-1 .qt-1-results .results-ls .item-name, .qt-1-results .results-ls .qt-1 .item-name, .qt-1 .qt-1-results .results-ls .item .shipment-details__section dl dt, .qt-1-results .results-ls .item .shipment-details__section dl .qt-1 dt, .qt-1 .qt-1-results .results-ls .item .shipment-details__bottom ul li dl dt, .qt-1-results .results-ls .item .shipment-details__bottom ul li dl .qt-1 dt, .qt-1 .qt-1-results .results-ls .item .vessel-flight ul > li .name > *, .qt-1-results .results-ls .item .vessel-flight ul > li .qt-1 .name > *, .qt-1 .qt-1-results .results-ls .item .vessel-flight ul > li .value, .qt-1-results .results-ls .item .vessel-flight ul > li .qt-1 .value, .qt-1 .qt-1-results .results-ls .item .status-table thead th, .qt-1-results .results-ls .item .status-table thead .qt-1 th, .qt-1 .qt-1-results .results-ls .item .contact-table ul > li .key, .qt-1-results .results-ls .item .contact-table ul > li .qt-1 .key, .qt-1 .qt-form .group-input input[type="submit"], .qt-form .group-input .qt-1 input[type="submit"] {
    font-size: 14px; }
    @media (min-width: 768px) {
      .qt-1 .ft-16, .qt-1 .qt-1-results .results-ls .item-name, .qt-1-results .results-ls .qt-1 .item-name, .qt-1 .qt-1-results .results-ls .item .shipment-details__section dl dt, .qt-1-results .results-ls .item .shipment-details__section dl .qt-1 dt, .qt-1 .qt-1-results .results-ls .item .shipment-details__bottom ul li dl dt, .qt-1-results .results-ls .item .shipment-details__bottom ul li dl .qt-1 dt, .qt-1 .qt-1-results .results-ls .item .vessel-flight ul > li .name > *, .qt-1-results .results-ls .item .vessel-flight ul > li .qt-1 .name > *, .qt-1 .qt-1-results .results-ls .item .vessel-flight ul > li .value, .qt-1-results .results-ls .item .vessel-flight ul > li .qt-1 .value, .qt-1 .qt-1-results .results-ls .item .status-table thead th, .qt-1-results .results-ls .item .status-table thead .qt-1 th, .qt-1 .qt-1-results .results-ls .item .contact-table ul > li .key, .qt-1-results .results-ls .item .contact-table ul > li .qt-1 .key, .qt-1 .qt-form .group-input input[type="submit"], .qt-form .group-input .qt-1 input[type="submit"] {
        font-size: 16px; } }
  .qt-1 .ft-15, .qt-1 .qt-1-results .results-ls .item .shipment-details__section dl dd, .qt-1-results .results-ls .item .shipment-details__section dl .qt-1 dd, .qt-1 .qt-1-results .results-ls .item .shipment-details__bottom ul li dl dd, .qt-1-results .results-ls .item .shipment-details__bottom ul li dl .qt-1 dd, .qt-1 .qt-1-results .results-ls .item .status-table tbody td, .qt-1-results .results-ls .item .status-table tbody .qt-1 td, .qt-1 .qt-1-results .results-ls .item .contact-table ul > li .val, .qt-1-results .results-ls .item .contact-table ul > li .qt-1 .val, .qt-1 .qt-1-results .results-ls .item .content, .qt-1-results .results-ls .item .qt-1 .content {
    font-size: 13.5px; }
    @media (min-width: 768px) {
      .qt-1 .ft-15, .qt-1 .qt-1-results .results-ls .item .shipment-details__section dl dd, .qt-1-results .results-ls .item .shipment-details__section dl .qt-1 dd, .qt-1 .qt-1-results .results-ls .item .shipment-details__bottom ul li dl dd, .qt-1-results .results-ls .item .shipment-details__bottom ul li dl .qt-1 dd, .qt-1 .qt-1-results .results-ls .item .status-table tbody td, .qt-1-results .results-ls .item .status-table tbody .qt-1 td, .qt-1 .qt-1-results .results-ls .item .contact-table ul > li .val, .qt-1-results .results-ls .item .contact-table ul > li .qt-1 .val, .qt-1 .qt-1-results .results-ls .item .content, .qt-1-results .results-ls .item .qt-1 .content {
        font-size: 15px; } }
  .qt-1-results .navibar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background-color: #F0F3F5;
    gap: 20px; }
    @supports not (gap: 20px) {
      .qt-1-results .navibar .input[type="submit"], .qt-1-results .navibar button[type="submit"] {
        margin-left: 20px; } }
    .qt-1-results .navibar-info {
      width: calc(100% - 123px); }
      .qt-1-results .navibar-info dl {
        width: 100%;
        display: flex;
        text-align: left;
        gap: 20px; }
        @supports not (gap: 20px) {
          .qt-1-results .navibar-info dl dd {
            margin-left: 20px; } }
        .qt-1-results .navibar-info dl dt {
          font-weight: 400;
          line-height: 127%;
          color: #171717;
          white-space: nowrap; }
        .qt-1-results .navibar-info dl dd {
          font-weight: 700;
          line-height: 127%;
          color: #171717;
          flex: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap; }
    .qt-1-results .navibar-back {
      cursor: pointer;
      display: block;
      width: 53px;
      padding-left: 30px; }
      .qt-1-results .navibar-back:hover svg {
        transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: translateX(-10px); }
    .qt-1-results .navibar-print {
      width: 43px;
      padding-right: 23px;
      display: block; }
      .qt-1-results .navibar-print:hover {
        transition: 0.3s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
        opacity: .8; }
  .qt-1-results .results {
    --hName: 48px;
    --topRs: calc(var(--hName) + 30px); }
    @media (min-width: 768px) {
      .qt-1-results .results {
        --hName: 60px; } }
    .qt-1-results .results-top {
      margin-top: 30px;
      padding: 0 23px 0 30px; }
      .qt-1-results .results-top > * {
        font-weight: 700;
        line-height: 127%;
        color: #171717; }
    .qt-1-results .results-ls .item {
      position: relative; }
      .qt-1-results .results-ls .item-name {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: 8px;
        font-weight: 700;
        background-color: #05ACEB;
        color: #FFF;
        height: var(--hName, 60px);
        cursor: pointer; }
        .qt-1-results .results-ls .item-name > * {
          padding: 12px 23px 12px 30px; }
          @media (min-width: 768px) {
            .qt-1-results .results-ls .item-name > * {
              padding: 20px 23px 20px 30px; } }
        .qt-1-results .results-ls .item-name .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          transition: .3s all ease-in-out;
          height: 100%; }
      .qt-1-results .results-ls .item.is-showed .item-name .icon {
        transform: rotate(-180deg); }
      .qt-1-results .results-ls .item.is-showed .item-wrapper {
        height: auto;
        padding-top: var(--topRs, 96px) !important;
        padding-bottom: 30px !important; }
      .qt-1-results .results-ls .item-wrapper {
        padding: var(--topRs, 96px) 30px 29px 31px;
        background-color: #F6F6F6;
        border-radius: 8px;
        height: 0;
        overflow: hidden;
        transition: .3s all ease-in-out;
        padding-top: var(--hName, 60px) !important;
        padding-bottom: 0 !important; }
      .qt-1-results .results-ls .item .shipment-details {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; }
        @media (min-width: 768px) {
          .qt-1-results .results-ls .item .shipment-details {
            gap: 40px; } }
        @supports not (gap: 40px) {
          .qt-1-results .results-ls .item .shipment-details .shipment-details__section:not(:first-child) {
            margin-left: 40px; } }
        .qt-1-results .results-ls .item .shipment-details__section {
          flex: 0 0 100%;
          width: 100%; }
          @media (min-width: 768px) {
            .qt-1-results .results-ls .item .shipment-details__section {
              flex: 0 0 40%;
              width: 40%; } }
          @media (min-width: 992px) {
            .qt-1-results .results-ls .item .shipment-details__section {
              flex: 0 0 32%;
              width: 32%; } }
          @media (max-width: 767px) {
            .qt-1-results .results-ls .item .shipment-details__section:not(:first-child) {
              margin-top: 20px;
              padding-top: 20px;
              border-top: 1px solid #171717; } }
          .qt-1-results .results-ls .item .shipment-details__section .icon {
            margin-bottom: 20px; }
          .qt-1-results .results-ls .item .shipment-details__section dl dt {
            font-weight: 400;
            color: #171717;
            line-height: 135%; }
            .qt-1-results .results-ls .item .shipment-details__section dl dt:not(:nth-of-type(1)) {
              margin-top: 20px; }
              @media (min-width: 768px) {
                .qt-1-results .results-ls .item .shipment-details__section dl dt:not(:nth-of-type(1)) {
                  margin-top: 40px; } }
          .qt-1-results .results-ls .item .shipment-details__section dl dd {
            margin-top: 10px;
            font-weight: 700;
            color: #171717;
            line-height: 135%; }
        .qt-1-results .results-ls .item .shipment-details__bottom {
          flex: 0 0 100%;
          width: 100%;
          margin-top: 20px;
          padding-top: 20px;
          border-top: 1px solid #000; }
          .qt-1-results .results-ls .item .shipment-details__bottom ul {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-between;
            list-style-type: none;
            gap: 40px; }
            @media (min-width: 992px) {
              .qt-1-results .results-ls .item .shipment-details__bottom ul {
                gap: 40px; } }
            .qt-1-results .results-ls .item .shipment-details__bottom ul li {
              display: flex;
              align-items: center; }
              .qt-1-results .results-ls .item .shipment-details__bottom ul li .icon {
                margin-right: 25px; }
              .qt-1-results .results-ls .item .shipment-details__bottom ul li dl dt {
                font-weight: 400;
                line-height: 22px; }
              .qt-1-results .results-ls .item .shipment-details__bottom ul li dl dd {
                margin-top: 3px;
                font-weight: 700;
                line-height: 22px; }
              @media (max-width: 767px) {
                .qt-1-results .results-ls .item .shipment-details__bottom ul li {
                  width: calc(50% - 20px);
                  flex: 0 0 calc(50% - 20px); } }
              @media (min-width: 768px) {
                .qt-1-results .results-ls .item .shipment-details__bottom ul li:nth-of-type(2n + 1) {
                  flex: 0 0 calc(60% - 40px);
                  width: calc(60% - 40px); }
                .qt-1-results .results-ls .item .shipment-details__bottom ul li:nth-of-type(2n) {
                  width: 40%;
                  flex: 0 0 40%; } }
              @media (min-width: 992px) {
                .qt-1-results .results-ls .item .shipment-details__bottom ul li:nth-of-type(2n + 1) {
                  flex: 0 0 auto;
                  width: auto; }
                .qt-1-results .results-ls .item .shipment-details__bottom ul li:nth-of-type(2n) {
                  flex: 0 0 auto;
                  width: auto; }
                .qt-1-results .results-ls .item .shipment-details__bottom ul li:nth-of-type(4n) {
                  width: 32%;
                  flex: 0 0 32%; } }
      .qt-1-results .results-ls .item + .item {
        margin-top: 20px; }
      .qt-1-results .results-ls .item .shipment-line {
        display: none; }
        @media (min-width: 768px) {
          .qt-1-results .results-ls .item .shipment-line {
            display: block;
            top: calc(var(--topRs, 96px) + (39px / 2));
            left: calc(30% - 8.7vw);
            width: calc(36% - 48px);
            height: 24px;
            transform: translate(0%, -50%);
            position: absolute; }
            .qt-1-results .results-ls .item .shipment-line::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 0%;
              width: 100%;
              height: 0;
              border-bottom: 1px solid #171717;
              transform: translate(0%, -50%); }
            .qt-1-results .results-ls .item .shipment-line .start, .qt-1-results .results-ls .item .shipment-line .end {
              z-index: 1;
              width: 24px;
              height: 24px;
              border-radius: 100%;
              position: absolute;
              top: 50%;
              transform: translate(-50%, -50%); }
              .qt-1-results .results-ls .item .shipment-line .start::after, .qt-1-results .results-ls .item .shipment-line .end::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 8px;
                height: 8px;
                transform: translate(-50%, -50%);
                border-radius: inherit; }
            .qt-1-results .results-ls .item .shipment-line .start {
              left: 0;
              border: 2px solid #000000;
              background: transparent;
              transform: translate(-100%, -50%); }
              .qt-1-results .results-ls .item .shipment-line .start::after {
                background-color: #303090; }
            .qt-1-results .results-ls .item .shipment-line .end {
              right: 0;
              background: #303090;
              transform: translate(100%, -50%); }
              .qt-1-results .results-ls .item .shipment-line .end::after {
                background-color: #FFF; } }
        @media (min-width: 992px) {
          .qt-1-results .results-ls .item .shipment-line {
            left: calc(40% - 8.7vw); } }
      .qt-1-results .results-ls .item .vessel-flight {
        overflow: hidden; }
        .qt-1-results .results-ls .item .vessel-flight ul {
          display: flex;
          list-style-type: none;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 40px; }
          .qt-1-results .results-ls .item .vessel-flight ul > li {
            width: 100%; }
            @media (min-width: 768px) {
              .qt-1-results .results-ls .item .vessel-flight ul > li {
                width: auto; } }
            .qt-1-results .results-ls .item .vessel-flight ul > li .name {
              display: flex;
              align-items: center;
              white-space: nowrap; }
              .qt-1-results .results-ls .item .vessel-flight ul > li .name > * {
                font-weight: 700;
                color: #171717;
                line-height: 135%; }
              .qt-1-results .results-ls .item .vessel-flight ul > li .name .icon {
                margin-right: 20px; }
            .qt-1-results .results-ls .item .vessel-flight ul > li .value {
              margin-top: 10px;
              padding-top: 10px;
              line-height: 135%;
              position: relative; }
              .qt-1-results .results-ls .item .vessel-flight ul > li .value::after {
                content: "";
                position: absolute;
                top: 0px;
                left: -40px;
                width: 100vw;
                height: 0;
                border-top: 1px solid #E1E1E1; }
      .qt-1-results .results-ls .item .status-table {
        padding: var(--topRs, 96px) 0px 30px 0px; }
        .qt-1-results .results-ls .item .status-table table {
          width: 100%; }
        .qt-1-results .results-ls .item .status-table table td {
          border-top: 1px solid #E1E1E1; }
        .qt-1-results .results-ls .item .status-table thead th {
          font-weight: 700;
          color: #171717;
          padding: 10px 30px 10px 30px; }
          .qt-1-results .results-ls .item .status-table thead th span {
            display: inline-block;
            white-space: nowrap; }
        .qt-1-results .results-ls .item .status-table tbody tr, .qt-1-results .results-ls .item .status-table tbody td {
          transition: .3s all ease-in-out; }
        .qt-1-results .results-ls .item .status-table tbody td {
          color: #171717;
          padding: 10px 30px; }
          .qt-1-results .results-ls .item .status-table tbody td.td-bold {
            font-weight: 700;
            color: #171717; }
        @media (max-width: 991px) {
          .qt-1-results .results-ls .item .status-table thead, .qt-1-results .results-ls .item .status-table thead tr, .qt-1-results .results-ls .item .status-table thead td {
            display: none; }
          .qt-1-results .results-ls .item .status-table tbody, .qt-1-results .results-ls .item .status-table tbody tr, .qt-1-results .results-ls .item .status-table tbody td {
            display: block;
            text-align: left !important; }
          .qt-1-results .results-ls .item .status-table tbody td {
            position: relative;
            display: flex;
            width: 100%;
            border-top: 1px solid #E1E1E1;
            padding: 8px;
            padding-left: 32px;
            padding-right: 40px; }
            .qt-1-results .results-ls .item .status-table tbody td::before {
              content: attr(data-title) "";
              display: inline-block;
              width: 50%;
              flex: 0 0 50%;
              white-space: pre-line;
              font-size: inherit;
              padding-right: 3px; }
            .qt-1-results .results-ls .item .status-table tbody td[data-title=""]::before {
              content: none; }
            .qt-1-results .results-ls .item .status-table tbody td[data-empty="true"] {
              display: none; }
          .qt-1-results .results-ls .item .status-table tbody tr {
            border-top: 1px solid #a8a8a8; } }
        @media (max-width: 767px) {
          .qt-1-results .results-ls .item .status-table tbody td {
            padding: 8px;
            padding-left: 20px;
            padding-right: 20px; }
            .qt-1-results .results-ls .item .status-table tbody td::before {
              flex: 0 0 38%;
              width: 38%; } }
      .qt-1-results .results-ls .item .contact-table {
        padding: var(--topRs, 96px) 0px 30px 0px; }
        .qt-1-results .results-ls .item .contact-table ul {
          display: flex;
          width: 100%;
          list-style-type: none;
          flex-wrap: wrap; }
          .qt-1-results .results-ls .item .contact-table ul > li {
            flex: 0 0 100%;
            width: 100%; }
            @media (max-width: 767px) {
              .qt-1-results .results-ls .item .contact-table ul > li:nth-of-type(n + 2) {
                border-top: 1px solid #E1E1E1; } }
            @media (min-width: 768px) {
              .qt-1-results .results-ls .item .contact-table ul > li {
                flex: 0 0 50%;
                width: 50%; }
                .qt-1-results .results-ls .item .contact-table ul > li:nth-of-type(n + 3) {
                  border-top: 1px solid #E1E1E1; } }
            .qt-1-results .results-ls .item .contact-table ul > li p {
              padding: 10px 30px; }
            .qt-1-results .results-ls .item .contact-table ul > li .key {
              font-weight: 700;
              color: #171717; }
            .qt-1-results .results-ls .item .contact-table ul > li .val {
              border-top: 1px solid #E1E1E1;
              font-weight: 400;
              color: #171717; }
      .qt-1-results .results-ls .item .content {
        line-height: 135%;
        color: #171717; }

.qt-form {
  padding: 20px 0;
  width: 100%;
  display: block;
  overflow: visible; }
  .qt-form .group-input {
    width: 100%; }
    .qt-form .group-input .entry-input {
      display: flex;
      flex-direction: column; }
      .qt-form .group-input .entry-input > *:not(:first-child) {
        margin-top: 10px; }
        @media (min-width: 768px) {
          .qt-form .group-input .entry-input > *:not(:first-child) {
            margin-top: 0; } }
      @media (min-width: 768px) {
        .qt-form .group-input .entry-input {
          flex-direction: row;
          gap: 10px; } }
    .qt-form .group-input input {
      padding: 21px 20px;
      appearance: none; }
    .qt-form .group-input input:not([type="submit"]) {
      background: #FAFAFA;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 0px;
      flex: 1;
      border: none;
      outline: none; }
      .qt-form .group-input input:not([type="submit"]):hover, .qt-form .group-input input:not([type="submit"]):focus {
        transition: .2s all ease-in-out; }
    .qt-form .group-input input[type="submit"] {
      width: auto;
      min-width: 302px;
      background: #05ACEB;
      box-shadow: none;
      border-radius: 0;
      color: white;
      font-weight: 700;
      text-transform: capitalize; }
      .qt-form .group-input input[type="submit"]:hover, .qt-form .group-input input[type="submit"]:focus {
        transition: .2s all ease-in-out;
        opacity: .8;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
