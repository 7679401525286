
////////////////////////////////////////////////
///////////////// BLOCK ////////////////////////

@mixin blockBs($key, $name, $side: -1){
  @each $idx, $val in map-get($blockBs, #{$key}){
    @if $side == -1{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (min-width: map-get($mdWidth, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (min-width: map-get($mdWidth, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}

///////////////// BLOCK ////////////////////////
////////////////////////////////////////////////


////////////////////////////////////////////////
///////////////// SPACE ////////////////////////

@mixin spaceBs($key, $name, $side: '-1'){
  @each $idx, $val in map-get($spaceBs, #{$key}){
    @if $side == '-1'{
      @if $idx == default{
        #{$name}: $val;
      }
      @else{
        @media (min-width: map-get($mdWidth, $idx)){
          #{$name}: $val;
        }
      }
    }
    @else{
      @if $idx == default{
        #{$name}: $val $side;
      }
      @else{
        @media (min-width: map-get($mdWidth, $idx)){
          #{$name}: $val $side;
        }
      }
    }
  }
}

///////////////// SPACE ////////////////////////
////////////////////////////////////////////////

////////////////////////////////////////////////
///////////////// SECTION ////////////////////////

// @mixin sectionBs($key, $name, $side: '-1'){
//   @each $idx, $val in map-get($sectionBs, #{$key}){
//     @if $side == '-1'{
//       @if $idx == default{
//         #{$name}: $val;
//       }
//       @else{
//         @media (min-width: map-get($mdWidth, $idx)){
//           #{$name}: $val;
//         }
//       }
//     }
//     @else{
//       @if $idx == default{
//         #{$name}: $val $side;
//       }
//       @else{
//         @media (min-width: map-get($mdWidth, $idx)){
//           #{$name}: $val $side;
//         }
//       }
//     }
//   }
// }

///////////////// SECTION ////////////////////////
////////////////////////////////////////////////



////////////////////////////////////////////////
//////////// FONT SIZE /////////////////////////

@mixin sizeBs($key){
  @each $idx, $val in map-get($sizeBs, #{$key}){
    @if $idx == default{
      font-size: $val;
    }
    @else{
      @media (min-width: map-get($mdWidth, $idx)){
        font-size: $val;
      }
    }
  }
}

// @mixin titleBs($key){
//   @each $idx, $val in map-get($titleBs, #{$key}){
//     @if $idx == default{
//       font-size: $val;
//     }
//     @else{
//       @media (min-width: map-get($mdWidth, $idx)){
//         font-size: $val;
//       }
//     }
//   }
// }


//////////// FONT SIZE ////////////////////////
////////////////////////////////////////////////