.nav-bar{
    padding: 2rem;
    background-color: #F5F5F5;

    &-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        padding: 1rem 2rem;

        min-height: 6rem;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);

        transition: .3s all;

        &>*{
            transition: .3s all;
            color: #171717;
            @include sizeBs(s16);
        }

        &>p{
            margin-left: 1rem;
            margin-right: 1.6rem;
            line-height: 1.4;
            width: 100%;
            text-transform: uppercase;
        }

        svg{
            height: 42px;
            *{
                transition: .3s all;
            }
            *[fill]:not([fill="none"]){
                fill: #05aceb;
            }
            
            *[stroke]:not([stroke="none"]){
                stroke: #05aceb;
            }
        }

        &.active, &:hover{
            background-color: #05aceb;
            box-shadow: none;

            .nav-bar-item__arrow{
                position: relative;
                transform-origin: center;
                transform: rotate(90deg);
            }

            &>*{
                color: #fff;
            }
            svg{
                *[fill]:not([fill="none"]){
                    fill: #fff;
                }
                *[stroke]:not([stroke="none"]){
                    stroke: #fff;
                }
            }
        }

        &:hover{
            background-color: #05aceb;
            box-shadow: none;
            &>*{
                color: #fff;
            }
            svg{
                *[fill]:not([fill="none"]){
                    fill: #fff;
                }
                *[stroke]:not([stroke="none"]){
                    stroke: #fff;
                }
            }
        }
    }

    &-item + &-item{
        margin-top: 1.6rem;
    }
}