.about-4{
    
    position: relative;
    &-bg{
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;

        @include rpmin(lg){
            object-fit: contain;
        }
    }

    &-swiper{
        position: relative;
        .swiper{
            padding: 6px 3px;
        }

        .swiper-custom-button-prev,
        .swiper-custom-button-next{
            top: 13.5rem;
        }
    }

    &__item{
        display: flex;
        flex-direction: column;
        position: relative;
        cursor: default;

        .desc{
            cursor: inherit;
            display: block;
            padding: 5rem 6rem 7rem 6rem;
            @include rpmin(sm){
                padding: 5rem 6rem 7rem 6rem;
            }

            background-color: var(--cl-w);
            box-shadow: 0 3px 6px rgba(0,0,0,.16);
            border-radius: 10px;

            .overflow-y{
                height: 15rem;
            }

            *{
                color: #707070;
                @include medium;
                @include sizeBs(s18);
                line-height: 1.5;
                transition: .3s all;
            }
        }

        .avt{
            position: relative;
            margin-top: -5rem;
            width: 10rem;
            height: 10rem;
            border-radius: 50%;
            &>*{
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
                object-position: center;
            }
        }

        .name{
            margin-top: 1rem;
            &>*{
                color: #00396D;
                @include bold;
                @include sizeBs(s18);
                line-height: 1.5;
            }
        }

        .major{
            margin-top: .4rem;
            &>*{
                color: #4B4B4B;
                @include medium;
                @include sizeBs(s16);
                line-height: 1.3;
            }
        }

        .name, .major, .avt{
            margin-left: 6rem;
        }

        &:hover{
            .desc{
                animation-name: bounce;
                animation-duration: 1s;
                transform-origin: center bottom;

                &>*{
                    position: relative;
                    z-index: 11;

                    color: #000;
                }
            }
        }
    }
}