.systems-1{
    background-color: #F6F6F6;
}

.box-system{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &__img{
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        padding-top: 62.5%;

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__content{
        flex: 1;
        text-align: center;
        width: 100%;
        padding: 2rem 0;
        &>*{
            width: calc(100% - 8rem);
            @include uppercase;
            @include bold;
            @include sizeBs(s20);
            color: var(--cl-black);

            margin: 0 auto;

            transition: .3s all;
        }
    }

    position: relative;
    background:
        linear-gradient(to right, var(--cl-lv-1) 80%, rgba(255, 255, 255, 0) 0%);

    background-position: bottom;
    background-repeat: repeat-x;
    background-size: 20px 2px;
    margin-bottom: 2px;

    &:hover{
        .box-system{
            &__content{
                &>*{
                    color: var(--cl-sub);
                }
            }
        }
    }
}