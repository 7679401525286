*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

:root{
	--cl-main: #303090;
	--cl-sub: #05ACEB;
	
	--cl-text: #171717BF;

	--cl-w: #FFF;
	--cl-b: #000;

	--cl-gray: #707070;
	--cl-black: #171717;
	
	--cl-lv-1: #0C1E72;
	--cl-lv-2: #1F6BB4;
	--cl-lv-3: #293D9C;
	--cl-lv-4: #8D8D8D;
	--cl-lv-5: #D1D1D1;
	--cl-lv-6: #D6F7FF;
	--cl-lv-7: #E5E5E5;
	--cl-lv-8: #F0F0F0;
	--cl-lv-9: #F1ECE5;
	--cl-lv-10: #F6F6F6;
	--cl-lv-11: #E6FFFF;

	--cl-overlay-1: #0000004D;
	--cl-overlay-2: #1B23357F;
	--cl-overlay-3: #0C1E7291;
}

html, body{
	// trình duyệt mặc định font-size: 16px
	-webkit-touch-callout : none ; 
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-font-smoothing: antialiased;

	line-height: 1;
	@include family--primary;
	@include regular;

	// scrollbar-width: none;
	// -ms-overflow-style: none;
	// &::-webkit-scrollbar{
	// 	display: none;
	// 	width: 0;
	// 	background-color: transparent;
	// }

	// overflow-x: hidden;
}

html {
	font-size: 52.5%; // 8.4px
	@include rpmin(md) {
		font-size: 57.5%; // 9.2px
	}
	@include rpmin(xl) {
		font-size: 62.5%; // 10px
	}
	// @media (min-width: 1921px) {
	// 	font-size: 77%; // 12.32px
	// }
}

body {
	// font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
	// 	"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
	// 	"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	
	background-color: var(--color-w);
	color: var(--color-text);
	line-height: 1.2;
	// font-size: 1.6rem;
	@include sizeBs(s16);

}

// fullpage
main{
	position: relative;
	
	height: auto;
	width: 100%;

	// overflow: hidden;

	// @include rp(tx){
	// 	height: auto;
	// 	overflow-x: hidden;
	// 	overflow-y: auto;
	// }

	// &>section{
	// 	width: 100%;
	// 	height: 100%;
	
	// 	@include rp(tx){
	// 		height: auto;
	// 	}
	// }
}


main,
#banner,
#bannerSub,
#breadcrumb{
	// max-width: 1336px;
	margin-left:auto;
	margin-right:auto;
	// overflow: hidden;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
// 	margin-top: 0;
// 	margin-bottom: 0;
// }

// p {
// 	margin-top: 0;
// 	margin-bottom: 0;
// }

a[data-fancybox] img {
    cursor: zoom-in;
}

abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

.hidden{
	display: none;
}

address {
	margin-bottom: 0;
	font-style: normal;
	line-height: inherit;
}

b,
strong {
	font-weight: bolder;
	font-weight: 700;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

a {
	text-decoration: none;
	background-color: transparent;
}

pre,
code,
kbd,
samp {
	font-family: inherit;
	font-size: 1em;
}

pre {
	margin-top: 0;
	margin-bottom: 0;
	overflow: auto;
}

figure {
	margin: 0;
}

img {
	border-style: none;
	// max-width: 100%;
	width: 100%;
	height: auto;
	object-fit: cover;
	object-position: center;
}

img.svg{
	width: auto;
}

svg {
	overflow: hidden;
	vertical-align: middle;
}

table {
	border-collapse: collapse;
}

caption {
	padding-top: 0;
	padding-bottom: 0;
	text-align: left;
	caption-side: bottom;
}

th {
	text-align: inherit;
}

label {
	display: inline-block;
	margin-bottom: 0;
}

button {
	border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	// appearance: none;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

select {
	word-wrap: normal;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
	cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	-webkit-appearance: listbox;
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: 0;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
	cursor: pointer;
}

template {
	display: none;
}

*:focus,
*:active {
	outline: none;
}
