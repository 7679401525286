.about-1{
    max-width: 100%;
    overflow: hidden;
    
    background-color: var(--cl-lv-10);
    &__img{
        @include rpmin(lg){
            margin-right: 2.6rem;
        }
        position: relative;
        height: 100%;

        .img{
            display: block;
            height: 100%;
            object-fit: cover;
            max-height: 80rem;
        }

        .logo{
            object-fit: contain;

            position: absolute;
            top: 4vh;
            left: 2rem;
            width: 18vw;
            height: auto;
            z-index: 1;
            
            @include rpmin(lg){
                width: 40%;
                transform: translate(calc(-50% - 2rem), 0);
            }

            display: block;
            transform-origin: center center;
            // transform: rotate(359deg);

            img{
                display: block;
                // animation: rotateLogoInfinite 60s infinite;
            }
        }
    }

    .wrapper-content{
        margin-top: 2.4rem;

        color: var(--cl-black);

        p{
            color: var(--cl-black);
            line-height: 1.6;
        }
    }
}