.searches-1{
    background-color: #F6F6F6;

    &__content{
        &>*{
            @include sizeBs(s24);
            color: #707070;

        }
    }
}

.results{
    &-item{
        a{
            display: block;
        }
        .link{
            @include sizeBs(s28);
            color: var(--cl-main);
            @include bold;
            margin-bottom: 1.5rem;
        }
        &:hover{
            .link{
                color: var(--cl-sub);
            }
        }
        
        *{
            transition: .2s all;
            @include sizeBs(s22);
            color: #171717;
        }

        &:not(:first-child){
            @extend .pt-b40;
        }
    }
}