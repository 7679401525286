.n-1{
    position: relative;
    background-color: var(--cl-lv-10);
    &-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        object-fit: cover;
        @include rpmin(lg){
            object-fit: contain;
        }
        object-position: top center;
    }
    &>.container{
        position: relative;
        z-index: 1;
    }
    &-tabs{

        @include spaceBs(s30, margin-top);
        @include blockBs(b60, margin-bottom);
        display: flex;
        justify-content: center;
        .tab{
            &-list{
                margin-left: auto;
                margin-right: auto;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
        
                background-color: var(--cl-w);
                box-shadow: 0 3px 6px rgba(0,0,0,.16);
                border-radius: 10px;

                flex-direction: column;
                @include rpmin(md){
                    flex-direction: row;
                }
            }
            &-item{
                padding: 1.6rem 3rem;
                @include rpmin(lg){
                    padding: 1.6rem 8rem;
                }
                &>*{
                    @include bold;
                    @include sizeBs(s28);
                }

                &:not(:first-child){
                    @include rpmax(lg){
                        padding-top: 0;
                    }
                }

                cursor: pointer;
                &>*{
                    display: inline-block;
                    transition: .3s all;
                    position: relative;
                    line-height: 1.5;
                    padding-bottom: 8px;
                    &::after{
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0%;
                        height: 2px;
                        border-bottom: 1px dashed var(--cl-main);
                        transition: .3s all;
                        transform-origin: center;
                    }
                }
                &.active{
                    &>*{
                        color: var(--cl-main);
                        opacity: 1;
                        &::after{
                            width: 100%;
                        }
                    }
                }
                &:hover{
                    &>*{
                        color: var(--cl-main);
                        opacity: .9;
                    }
                }
            }
        }
    }
    &-wrapper{
        position: relative;
        z-index: 1;
    }
    &-option{
        
        display: none;
        &.active{
            display: block;
        }
        &>.row{
            justify-content: center;
        }
        .box-article{
            &-block{
                @include rpmax(md){
                    --ratioHeight: 144.45%;
                    --ratioHeightHover: 60%;
                }
                @include rpmax(sm){
                    --ratioHeight: 132%;
                }
            }
            &-content{
                .content-desc{
                    @include rpmax(md){
                        // display: none;
                    }
                }
            }
        }
    }
}