.bsv-page{
    height: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    &:hover{
        .bsv-page{
            &__box{
                background-color: rgba(#293D9C, .5);
            }
        }
    }
    &--top{
        position: relative;
        height: 0;
        padding-top: 80%;
        display: block;
    }

    &--bottom{
        flex: 1;
        background-color: var(--cl-w);
        padding: 3rem 3rem 0 3rem;

        display: flex;
        flex-direction: column;

        .braney-more{
            border-top: 1px solid #DFDDDD;
            text-align: center;
            padding: 2rem 0;
        }
    }

    &__bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background-color: #293D9C;

        img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    &__box{
        position: absolute;
        top: 16px;
        left: 16px;
        bottom: 16px;
        right: 16px;
        z-index: 1;
        transition: .6s all ease-in-out;
        background-color: #293D9C;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;

        .icon{
            margin-top: 5.8rem;

            svg, img{
                max-height: 7rem;
                object-fit: contain;
            }
        }

        .title{
            width: 80%;
            margin-top: 2.4rem;
            &>*{
                @include bold;
                color: var(--cl-w);
                @include sizeBs(s22);
            }
        }
    }

    &__desc{
        flex: 1;
        color: var(--cl-lv-4);
        margin-bottom: 3rem;
        &>*{
            color: var(--cl-lv-4);
        }
    }
}