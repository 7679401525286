.nd-1{
    &-title{
        &>*{
            @include sizeBs(s32);
            color: #171717;
            line-height: 1.6;
        }
    }
    &-time{
        @include sizeBs(s18);
        @include italic;
        color: var(--cl-main);

        margin-top: 2rem;
    }

    .wrapper-content{
        margin-top: 2rem;
    }

    .spaceRight{
        @include rpmin(lg){
            margin-right: 3rem;
        }        
        @include rpmin(xl){
            margin-right: 4rem;
        }
    }

    &-nav{
        width: 100%;
        padding: 4rem;
        border-top: 5px solid var(--cl-main);

        @include rpmax(lg){
            @include blockBs(b60, margin-top);
        }

        .nav-bar-item{
            &>*{
                @include sizeBs(s18);
                @include bold;
            }
        }
    }
    &-relate{
        @include blockBs(b60, padding-top);
    }

    &-relate{
        display: none;
        &.active{
            display: block;
        }
        &__title{
            &>*{
                @include sizeBs(s32);
                color: var(--cl-gray);
            }
        }

        &-boxes{
            @include blockBs(b40, padding-top);
            position: relative;

            .swiper-custom-button{
                @include rpmin(lg){
                    display: none;
                }
            }

            @include rpmin(lg){
                .swiper{
                    overflow: initial;
                }
                .swiper-wrapper{
                    flex-direction: column;
                }

                .swiper-slide{
                    &:not(:first-child){
                        margin-top: 4rem;
                    }
                }
            }


        }
    }
}