[data-popup]{
    cursor: pointer;
}


.popup-careers{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;

    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    display: none;
    &.show{
        display: flex;
        .modal{
            // transition-delay: .2s;
            // transform: scale(1);
            transform: scale(0) rotate(-90deg);
            animation-delay: .2s;
            animation: modalPopup .3s linear forwards;
        }
    }

    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        background-color: #171717;
        opacity: .5;
        border: 1px solid #707070;
    }
    
    transition: .16s all linear;
    *{
        transition: .16s all linear;
    }

    .modal{
        position: relative;
        z-index: 1;

        display: flex;
        align-items: flex-end;
        // box-shadow: inset 0 3px 6px rgba(0,0,0,.16);

        transform: scale(0);
        &-wrapper{
            background-color: white;
            max-width: 94vw;
            width: 90rem;
            min-height: 40rem;
            height: auto;
            max-height: 84vh;
            
            @include rpmin(lg){
                margin-top: 2rem;
                margin-right: 2rem;
            }
            
            @include rpmin(xl){
                margin-top: 3rem;
                margin-right: 3rem;
            }
        }

        &-close{
            position: absolute;
            top: -4rem;
            right: 0;
            width: 4rem;
            height: 4rem;


            z-index: 1;
            border-radius: 50%;
            background-color: transparent;
            transition: .2s all linear;
            cursor: pointer;

            @include rpmin(lg){
                background-color: #FFFFFF;
                top: 0;
                right: 0;
                width: 4rem;
                height: 4rem;
            }
            @include rpmin(xl){
                width: 6rem;
                height: 6rem;    
            }

            &::before, &::after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 2.2rem;
                @include rpmin(md){
                    width: 42%;
                }
                height: 0;
                transform: translate(-50%, -50%);
                border-top: 2px solid #FFF;
                transition: .2s all linear;
                user-select: none;
                pointer-events: none;
                @include rpmin(lg){
                    border-top: 2px solid #707070;
                }
            }

            &::before{
                transform: translate(-50%, -50%) rotate(-45deg);
            }
            &::after{
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:hover{
                &::before{
                    transform: translate(-50%, -50%) rotate(-135deg);
                }
                &::after{
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }

        .title{
            display: block;
            width: 100%;
            padding: 2rem 6rem;
            background-color: var(--cl-main);
            text-align: center;
            &>*{
                color: var(--cl-w);
                @include sizeBs(s18);
                @include bold;
                line-height: 1.4;
            }
        }

        .formBrn{
            padding-left: 3rem;
            padding-right: 3rem;

            @include rpmin(md){
                padding-left: 4rem;
                padding-right: 4rem;
            }
            @include rpmin(lg){
                padding-left: 6rem;
                padding-right: 6rem;
            }


            input, select, textarea, button,.braney-more{
                border-radius: 10px;
            }

            .inputSubmit{
                width: 100%;
                padding-right: 0;
            }
        }
    }
}