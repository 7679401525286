.idx-4{
    @include rpmax(lg){
        @include blockBs(b80, padding-top);
    }
    max-width: 100%;
    overflow: hidden;

    &-top{
        margin-top: 2rem;
        align-items: flex-end;

        .braney-more{
            text-align: right;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            
            @include rpmin(md){
                justify-content: flex-end;
                align-items: flex-end;
                text-align: right;
            }
        }
    }

    &-tabs{
        .tab-item{
            cursor: pointer;
            margin-bottom: 2rem;
            justify-content: center;
            text-align: center;
            @include rpmin(md){
                margin-bottom: 0;
                justify-content: flex-start;
                text-align: left;
            }
            &>*{
                display: inline-block;
                transition: .3s all;
                position: relative;
                line-height: 1.5;
                padding-bottom: 8px;
                &::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0%;
                    height: 2px;
                    border-bottom: 1px dashed var(--cl-main);
                    transition: .3s all;
                    transform-origin: center;
                }
            }
            &.active{
                &>*{
                    color: var(--cl-main);
                    opacity: 1;
                    &::after{
                        width: 100%;
                    }
                }
            }

            &:hover{
                &>*{
                    color: var(--cl-main);
                    opacity: .9;
                }
            }
        }
    }

    &-swiper{
        position: relative;

        display: none;

        .swiper-wrapper{
            padding:  6px 3px 6px 3px;
        }

        &.active{
            display: block;
        }
    }
}