.block-t3{
    .title{
        background-color: #303090;
        text-align: center;
        display: flex;
        justify-content: center;
        align-self: center;
        &>*{
            color: var(--cl-w);
            padding: 1rem 2rem;
            @include sizeBs(s18);
        }
    }

    .img{
        display: block;
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 54.6%;
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &--bottom{
        margin-top: 2.4rem;
    }

    .tb-item{
        display: flex;
        align-items: stretch;
        justify-content:center;
        width: 100%;
        background-color: transparent;
        flex-wrap: wrap;

        &:not(:nth-of-type(2)){
            margin-top: .8rem;
        }

        &>*{
            background-color: #F5F5F5;
        }

        *{
            @include sizeBs(s18);
            @include regular;
        }

        &--merge{
            display: flex;
            align-items: stretch;
            justify-content:center;
            width: 100%;
            background-color: transparent;
            flex-wrap: wrap;

            &>*{
                background-color: #F5F5F5;
            }

            .tb-item__key{
                border-top: none;
            }

            .tb-item__value{
                border-top: none;
            }



            &:first-child{
                .tb-item__key{
                    border-top: 1px solid #707070;
                }
                .tb-item__value{
                    &>*{
                        border-top: 1px solid #707070;
                    }
                }
            }
        }

        &__key{
            width: 25%;

            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            
            @include rpmin(lg){
                text-align: left;
                justify-content: flex-start;
                padding-left: 6rem;
            }
            border: 1px solid #707070;

            &[data-colspan="2"]{
                width: 50%;
            }
        }

        &__value{
            margin-left:.8rem; 
            flex: 1;

            border-top: 1px solid #707070;
            
        }

        &--row{
            display: flex;

            &>*{
                border-left: 1px solid #707070;
                border-right: 1px solid #707070;
                border-bottom: 1px solid #707070;
                
                flex: 1;
                // border-top: 1px solid #707070;
                
                padding: 1rem 1rem 1rem 1rem;
                text-align: center;
                @include rpmin(lg){
                    text-align: left;
                    padding: 1rem 1rem 1rem 3rem;
                }

                &:not(:first-child){
                    margin-left: .8rem;
                }
            }
        }
    }
}