
.input{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-right: -2rem;
    margin-top: -2rem;

    &.ip-3{
        margin-left: -1rem;
        margin-right: -1rem;
        margin-top: -2rem;
        .inputForm{
            width: 100%;

            padding-left: 1rem;
            padding-right: 1rem;
            padding-top: 2rem;

            @include rpmin(md){
                width: 33.33333%;
            }
        }
    }

    .inputForm{
        display: flex;
        flex-direction: column;
        width: 100%;

        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem;
        
        @include rpmin(md){
            padding-right: 2rem;
            width: 50%;
        }

        &.full{
            width: 100%;
            @include rpmin(md){
                width: 100%;
            }
        }
    }

     
    // label{
    //     margin-bottom: 2rem;
    //     // @include sizeBs(bodyText1);
    //     @include sizeBs(s18);
    //     // color: map-get($color,tx1 );
    //     color: var(--cl-text);

    //     span{
    //         color: inherit;
    //     }
    // }

    // select {
    //     -webkit-appearance: none;
    //     -moz-appearance: none;
    //     -ms-appearance: none;
    //     appearance: none;
    //     outline: 0;
    //     box-shadow: none;
    //     border: 0;
    //     background: transparent;
    //     background-image: none;
    // }

    // input,textarea, select{
    //     padding: 1.5rem 2rem;
    //     border-radius: 0;
    //     // border: solid 1px var(--cl-gray);
    //     @include sizeBs(s16);
    //     resize: none;
    //     border: none;
    //     box-shadow: 0 3px 6px rgba(0,0,0,.16);
    //     color: var(--cl-black);
    // }

    // .group-opt{
    //     position: relative;
    //     display: flex;
    //     width: 100%;
    //     line-height: 1.4;
    //     background: #fff;
    //     overflow: hidden;
    //     box-shadow: 0 3px 6px rgba(0,0,0,.16);

    //     select {
    //         width: 100%;
    //         flex: 1;
    //         cursor: pointer;
    //     }

    //     option[disabled]{
    //         display: none;
    //     }

    //     /* thiết kế dấu mũi tên */
    //     &::after {
    //         content: '\25BC';
    //         position: absolute;
    //         top: 0;
    //         right: 0px;
    //         height:100%;
    //         padding: 0 3rem;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;

    //         background: transparent;
    //         cursor: pointer;
    //         pointer-events: none;
    //         -webkit-transition: .25s all ease;
    //         -o-transition: .25s all ease;
    //         transition: .25s all ease;
    //         color: #05ACEB;
    //     }

    //     /* Transition */
    //     &:hover::after {
    //         color: #707070;
    //     }
    // }
}
.table{
    display: block;
    max-width: 100%;
    overflow: auto;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    *{
        @include sizeBs(s16);
        transition: .3s all;
        // color: var(--cl-black);
    }

    table{
        min-width: 1024px;
    }

    // &-mobile{
    //     display: block;
    //     @include rpmin(md){
    //         display: none;
    //     }
    // }


    table{
        border-collapse: collapse;
        width: 100%;

        thead{
            th{
                background-color: var(--cl-w);
                box-shadow: 0 3px 6px rgba(0,0,0,.16);
            }
        }

        tbody{
            tr{
                background-color: #E5E5E5;
                &.disabled{
                    background-color: var(--cl-w);

                    td{
                        input, select{
                            cursor: initial;
                            user-select: none;
                            appearance: none;
                        }

                        .group-opt{
                            &::after {
                                color: #707070;
                                opacity: .3;
                            }
                        }
                    }
                }
            }
            td{
                &>*{
                    box-shadow: 0 3px 6px rgba(0,0,0,.16);
                }
            }
        }

        th{
            padding: 2rem .8rem;
        }

        td{
            padding: .8rem;
            input, select, .actionEvt{
                height: 40px;
                width: 100%;
                padding: 0 1.2rem;
                font-weight: normal;
                outline: none;
                border: none;
                background-color: var(--cl-w);
            }

            .actionEvt{
                display: flex;
                justify-content: center;
                align-items: center;

                button{
                    width: 2.3rem;
                    height: 2.3rem;
                    background-color: transparent;
                    outline: none;
                    border: 1px solid #707070;
                    position: relative;
                    transition: .3s all;

                    &::after, &::before{
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 1rem;
                        height: 2px;
                        background-color: #707070;
                    }
                }

                .btnAdd{
                    &::before{
                        transform: translate(-50%, -50%) rotate(90deg);
                    }

                    &:hover{
                        border: 1px solid var(--cl-sub);
                        background-color: var(--cl-sub);
                        &::before, &::after{
                            background-color: white;
                        }
                    }
                }
                .btnRemove{
                    &::before, &::after{
                        background-color: red;
                    }

                    &:hover{
                        border: 1px solid var(--cl-sub);
                        background-color: var(--cl-sub);
                        &::before, &::after{
                            background-color: red;
                        }
                    }

                    &::before{
                        transform: translate(-50%, -50%) rotate(45deg);
                    }

                    &::after{
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }
            }
        }
    }

    // select {
    //     -webkit-appearance: none;
    //     -moz-appearance: none;
    //     -ms-appearance: none;
    //     appearance: none;
    //     outline: 0;
    //     box-shadow: none;
    //     border: 0;
    //     background: transparent;
    //     background-image: none;
    // }

    .group-opt{
        /* thiết kế dấu mũi tên */
        &::after {
            padding: 0 .6rem;
        }
    }

    .input-item[disabled]{
        color: var(--cl-text);
        opacity: .7;
        user-select: none;
    }
}

form{
    &>.row{
        padding: 4px 6px;
    }

    label{
        margin-bottom: 2rem;
        // @include sizeBs(bodyText1);
        @include sizeBs(s18);
        // color: map-get($color,tx1 );
        color: var(--cl-text);

        span{
            color: inherit;
        }
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        outline: 0;
        box-shadow: none;
        border: 0;
        background: transparent;
        background-image: none;
    }

    input,textarea, select{
        width: 100%;
        padding: 1.5rem 2rem;
        border-radius: 0;
        // border: solid 1px var(--cl-gray);
        @include sizeBs(s16);
        resize: none;
        border: none;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
        color: var(--cl-black);
    }

    .group-opt{
        position: relative;
        display: flex;
        width: 100%;
        line-height: 1.4;
        background: #fff;
        overflow: hidden;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);

        select {
            width: 100%;
            flex: 1;
            cursor: pointer;
        }

        option[disabled]{
            display: none;
        }

        /* thiết kế dấu mũi tên */
        &::after {
            content: '\25BC';
            position: absolute;
            top: 0;
            right: 0px;
            height: 100%;
            padding: 0 3rem;
            display: flex;
            justify-content: center;
            align-items: center;

            background: transparent;
            cursor: pointer;
            pointer-events: none;
            -webkit-transition: .25s all ease;
            -o-transition: .25s all ease;
            transition: .25s all ease;
            color: #05ACEB;
        }

        /* Transition */
        &:hover::after {
            color: #707070;
        }
    }

    .inputFile{
        display: flex;
        align-items: center;
        flex-direction: column;
        .spanList{
            display: none;
            opacity: 0;
            visibility: hidden;
            transform: scale(0);
        }
        .preview{
            ol{
                list-style: none;
            }
        }
        &-group{
            p{
                @include sizeBs(s18);
                color: var(--cl-main);
                @include bold;

                display: none;

                @include rpmin(md){
                    display: inline-block;
                }
            }

            position: relative;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            width: auto;

            input[type="file"]{
                position: absolute;
                top: 0;
                left: 0;
                width:100%;
                height: 100%;
                z-index: 1;
                padding: 0;
                opacity: 0;
                cursor: pointer;
            }

            button.file{
                @include rpmin(md){
                    margin-left: 1rem;
                }

                display: flex;
                justify-content: center;
                align-items: center;

                line-height: 1.4;
                padding: .6rem 2rem;
                border-radius: 5px !important;
                border: 1px solid #171717;
                background: none;
                outline: none;

                svg{
                    margin-right: 1.2rem;
                }
            }
        }
    }
}
