.idx-2{
    max-width: 100%;
    overflow: hidden;
    .braney-subtitle{
        justify-content: center;
        text-align: center;
    }
    .braney-title{
        margin-top: 2rem;
        text-align: center;
    }

    &-swiper{
        position: relative;
    }
}