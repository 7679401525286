.lib-1{
    background-color: var(--cl-lv-5);

    &-tabs{
        @include spaceBs(s30, margin-top);
        @include blockBs(b60, margin-bottom);
        display: flex;
        justify-content: center;
        .tab{
            &-list{
                margin-left: auto;
                margin-right: auto;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
        
                background-color: var(--cl-w);
                box-shadow: 0 3px 6px rgba(0,0,0,.16);
                border-radius: 10px;

                flex-direction: column;
                @include rpmin(md){
                    flex-direction: row;
                }
            }
            &-item{
                padding: 1.6rem 3rem;
                @include rpmin(lg){
                    padding: 1.6rem 8rem;
                }
                &>*{
                    @include bold;
                    @include sizeBs(s28);
                }

                &:not(:first-child){
                    @include rpmax(lg){
                        padding-top: 0;
                    }
                }

                cursor: pointer;
                &>*{
                    display: inline-block;
                    transition: .3s all;
                    position: relative;
                    line-height: 1.5;
                    padding-bottom: 8px;
                    &::after{
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0%;
                        height: 2px;
                        border-bottom: 1px dashed var(--cl-main);
                        transition: .3s all;
                        transform-origin: center;
                    }
                }
                &.active{
                    &>*{
                        color: var(--cl-main);
                        opacity: 1;
                        &::after{
                            width: 100%;
                        }
                    }
                }
                &:hover{
                    &>*{
                        color: var(--cl-main);
                        opacity: .9;
                    }
                }
            }
        }
    }

    &-option{
        display: none;
        &.active{
            display: block;
        }
        &>.row{
            justify-content: center;
        }
        
        &__item{
            .image{
                cursor: zoom-in;
            }
            .image, .video{
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 100%;
                display: block;

                img{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &::after{
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0%;
                    height: 100%;
                    background-color: rgba(255, 255, 255, .2);
                    transform-origin: left center;
                    z-index: 1;
                    transition: .3s all;
                }

                &:hover{
                    &::after{
                        width: 100%;
                    }
                }
            }

            .video{
                &::after{
                    content: none;
                }
                .playYtb{
                    z-index: 11;
                    width: 18%;
                    padding-top: 18%;
                    height: 0;
                }
            }
        }
    }
}