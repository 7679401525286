.box-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &-number{
        --wh: 5.4rem;
        @include rpmin(lg){
            --wh: 62px;
        }

        width: var(--wh);
        height: var(--wh);
        padding: 1rem;

        border-radius: 50%;
        background-color: var(--cl-sub);

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        transition: .3s all;

        cursor: pointer;

        
        &::after{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            border: 2px dashed #fff;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            transform-origin: center;
            transition: 1.2s all;

            transition: .3s all;

        }

        &>*{
            @include bold;
            @include sizeBs(s22);
            color: var(--cl-w);
        }
    }

    &-icon{
        width: 15rem;
        height: 15rem;
        background-color: var(--cl-w);
        border: 2px solid var(--cl-main);

        display: flex;
        justify-content: center;
        align-items: center;

        transition: .3s all;

        img{
            display: block;
            width: 60%;
            height: 60%;
        }
    }

    &-content{
        margin-left: auto;
        margin-right: auto;
        &>*{
            @include bold;
            @include sizeBs(s22);
        }
    }

    &>*:not(:first-child){
        margin-top: 2rem;
    }
    &:hover{
        .box-form-number{
            box-shadow: 0 3px 6px rgba(0,0,0,.16);

            &::after{
                animation: _360-infinite-btn 8s linear infinite;
            }
        }

        .box-form-icon{
            box-shadow: 0 3px 6px rgba(0,0,0,.16);
        }
    }
}