.overflow{
    overflow: auto;
}
.overflow-y{
    overflow-x: initial;
    overflow-y: auto;
}
.overflow-x{
    overflow-y: initial;
    overflow-x: auto;
}

.overflow, .overflow-y, .overflow-x{
    max-width: 100%;
    &::-webkit-scrollbar {
        width: 6px;
        height: 4px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px var(--cl-gray); 
        border-radius: 10px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--cl-main); 
        border-radius: 10px;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: var(--cl-sub); 
    }
}