.about-3 {
    background-color: var(--cl-lv-10);
    &-swiper,
    &-swiper2 {
        position: relative;

        .swiper {
            padding: 10px;
            margin: 0 -10px;
        }
    }

    &__item {
        display: block;
        padding: 0 2.6rem 5rem 2.6rem;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 60%;
            background-color: map-get($color, w);
            z-index: -1;
            box-shadow: -10px 10px 10px rgba(0, 0, 0, 0.16);
        }

        img {
            display: block;
        }

        cursor: pointer;

        &:hover {
            img {
                animation-name: bounce;
                animation-duration: 1s;
                transform-origin: center bottom;
            }
        }
    }
}
