.st-1{
    background-color: var(--cl-lv-10);

    &-teams{
        .team{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            max-width: 320px;
            @include rpmin(lg){
                max-width: initial;
            }

            .avt{
                display: block;
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 100%;
                &>*{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        
            .infor{
                position: relative;
                z-index: 1;
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                // margin-top: -3rem;
                transform: translateY(-50%);
                background-color: var(--cl-w);
                box-shadow: 0 3px 6px rgba(0,0,0,.16);

                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 2rem 1.2rem;
                
                .name{
                    color: var(--cl-black);
                    @include bold;
                    @include sizeBs(s24);
                    line-height: 1.4;
                }
                
                &-details{
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: translateY(-100%);

                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    transition: .6s all;
                    overflow: hidden;
                }

                .office{
                    position: relative;
                    transition: .6s all;

                    padding: .8rem 1rem;
                    min-width: 12rem;
                    max-height: 40px;
                    background-color: #293D9C;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    &>*{
                        @include bold;
                        @include sizeBs(s16);
                        color: var(--cl-w);
                    }
                }

                .socials{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    transform-origin: left;
                    transition: .6s all;
                    width: 0;
                    clip-path: polygon(0 0, 0 53%, 0 100%, 0% 100%, 0 52%, 0% 0%);

                    
                    &-item{
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: 4rem;
                        height: 4rem;
                        background-color: #05ACEB;
                        
                        &:hover{
                            background-color: #1F6BB4;
                            box-shadow: 0 3px 6px rgba(0,0,0,.16);
                        }
                        transition: .6s all;
                    }
                }
            }

            &:hover{
                .infor{
                    &-details{
                        .socials{
                            width: 12rem;
                            clip-path: polygon(100% 0%, 125% 50%, 100% 100%, -25% 100%, 0% 50%, -25% 0%);
                        }
                    }
                }
            }
        }
    }
}