@keyframes appearBox {
    from {
        transform: translateY(100px);
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes grow {
    from {
        box-shadow: 0px 0px 1em var(--cl-main);
    }

    to {
        box-shadow: 0px 0px 3em var(--cl-main);
    }
}

@keyframes rotateLogoInfinite{
    to {
        transform: rotate(359deg);
    }
}

@keyframes _360-infinite {
    to {
        transform: translate(14.3rem,10.4rem) rotate(359deg);
    }
}

@keyframes _360-infinite-btn {
    to {
        transform: translate(-50%, -50%) rotate(359deg);
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        -webkit-transform: translate3d(0, -5px, 0) scaleY(1.1);
        transform: translate3d(0, -5px, 0) scaleY(1.1)
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        -webkit-transform: translate3d(0, -2px, 0) scaleY(1.05);
        transform: translate3d(0, -2px, 0) scaleY(1.05)
    }
    80% {
        -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
        transition-timing-function: cubic-bezier(.215, .61, .355, 1);
        -webkit-transform: translateZ(0) scaleY(.95);
        transform: translateZ(0) scaleY(.95)
    }

    90% {
        -webkit-transform: translate3d(0, -1px, 0) scaleY(1.02);
        transform: translate3d(0, -1px, 0) scaleY(1.02)
    }
}


@keyframes bounceDot {
    0%,
    20%,
    53%,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        -webkit-transform: translate3d(0, -5px, 0) scaleY(1.1);
        transform: translate3d(0, -5px, 0) scaleY(1.1)
    }

    70% {
        -webkit-animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        animation-timing-function: cubic-bezier(.755, .05, .855, .06);
        -webkit-transform: translate3d(0, -2px, 0) scaleY(1.05);
        transform: translate3d(0, -2px, 0) scaleY(1.05)
    }
    80% {
        -webkit-transition-timing-function: cubic-bezier(.215, .61, .355, 1);
        transition-timing-function: cubic-bezier(.215, .61, .355, 1);
        -webkit-transform: translateZ(0) scaleY(.95);
        transform: translateZ(0) scaleY(.95)
    }

    // 90% {
    //     -webkit-transform: translate3d(0, -1px, 0) scaleY(1.02);
    //     transform: translate3d(0, -1px, 0) scaleY(1.02)
    // }
}

@keyframes modalPopup {
    to{
        transform: scale(1) rotate(0);
    }
}

@keyframes phone-outer {
    0% {
      transform: translate3d(0, 0, 0) scale(1);
      box-shadow:
        0 0 0 0em rgba(#{var(--cl-sub)}, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    33.3333% {
      transform: translate3d(0, 0, 0) scale(1.1);
      box-shadow:
        0 0 0 0em rgba(#{var(--cl-sub)}, 0.1),
        0em 0.05em 0.1em rgba(#000000, 0.5);
    }
    66.6666% {
      transform: translate3d(0, 0, 0) scale(1);
      box-shadow:
        0 0 0 0.5em rgba(#{var(--cl-sub)}, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
      box-shadow:
        0 0 0 0em rgba(#{var(--cl-sub)}, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
}
@keyframes phone-inner {
0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
}
33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9);
}
66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
}
100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
}
}

@keyframes phone-icon {
    0% { transform: translate3d(0em, 0, 0); }
    2% { transform: translate3d(0.01em, 0, 0); }
    4% { transform: translate3d(-0.01em, 0, 0); }
    6% { transform: translate3d(0.01em, 0, 0); }
    8% { transform: translate3d(-0.01em, 0, 0); }
    10% { transform: translate3d(0.01em, 0, 0); }
    12% { transform: translate3d(-0.01em, 0, 0); }
    14% { transform: translate3d(0.01em, 0, 0); }
    16% { transform: translate3d(-0.01em, 0, 0); }
    18% { transform: translate3d(0.01em, 0, 0); }
    20% { transform: translate3d(-0.01em, 0, 0); }
    22% { transform: translate3d(0.01em, 0, 0); }
    24% { transform: translate3d(-0.01em, 0, 0); }
    26% { transform: translate3d(0.01em, 0, 0); }
    28% { transform: translate3d(-0.01em, 0, 0); }
    30% { transform: translate3d(0.01em, 0, 0); }
    32% { transform: translate3d(-0.01em, 0, 0); }
    34% { transform: translate3d(0.01em, 0, 0); }
    36% { transform: translate3d(-0.01em, 0, 0); }
    38% { transform: translate3d(0.01em, 0, 0); }
    40% { transform: translate3d(-0.01em, 0, 0); }
    42% { transform: translate3d(0.01em, 0, 0); }
    44% { transform: translate3d(-0.01em, 0, 0); }
    46% { transform: translate3d(0em, 0, 0); }
}