@font-face {
  font-family: "utm-avo";
  src: url("../fonts/UTM-Avo.ttf") format("truetype");
  @include regular;
}
@font-face {
  font-family: "utm-avo";
  src: url("../fonts/UTM-AvoBold.ttf") format("truetype");
  @include bold;
}
@font-face {
  font-family: "utm-avo";
  src: url("../fonts/UTM-AvoItalic.ttf") format("truetype");
  @include italic;
}
@font-face {
  font-family: "utm-avo";
  src: url("../fonts/UTM-AvoBold_Italic.ttf") format("truetype");
  @include bold;
  @include italic;
}
