.e-info {
    &-wrapper {
        &__left {
            height: 100%;

            &>img {
                height: 100%;
                object-fit: cover;
                object-position: center;
                max-height: 450px;
                min-height: 280px;
            }

            position: relative;

            
        }

        .braney-title {
            line-height: 1.6;
            flex: 1;
        }

        &__content {
            margin-top: 1rem;
            @include sizeBs(s16);
            color: var(--cl-gray);
            line-height: 1.4;
        }

        .submit-now {
            margin-top: 3.4rem;
        }

        &__right{
            height: 100%;
            display: flex;
            flex-direction: column;

        }
    }

    &:nth-of-type(n + 2) {
        &:nth-of-type(2n+ 1){
            .e-info-wrapper {
                flex-direction: row-reverse;
            }
        }
        &:nth-of-type(2n){
            background-color: #F6F6F6;
        }
    }
}