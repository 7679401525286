.f-1{
    &-tabs{
        *{
            transition: .3s all;
        }
        .tab-item{
            cursor: pointer;
            background-color: #F0F0F0;
            padding: 2rem 1.8rem;
            text-align: center;
            box-shadow: 0 3px 6px rgba(0,0,0,.16);
            border-radius: 10px;
            
            &>*{
                color: var(--cl-gray);
                @include sizeBs(s18);
                @include bold;
            }

            &:hover, &.active{
                background-color: #D6F7FF;
                &>*{
                    color: var(--cl-sub);
                }
            }
        }
    }

    &-opt{
        display: none;
        &.active{
            display: block;
        }
    }

    &>.container>.row{
        flex-direction: row-reverse;
    }

    &--left{
        @include rpmin(lg){
            margin-right: 3rem;
        }        
        @include rpmin(xl){
            margin-right: 4rem;
        }
    }
}



.box-download{
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @include rpmin(sm){
        flex-direction: row;
    }
    &--left{
        width:100%;
        @include rpmin(sm){
            width:20rem;
        }
    }
    .img{
        display: block;
        width: 100%;
        height: 100%;
        padding-top: 62.5%;
        position: relative;
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &--right{
        flex: 1;
        display: flex;
        flex-direction: column;

        @include rpmin(sm){
            margin-left: 2rem;
        }
        .title{
            flex: 1;
        }
    }

    .title{
        &>*{
            @include bold;
            @include sizeBs(s24);
            color: #707070;
            @include uppercase;
            line-height: 1.6;
        }
        margin-bottom: 2rem;
    }
    .braney-more{
        margin-bottom: .8rem;
    }

    & + .box-download{
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px solid #707070;
    }
}
