.braney{
    &-title{
        &>*{
            @include bold;
            @include sizeBs(s32);
            // color: var(--cl-black);
            color: var(--cl-sub);
            line-height: 1.4;
        }
        &.center{
            text-align: center;
        }
    }

    &-subtitle{
        display: flex;
        align-items: center;
        &.center{
            justify-content: center;
            text-align: center;
        }
        &>*{
            @include bold;
            @include sizeBs(s18);
            text-transform: uppercase;
            color: var(--cl-main);
            text-align: center;
        }
    
        path{
            fill: var(--cl-main);
        }
    
        &>*:not(:first-child){
            margin-left: .6rem;
        }
        
        text-align: center;
        justify-content: center;
        @include rpmin(md){
            justify-content: flex-start;
            text-align: left;
        }
    }

    &-subtitle ~ &-title{
        margin-top: 2rem;
    }

    &-desc{
        @include sizeBs(s20);
        color: var(--cl-text);
    }

    &-more{
        &>*{
            @include bold;
            @include sizeBs(s16);
            color: var(--cl-black);
            display: inline-flex;
            align-items: center;
            text-transform: uppercase;
            transition: .3s all;
            
            svg{
                margin-right: .6rem;
                transition: .3s all;
            }
            
            &:hover{
                color: var(--cl-sub) !important;
                svg{
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

form{
    max-width: 100%;
    overflow: hidden;
}