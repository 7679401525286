.qt-1{
    margin-bottom: 40px;
    &-wrapper{
        .braney-subtitle{
            &>*{
                @include sizeBs(s32);
                @include regular;
            }
        }

        .braney-subtitle>:not(:first-child){
            margin-left: 20px;
        }
    }

    .ft-17{
        font-size: 15px;
        @include rpmin(md){
            font-size: 17px;
        }
    }
    .ft-16{
        font-size: 14px;
        @include rpmin(md){
            font-size: 16px;
        }
    }
    .ft-15{
        font-size: 13.5px;
        @include rpmin(md){
            font-size: 15px;
        }
    }

    &-results{
        .navibar{
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 20px 0;
            background-color: #F0F3F5;

            gap: 20px;

            @supports not (gap: 20px){
                .input[type="submit"], button[type="submit"]{
                    margin-left: 20px;
                }
            }

            &-info{
                width: calc(100% - 123px);

                dl{
                    width: 100%;
                    display: flex;
                    text-align: left;
                    gap: 20px;

                    @supports not (gap: 20px){
                        dd{
                            margin-left: 20px;
                        }
                    }

                    dt{
                        // font-size: 17px;
                        @extend .ft-17;

                        font-weight: 400;
                        line-height: 127%;

                        color: #171717;

                        white-space: nowrap;
                    }

                    dd{
                        @extend .ft-17;

                        font-weight: 700;
                        line-height: 127%;

                        color: #171717;

                        flex: 1;

                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                }
            }

            &-back{
                cursor: pointer;
                display: block;
                width: 53px;
                padding-left: 30px;


                &:hover{
                    svg{
                        transition: .3s all cubic-bezier(0.075, 0.82, 0.165, 1);
                        transform: translateX(-10px);
                    }
                }
            }

            &-print{
                width: 43px;
                padding-right: 23px;

                display: block;
                &:hover{
                    transition: .3s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
                    opacity: .8;
                }
            }
        }

        .results{
            --hName: 48px;
            @include rpmin(md){
                --hName: 60px;
            }
            --topRs: calc(#{var(--hName)} + 30px);
            
            &-top{
                margin-top: 30px;
                padding: 0 23px 0 30px;
                &>*{
                    @extend .ft-17;

                    @include bold;
                    line-height: 127%;
                    color: #171717;
                }
            }

            &-ls{
                .item{
                    position: relative;
                    &-name{
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        border-radius: 8px;
                        
                        @extend .ft-16;

                        @include bold;
                        background-color: #05ACEB;
                        color: #FFF;


                        height: var(--hName, 60px);
                        
                        cursor: pointer;
                        // &>*{
                        //     pointer-events: none;
                        // }
                        &>*{
                            padding: 12px 23px 12px 30px;
                            @include rpmin(md){
                                padding: 20px 23px 20px 30px;
                            }
                        }
                        .icon{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: .3s all ease-in-out;
                            height: 100%;
                        }
                    }

                    &.is-showed{
                        .item-name{
                            .icon{
                                transform: rotate(-180deg);
                            }
                        }

                        .item-wrapper{
                            height: auto;
                            padding-top: var(--topRs, 96px) !important;
                            padding-bottom: 30px !important;
                        }
                    }

                    &-wrapper{
                        padding: var(--topRs, 96px) 30px 29px 31px;
                        background-color: #F6F6F6;
                        border-radius: 8px;

                        height: 0;
                        overflow: hidden;
                        transition: .3s all ease-in-out;

                        padding-top: var(--hName, 60px) !important;
                        padding-bottom: 0 !important;
                    }

                    .shipment-details{
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;

                        @include rpmin(md){
                            gap: 40px;
                        }

                        @supports not (gap: 40px){
                            .shipment-details__section:not(:first-child){
                                margin-left: 40px;
                            }
                        }

                        &__section{
                            flex: 0 0 100%;
                            width: 100%;
                            
                            @include rpmin(md){
                                flex: 0 0 40%;
                                width: 40%;
                            }
                            @include rpmin(lg){
                                flex: 0 0 32%;
                                width: 32%;
                            }

                            @include rpmax(md){
                                &:not(:first-child){
                                    margin-top: 20px;
                                    padding-top: 20px;
                                    border-top: 1px solid #171717;
                                }
                            }

                            .icon{
                                margin-bottom: 20px;
                            }

                            dl{
                                dt{
                                    @include regular;
                                    // font-size: 16px;
                                    @extend .ft-16;

                                    color: #171717;
                                    line-height: 135%;

                                    &:not(:nth-of-type(1)){
                                        margin-top: 20px;
                                        @include rpmin(md){
                                            margin-top: 40px;
                                        }
                                    }
                                }
                                dd{
                                    margin-top: 10px;
                                    @include bold;
                                    // font-size: 15px;
                                    @extend .ft-15;

                                    color: #171717;
                                    line-height: 135%;
                                }
                            }
                        }

                        &__bottom{
                            flex: 0 0 100%;
                            width: 100%;

                            margin-top: 20px;
                            padding-top: 20px;

                            border-top: 1px solid #000;

                            ul{
                                display: flex;
                                flex-wrap: wrap;
                                width: 100%;
                                justify-content: space-between;
                                
                                list-style-type: none;
                                gap: 40px;
                                @include rpmin(lg){
                                    gap: 40px;
                                }
                                li{
                                    display: flex;
                                    align-items: center;
                                    .icon{
                                        margin-right: 25px;
                                    }

                                    dl{
                                        dt{
                                            font-weight: 400;
                                            // font-size: 16px;
                                            @extend .ft-16;

                                            line-height: 22px;
                                        }
                                        dd{
                                            margin-top: 3px;
                                            @include bold;
                                            // font-size: 15px;
                                            @extend .ft-15;

                                            line-height: 22px;
                                        }
                                    }

                                    @include rpmax(md){
                                        width: calc(50% - 20px);
                                        flex: 0 0 calc(50% - 20px);
                                    }

                                    @include rpmin(md){
                                        &:nth-of-type(2n + 1){
                                            flex: 0 0 calc(60% - 40px);
                                            width: calc(60% - 40px);
                                        }

                                        &:nth-of-type(2n){
                                            width: 40%;
                                            flex: 0 0 40%;
                                        }
                                    }
                                    @include rpmin(lg){
                                        &:nth-of-type(2n + 1){
                                            flex: 0 0 auto;
                                            width: auto;
                                        }
                                        &:nth-of-type(2n){
                                            flex: 0 0 auto;
                                            width: auto;
                                        }
                                        &:nth-of-type(4n){
                                            width: 32%;
                                            flex: 0 0 32%;
                                        }
                                    }
                                    

                                }
                            }
                        }
                    }

                    & + .item{
                        margin-top: 20px;
                    }

                    .shipment-line{
                        display: none;

                        @include rpmin(md){
                            display: block;
                            top: calc(var(--topRs, 96px) + (39px / 2));
                            left: calc(30% - 8.7vw);
                            width: calc(36% - 48px);
                            height: 24px;
                            transform: translate(0%, -50%);

                            position: absolute;


                            &::after{
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 0%;
                                width: 100%;
                                height: 0;
                                border-bottom: 1px solid #171717;
                                transform: translate(0%, -50%);
                            }
    
                            .start, .end{
                                z-index: 1;
                                width: 24px;
                                height: 24px;
                                border-radius: 100%;
                                position: absolute;
                                top: 50%;
                                transform: translate(-50%, -50%);
    
                                &::after{
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    width: 8px;
                                    height: 8px;
                                    transform: translate(-50%, -50%);
                                    border-radius: inherit;
                                }
                            }
    
                            .start{
                                left: 0;
                                border: 2px solid #000000;
                                background: transparent;
                                transform: translate(-100%, -50%);
    
                                &::after{
                                    background-color: #303090;
                                }
                            }
    
                            .end{
                                right: 0;
                                background: #303090;
                                transform: translate(100%, -50%);
    
                                &::after{
                                    background-color: #FFF;
                                }
                            }
                        }

                        @include rpmin(lg){
                            left: calc(40% - 8.7vw);
                        }
                    }

                    .vessel-flight{
                        overflow: hidden;
                        ul{
                            display: flex;
                            list-style-type: none;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            gap: 40px;


                            &>li{
                                width: 100%;
                                @include rpmin(md){
                                    width: auto;
                                }
                                .name{
                                    display: flex;
                                    align-items: center;
                                    white-space: nowrap;
                                    &>*{
                                        @include bold;
                                        color: #171717;
                                        // font-size: 16px;
                                        @extend .ft-16;

                                        line-height: 135%;
                                    }

                                    .icon{
                                        margin-right: 20px;
                                    }
                                }

                                .value{
                                    margin-top: 10px;
                                    padding-top: 10px;
                                    // font-size: 16px;
                                    @extend .ft-16;
                                    line-height: 135%;

                                    position: relative;

                                    &::after{
                                        content: "";
                                        position: absolute;
                                        top: 0px;
                                        left: -40px;
                                        width: 100vw;
                                        height: 0;
                                        border-top: 1px solid #E1E1E1;
                                    }

                                }
                            }
                        }
                    }

                    .status-table{
                        padding: var(--topRs, 96px) 0px 30px 0px;

                        table{
                            width: 100%;
                        }
                        table td{
                            border-top: 1px solid #E1E1E1;
                        }
                    
                        thead{
                            th{
                                @include bold;
                                // font-size: 16px;
                                @extend .ft-16;
                                color: #171717;
                                padding: 10px 30px 10px 30px;
                    
                                span{
                                    display: inline-block;
                                    white-space: nowrap;
                                }
                            }
                        }
                    
                        tbody{
                            tr, td{
                                transition: .3s all ease-in-out;
                            }
                            td{
                                // font-size: 15px;
                                @extend .ft-15;
                                color: #171717;
                                padding: 10px 30px;
                    
                                &.td-bold{
                                    @include bold;
                                    color: #171717;
                                }
                            }
                        }
                    
                        @include rpmax(lg){
                            thead, thead tr, thead td{
                                display: none;
                            }
                            tbody, tbody tr, tbody td{
                                display: block;
                                text-align: left !important;
                            }
                    
                            tbody td{
                                position: relative;
                                display: flex;
                                &::before{
                                    content: attr(data-title)"";
                                    display: inline-block;
                                    width: 50%;
                                    flex: 0 0 50%;
                                    white-space: pre-line;
                                    font-size: inherit;
                    
                                    padding-right: 3px;

                                }

                                &[data-title=""]{
                                    &::before{
                                        content: none;
                                    }
                                }
                    

                                width: 100%;
                                border-top: 1px solid #E1E1E1;
                                
                                padding: 8px;
                                padding-left: 32px;
                                padding-right: 40px;
                    
                                &[data-empty="true"]{
                                    display: none;
                                }
                            }
                            tbody tr{
                                border-top: 1px solid #a8a8a8;
                            }
                        }
                        @include rpmax(md){
                            tbody td{
                                padding: 8px;
                                padding-left: 20px;
                                padding-right: 20px;
                    
                                &::before{
                                    flex: 0 0 38%;
                                    width: 38%;
                                }
                            }
                        }
                    }

                    .contact-table{
                        padding: var(--topRs, 96px) 0px 30px 0px;

                        ul{
                            display: flex;
                            width: 100%;
                            list-style-type: none;
                            flex-wrap: wrap;

                            &>li{
                                flex: 0 0 100%;
                                width: 100%;
                                
                                @include rpmax(md){
                                    &:nth-of-type(n + 2){
                                        border-top: 1px solid #E1E1E1;
                                    }
                                }

                                @include rpmin(md){
                                    flex: 0 0 50%;
                                    width: 50%;
                                    
                                    &:nth-of-type(n + 3){
                                        border-top: 1px solid #E1E1E1;
                                    }
                                }

                                p{
                                    padding: 10px 30px;
                                }

                                .key{
                                    @include bold;
                                    // font-size: 16px;
                                    @extend .ft-16;
                                    color: #171717;
                                }
                                
                                .val{
                                    border-top: 1px solid #E1E1E1;
                                    @include regular;
                                    // font-size: 15px;
                                    @extend .ft-15;
                                    color: #171717;
                                }
                                


                            }
                        }
                    }

                    .content{
                        
                        @extend .ft-15;
                        
                        line-height: 135%;
                        color: #171717;
                    }
                }
            }
        }
    }
}

.qt-form{
    padding: 20px 0 ;

    width: 100%;
    display: block;
    overflow: visible;

    .group-input{
        width: 100%;

        .entry-input{
            display: flex;
            flex-direction: column;
            &>*:not(:first-child){
                margin-top: 10px;
                
                @include rpmin(md){
                    margin-top: 0;
                }
            }
            @include rpmin(md){
                flex-direction: row;
                gap: 10px;

            }
        }

        input{
            padding: 21px 20px;
            appearance: none;
        }

        input:not([type="submit"]){
            background: #FAFAFA;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 0px;
            flex:1;
            border: none;
            outline: none;

            &:hover, &:focus{
                transition: .2s all ease-in-out;
            }
        }

        input[type="submit"]{
            width: auto;

            min-width: 302px;

            background: #05ACEB;
            box-shadow: none;

            border-radius: 0;

            color: white;
            @include bold;
            // font-size: 16px;
            @extend .ft-16;

            text-transform: capitalize;

            &:hover, &:focus{
                transition: .2s all ease-in-out;
                opacity: .8;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            }
        }
    }
}