.swiper-slide{
    height: auto;
}

.swiper{
    // @include rpmin(lg){
    //     overflow: hidden;
    // }

    &-custom-button{
        // --spaceBtn: 3rem;
        // @include rpmin(md){
        //     --spaceBtn: 4rem;
        // }
        // @include rpmin(lg){
        //     --spaceBtn: 2rem;
        // }
        // @include rpmin(xxl){
        //     --spaceBtn: -2rem;
        // }
        // @include rpmin(yl){
        //     --spaceBtn: -6rem;
        // }

        --wcontainer: 96%;
        @each $key,
        $size in $mdWidth {
            @media (min-width: $size) {
                --wcontainer: #{map-get($containerWidth, $key)};
            }
        }
        --spaceBtn: 4rem;
        
        @include rpmin(md){
            --directionBtn: -1;
            --spaceBtn: calc(((100vw - #{var(--wcontainer)}) / 2) / 100 * 16 * #{var(--directionBtn)});
        }
        
        &-next,&-prev{
            --wh: 3rem;
            
            @include rpmin(md){
                --wh: 40px;
            }

            @include rpmax(md){
                svg{
                    width: 16px;
                    height: 10px;
                }
            }
            z-index: 2;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: var(--wh);
            height: var(--wh);
            background: transparent;
        
            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;
            transition: .3s all;

            path{
                fill: var(--cl-1);
                transition: .3s all;
            }

            &[aria-disabled="true"]{
                opacity: .4 !important;
                user-select: none !important;
                cursor: initial !important;

                background: transparent !important;
                path{
                    fill: var(--cl-1) !important;
                }
            }

            &::after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 100%;
                transform: translate(-50%, -50%) scale(1.2);
                transform-origin: center;

                background: linear-gradient(to right, map-get($color, lv-1) 80%, rgba(255, 255, 255, 0) 0%), 
                    linear-gradient(map-get($color, lv-1) 80%, rgba(255, 255, 255, 0) 0%), 
                    linear-gradient(to right, map-get($color, lv-1) 80%, rgba(255, 255, 255, 0) 0%), 
                    linear-gradient(map-get($color, lv-1) 80%, rgba(255, 255, 255, 0) 0%);
                
                background-position: top, right, bottom, left;
                background-repeat: repeat-x, repeat-y;
                background-size: 16px 2px, 2px 16px;
                
                transition: .3s all;
            }
            &:hover{
                background: var(--cl-sub);
                
                path{
                    fill: var(--cl-w);
                }
            }
        }
        &-prev{
            left: var(--spaceBtn);
            transform: translate(-100%,-50%) rotate(90deg);
        }
        &-next{
            right: var(--spaceBtn);
            transform: translate(100%, -50%) rotate(-90deg);
        }

        .swiper-button-lock{
            display: none;
        }
    }

    &-pagination{
        &-bullet{
            width: 1.2rem;
            height: 1.2rem;
            
            @include rpmin(md){
                width: 1.8rem;
                height: 1.8rem;
            }

            background-color: var(--cl-w);
            border: 1px solid var(--cl-w);
            &-active{
                background-color: var(--cl-sub);
            }
        }
    }
}