.formBrn {
    .group-opt {
        position: relative;
        display: flex;
        width: 100%;
        line-height: 1.4;
        background: #fff;
        overflow: hidden;
        border-radius: 10px;

        /* Thiết kẻ thẻ select */
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            appearance: none;
            outline: 0;
            box-shadow: none;
            border: 0;
            background: transparent;
            background-image: none;
        }

        select {
            flex: 1;
            padding: 1rem 3rem;
            color: #707070;
            cursor: pointer;
            @include sizeBs(s20);

            border: 1px solid #171717;
            border-radius: 10px;
        }

        option[disabled]{
            display: none;
        }

        /* thiết kế dấu mũi tên */
        &::after {
            content: '\25BC';
            position: absolute;
            top: 0;
            right: 0px;
            height:100%;
            padding: 0 3rem;
            display: flex;
            justify-content: center;
            align-items: center;

            background: transparent;
            cursor: pointer;
            pointer-events: none;
            -webkit-transition: .25s all ease;
            -o-transition: .25s all ease;
            transition: .25s all ease;
            color: #05ACEB;
        }

        /* Transition */
        &:hover::after {
            color: #707070;
        }
    }

    .group-btn{
        width: 100%;
        height: 100%;
        border-radius: 10px;

        button, input{
            padding: 1rem 1.2rem;

            outline: none;
            border: none;
            background: none;

            width: 100%;
            height: 100%;

            background-color: #05ACEB;
            color: white;
            @include sizeBs(s20);
            @include bold;

            border-radius: 10px;
            transition: .3s all ease-in-out;
            &:hover{
                background-color: var(--cl-main);
            }
        }
    }
}

