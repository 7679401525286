.careers-1{
    background-color: #F6F6F6;
    &__searches{
        .row{
            justify-content: center;
        }
    }
    .post{
        
        &--top{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
        }

        &__title, &__deadline{
            &>*{
                line-height: 1.4;
                @include sizeBs(s20);
            }
        }

        &__title{
            width: 100%;
            @include rpmin(md){
                width: 75%;
            }
            &>*{
                color: var(--cl-main);
            }
        }

        &__deadline{
            width: 100%;
            @include rpmin(md){
                width: 25%;
            }
        }

        &-time{
            margin-top: .3rem;
            display: flex;
            align-items: center;
            @include sizeBs(s14);

            time{
                color: #1F6BB4;
                margin-left: 1rem;
            }
        }
        

        .hideContent {
            overflow: hidden;
            line-height: 1em;
            height: 6em;
        }
        
        .showContent {
            line-height: 1em;
            height: auto;
        }

        &--bottom{
            margin-top: 2rem;
            transition: .4s all;
            &-title{
                @include bold;
                color: var(--cl-black);
                @include sizeBs(s20)
            }


        }
        .wrapper-content{
            margin-top: 1rem;
            @include sizeBs(s16);
            p{
                @include sizeBs(s16);
            }
        }

        .show-more{
            margin-top: 1rem;
            &>*{
                @include sizeBs(s14);
                color: var(--cl-main);
                text-decoration: underline;
            }
        }
    }

    .post:not(:nth-of-type(3n+1)){
        margin-top: 2rem;
        padding-top: 2rem;
        @include rpmin(md){
            margin-top: 4rem;
            padding-top: 4rem;
        }
        border-top: 1px solid var(--cl-gray);
    }
}