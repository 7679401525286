.box-intro {
    width: 100%;
    height: 100%;

    background-color: var(--cl-w);

    padding: 2rem 1.6rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

    background: linear-gradient(to right, var(--cl-lv-2) 50%, rgba(255, 255, 255, 0) 0%), 
        linear-gradient(var(--cl-lv-2) 50%, rgba(255, 255, 255, 0) 0%), 
        linear-gradient(to right, var(--cl-lv-2) 50%, rgba(255, 255, 255, 0) 0%), 
        linear-gradient(var(--cl-lv-2) 50%, rgba(255, 255, 255, 0) 0%);
        
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 0px 2px, 2px 0px;

    color: var(--cl-gray);

    $dur1600: 1.6s;

    transition: $dur1600 all;
    position: relative;
    &::after{
        content:"";
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 2rem;
        height: 2rem;
        transform: rotate(-360deg) scale(0);
        transform-origin: center;
        transition: $dur1600 all;
        background-color: var(--cl-sub);
    }

    &:hover{
        background-size: 20px 2px, 2px 20px;
        color: var(--cl-b);

        .box-intro__icon{
            path{
                &[fill]{
                    // fill: var(--cl-lv-2);
                    fill: #05ACEB;
                }
                &[stroke]{
                    // stroke: var(--cl-lv-2);
                    stroke: #05ACEB;
                }
            }
        }
        .box-intro__title{
            &>*{
                color: var(--cl-b);
            }
        }

        &::after{
            transform: rotate(0deg) scale(1);
        }
    }

    &>*:not(:first-child){
        margin-top: 1.2rem;
    }
    
    &__icon{
        path{
            transition: .3s all;
            &[fill]{
                // fill: #707070;
                fill: #1F6BB4;
            }
            &[stroke]{
                // stroke: #707070;
                stroke: #1F6BB4;
            }
        }
    }

    &__title{
        text-align: center;
        &>*{
            @include sizeBs(s20);
            color: var(--cl-gray);
            @include bold;
            transition: .3s all;
        }
    }

}