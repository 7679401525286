.logistics-2{
    position: relative;
    background-color: var(--cl-lv-10);
    overflow: hidden;
    
    &>.container{
        position: relative;
        z-index: 1;
    }
    &-bg{
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        @include rpmin(md){
            object-fit: contain;
        }
    }
    &--bgright{
        z-index: 0;
        position: absolute;
        top: 20%;
        right: 0%;
        width: auto;
        height: auto;
        max-height: 80%;

        transform: translate(28%, 0%);
        object-fit: contain;
        @include rpmin(md){
            object-fit: contain;
        }
    }

    &__item{
        padding-bottom: -2px;
    }
}

.box-logistic{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    box-shadow: 0 3px 6px rgba(0,0,0,.16);

    padding: 4rem 5.2rem;
    position: relative;

    background: linear-gradient(to right, var(--cl-lv-2) 80%, rgba(255, 255, 255, 0) 0%), 
        linear-gradient(var(--cl-lv-2) 80%, rgba(255, 255, 255, 0) 0%), 
        linear-gradient(to right, var(--cl-lv-2) 80%, rgba(255, 255, 255, 0) 0%), 
        linear-gradient(var(--cl-lv-2) 80%, rgba(255, 255, 255, 0) 0%);
        
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 0px 2px, 2px 0px;

    transition: 1.2s all;

    &::after{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background-color: #FFF;
        z-index: 1;
        transition: .3s all;
    }

    .iconArrow{
        position: relative;
        z-index: 2;
        transition: .3s all;
    }
    .iconBox{
        position: relative;
        z-index: 2;
        width: 9rem;
        height: 9rem;
        border-radius: 10px;

        background-color: #F5F5F5;
        display: flex;
        justify-content: center;
        align-items: center;

        &>p{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        svg{
            width: calc(100% - 3rem);
            height: calc(100% - 3rem);
        }
        margin-left: 2.2rem;
        margin-right: 2.2rem;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
        transition: .3s all;
    }


    .content{
        flex: 1;
        display: flex;
        flex-direction: column;

        position: relative;
        z-index: 2;

        &>*{
            transition: .3s all;
        }

        .title{
            @include uppercase;

            @include bold;
            @include sizeBs(s24);
            color: var(--cl-black);
        }

        .desc{
            margin-top: 1.1rem;
            color: var(--cl-text);
            @include sizeBs(s18);

        }
    }

    &:hover,
    &.active{
        background-size: 20px 2px, 2px 20px;

        &::after{
            width: calc(100% - 12px);
            height: calc(100% - 12px);
            background-color: #05ACEB;
        }

        .iconArrow{
            transform: rotate(90deg);
            transform-origin: center;
            path{
                fill: var(--cl-w);
            }
        }

        .content{
            .title{
                color: var(--cl-w);
            }
            .desc{
                color: #F6F6F6;
            }
        }
        
    }
}