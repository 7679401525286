.submit-si{
    background-color: #F6F6F6;
    .braney-subtitle{
        &>*{
            @include sizeBs(s32);
        }
    }

    &-form{
        [data-typeofshipping]{
            display: none;
            &.active{
                display: block;
            }
        }
        [data-typeofshipping="2"]{
            &.active{
                display: flex;
            }
        }
    }
}