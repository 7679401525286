.sd-1{
    .wrapper-content{
        @include rpmin(lg){
            margin-left: 3rem;
        }        
        @include rpmin(xl){
            margin-left: 4rem;
        }

        h1{
            width: 100%;
            @include rpmin(xl){
                width: 94%;
            }
        }
    }

    .tag, .socials{
        @include rpmin(lg){
            margin-left: 3rem;
        }        
        @include rpmin(xl){
            margin-left: 4rem;
        }
    }
}