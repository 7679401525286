.box-service{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 320px;
    
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 1.6rem;
    @include rpmin(lg){
        max-width: initial;
        padding: 4.2rem 2.8rem;
    }

    background-color: var(--cl-lv-3);
    color: var(--cl-w);
    
    position: relative;
    overflow: hidden;
    
    // &::before{
    //     --wh: 23rem;
    //     content: "";
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     width: var(--wh);
    //     height: var(--wh);
    //     background: map-get($color, lv-1);
    //     border-radius: 50%;
    //     transform: translate(-60%, 50%);
    // }
    
    &::before{
        --wh: 40rem;
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: var(--wh);
        height: var(--wh);
        background: transparent;
        border-radius: var(--wh);
        transform: translate(14.3rem,10.4rem);
        border: 2px dashed #fff;
        box-shadow: inset 0px 0px 0px 1rem map-get($color, lv-3);
        animation: _360-infinite 16s linear infinite;
    }
    &::after{
        --wh: 39rem;
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: var(--wh);
        height: var(--wh);
        background: map-get($color, lv-1);
        border-radius: var(--wh);
        transform: translate(14rem, 10rem);
        box-shadow: inset 0px 0px 0px 0rem map-get($color, lv-3);
    }

    &>*:not(:first-child){
        margin-top: 2rem;
    }

    &__title{
        position: relative;
        z-index: 1;
        &>*{
            @include sizeBs(s20);
            line-height: 1.6;
            color: var(--cl-w);
        }
    }

    &__desc{
        position: relative;
        z-index: 1;
        &>*{
            @include sizeBs(s16);
            color: var(--cl-lv-5);
            line-height: 1.4;
        }
    }

    .braney-more{
        position: relative;
        z-index: 1;
        flex: 1;
        display: flex;
        align-items: flex-end;
        &>*{
            color: var(--cl-w);
        }
    }

    &__img{
        position: relative;
        z-index: 1;
        padding-top: 8rem;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        &>*{
            width: 18rem;
            height: 18rem;
            object-fit: contain;
        }
    }
}