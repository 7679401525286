.incoterms{
    &-tabs{
        *{
            transition: .3s all;
        }
        .tab-item{
            cursor: pointer;
            background-color: #F0F0F0;
            padding: 2rem 1.8rem;
            text-align: center;
            box-shadow: 0 3px 6px rgba(0,0,0,.16);
            border-radius: 10px;
            
            &>*{
                color: var(--cl-gray);
                @include sizeBs(s18);
                @include bold;
            }

            &:hover, &.active{
                background-color: #D6F7FF;
                &>*{
                    color: var(--cl-sub);
                }
            }
        }
    }

    &-opt{
        display: none;
        &.active{
            display: block;
        }
    }

    &>.container>.row{
        flex-direction: row-reverse;
    }

    &--left{
        @include rpmin(lg){
            margin-right: 3rem;
        }        
        @include rpmin(xl){
            margin-right: 4rem;
        }
    }
}

.box-incoterm{
    width: 100%;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    &__img{
        //ratio
        width: 100%;
        height: 0;
        padding-top: 46.4%;
        position: relative;

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &__content{
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: 3rem 2rem;
        @include rpmin(md){
            padding: 3rem 4rem;
        }
        
        &>*{
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            @include rpmin(lg){
                width: 80%;
            }
        }
        background-color: var(--cl-w);

        .title{
            &>*{
                @include uppercase;
                @include bold;
                @include sizeBs(s24);
                color: var(--cl-black);
            }
        }
        .desc{
            margin-top: 1rem;
            &>*{
                @include sizeBs(s18);
                color: var(--cl-text);
            }
        }
        .braney-more{
            margin-top: 1.8rem;
        }
    }
}

.box-incoterm:not(:nth-of-type(3n + 1)){
    margin-top: 3rem;
    
    @include rpmin(lg){
        margin-top: 4rem;
    }
}