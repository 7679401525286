@keyframes fixedHeader{
    0%{
        top: 0%;
        transform: translateY(-100%);
    }
    100%{
        top: 0;
        transform: translateY(0);

        @include shadow(s1);
    }
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

    &.scroll {
        .header-top {
            height: 0;
            transform: scale(0);
            opacity: 0;
            visibility: hidden;
            padding: 0;
            margin: 0;
        }

        transform: translateY(-100%);

        // animation-delay: 0.1s;
        animation: fixedHeader 1.5s ease forwards;
    }

    &-top {
        color: var(--cl-w);
        background-color: var(--cl-main);

        display: none;

        @include rpmin(lg) {
            display: block;
        }

        padding: 1.2rem 0;
        transition: .5s all;
        transform-origin: top center;

        .container,
        .left,
        .right {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .container {
            justify-content: center;

            @include rpmin(lg) {
                justify-content: space-between;
            }
        }

        .left {
            display: none;

            @include rpmin(lg) {
                display: flex;
            }
        }

        .socials,
        .search {
            position: relative;
            margin-right: 2rem;
            padding-right: 2rem;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(-50%, -50%);
                width: 2px;
                height: 19px;
                border-radius: 2px;
                background-color: map-get($color, w);
            }
        }

        .socials {
            display: flex;

            &-item {
                display: block;
                transition: .3s all;
                cursor: pointer;

                &:not(:first-child) {
                    margin-left: 1.6rem;
                }

                path {
                    fill: var(--cl-w);
                    transition: .3s all;
                }

                &:hover {
                    path {
                        fill: var(--cl-sub);
                    }
                }
            }
        }

        .search {
            display: flex;
            align-items: center;

            input,
            button {
                background: none;
                border: none;
                outline: none;
                color: var(--cl-w);
                @include bold;
                line-height: 1.4;
            }

            input {
                &::-webkit-input-placeholder {
                    color: var(--cl-w);
                }

                &::-ms-input-placeholder {
                    color: var(--cl-w);
                }

                &::placeholder {
                    color: var(--cl-w);
                }

                width: 18rem;

                @include rpmin(xl) {
                    width: 22rem;
                }
            }

            button {

                path,
                g,
                circle {
                    transition: .2s all;

                    &[stroke] {
                        &:not([stroke="none"]) {
                            stroke: #fff;
                        }
                    }

                    &[fill] {
                        &:not([fill="none"]) {
                            fill: #fff;
                        }
                    }
                }

                &:hover {
                    path,
                    g,
                    circle {
                        &[stroke] {
                            &:not([stroke="none"]) {
                                stroke: var(--cl-sub);
                            }
                        }

                        &[fill] {
                            &:not([fill="none"]) {
                                fill: var(--cl-sub);
                            }
                        }
                    }
                }
            }
        }

        .phone+.languages {
            margin-left: 3rem;

            @include rpmin(xl) {
                margin-left: 3.8rem;
            }
        }

        .right {
            justify-self: flex-end;
            justify-items: flex-end;
        }

        .phone {
            color: var(--cl-w);
            @include bold;

            display: flex;
            align-items: center;

            svg {
                margin-right: 1.6rem;
            }
        }

        .languages {
            display: flex;

            &-item {
                display: block;
                cursor: pointer;

                transition: .3s all;
                position: relative;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #1B2335;
                    opacity: 0;
                }

                &.active {
                    user-select: none;
                    cursor: initial;

                    &::after {
                        content: "";
                        opacity: .5;
                    }
                }

                &:hover {
                    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
                }

                img{
                    height: 100%;
                }
            }

            .languages-item+.languages-item {
                margin-left: 1.6rem;
            }
        }
    }

    &-bottom {
        background-color: #F1F2F2;
        position: relative;

        @include rpmin(lg) {
            background-color: var(--cl-w);
        }

        .menu {
            display: none;

            @include rpmin(lg) {
                display: flex;
            }

            justify-content: space-between;
            align-items: stretch;

            &-left,
            &-right {
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            --kc: 2rem;

            @include rpmin(xl) {
                --kc: 5rem;
            }

            &-left {
                margin-right: var(--kc);
            }

            &-right {
                margin-left: var(--kc);
            }

            &-center {
                padding: 1.2rem 0;
            }

            &-item {
                list-style-type: none;
                height: 100%;
                padding: 1.2rem 0;
                display: flex;
                align-items: center;
                justify-content: center;

                position: relative;

                &:hover {
                    &>.menu-item__link{
                        color: var(--cl-sub);
                        &::after {
                            width: 100%;
                        }
                    }


                    &>.menu-item__sub {
                        // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                        transform: translateY(0%);
                        opacity: 1;
                        z-index: -1 ;
                        visibility: visible;
                        user-select: initial;
                    }
                }
                &__link {
                    @include bold;
                    color: var(--cl-black);
                    @include uppercase;

                    transition: .3s all;

                    display: inline-block;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: -.8rem;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        transition: .3s all;
                        transform-origin: center;
                        width: 0%;
                        height: 1px;
                        background:
                            linear-gradient(to right, #293D9C 50%, rgba(255, 255, 255, 0) 0%);

                        background-position: bottom;
                        background-repeat: repeat-x;
                        background-size: 8px 1px;
                    }

                    &.active {
                        color: var(--cl-sub);

                        &::after {
                            width: 100%;
                        }
                    }

                    &.show {
                        &~.menu-item__sub {
                            // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                            transform: translateY(0%);
                            opacity: 1;
                            visibility: visible;
                            user-select: initial;
                        }
                    }
                }

                &__sub {
                    display: block;
                    position: absolute;
                    top: calc(100% - 1px);
                    left: 0px;
                    min-width: 22rem;
                    background-color: var(--cl-w);
                    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
                    border-bottom: 3px solid var(--cl-main);

                    padding: 2rem 0rem 3rem 0rem;

                    list-style-type: none;

                    &-link {
                        padding: 0 2rem;
                        @include bold;
                        color: var(--cl-black);
                        line-height: 1.3;
                        transition: .2s all ease-in-out;
                        &>*{
                            @include bold;
                            color: var(--cl-black);
                            line-height: 1.3;
                            transition: .2s all ease-in-out;

                            @include rpmin(xl){
                                white-space: nowrap;
                            }
                        }
                        &:hover{
                            color: var(--cl-sub);
                            &>*{
                                color: var(--cl-sub);
                            }
                        }

                        &:not(:first-child) {
                            margin-top: 1rem;
                        }

                        position: relative;

                        ul{
                            position: absolute;
                            top: -2rem;
                            left: 100%;

                            transition: .6s all ease-in-out;
                            transform: translateX(-40%);
                            opacity: 0;
                            z-index: -2;
                        }

                        &:hover{
                            &>ul{
                                transform: translateX(0%);
                                opacity: 1;
                                z-index: -2;
                                visibility: visible;
                                user-select: initial;
                            }
                        }
                    }

                    // clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                    transition: .6s all ease-in-out;
                    transform: translateY(-100%);
                    opacity: 0;
                    z-index: -1;
                    visibility: hidden;
                    user-select: none;
                }
            }
        }
    }

    .mobile {
        @include rpmin(lg) {
            display: none;
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.2rem 0;

        &-logo {
            svg {
                height: 5.6rem;
            }
        }

        &-hamburger,
        .close {
            cursor: pointer;
            display: flex;
            flex-direction: column;

            position: relative;
            width: 2.3rem;
            height: 1.9rem;

            --line: 2px;

            @include rpmin(md) {
                --line: 3px;
            }

            .line {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                user-select: none;
                width: 100%;
                height: 0px;
                border-top: var(--line) solid var(--cl-main);
                border-radius: 12px;

                transition: .3s all;

                &:first-child {
                    top: 0;
                    bottom: auto;
                    transform: translate(-50%, 0);
                }

                &:last-child {
                    top: auto;
                    bottom: 0;
                    transform: translate(-50%, 0);
                }
            }

            &.show {
                width: 2.4rem;

                .line {
                    opacity: 0;
                    visibility: hidden;
                }

                .line:first-child {
                    opacity: 1;
                    visibility: visible;

                    top: 50%;
                    bottom: auto;
                    transform: translate(-50%, -50%) rotate(45deg);
                }

                .line:last-child {
                    opacity: 1;
                    visibility: visible;
                    top: 50%;
                    bottom: auto;
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
        }

        .close {
            .line{
                border-top: var(--line) solid var(--cl-black);
            }
        }

        .languages {
            display: flex;

            &-item {
                display: block;
                cursor: pointer;

                transition: .3s all;
                position: relative;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #1B2335;
                    opacity: 0;
                }

                &.active {
                    user-select: none;
                    cursor: initial;

                    &::after {
                        content: "";
                        opacity: .5;
                    }
                }

                &:hover {
                    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
                }
                img{
                    height: 100%;
                }
            }

            .languages-item+.languages-item {
                margin-left: 1.6rem;
            }
        }

        .search {
            display: flex;
            align-items: center;

            input,
            button {
                background: none;
                border: none;
                outline: none;
                color: var(--cl-main);
                line-height: 1.4;
                font-size: 16px;
            }

            input {
                flex: 1;

                &::-webkit-input-placeholder {
                    color: var(--cl-main);
                }

                &::-ms-input-placeholder {
                    color: var(--cl-main);
                }

                &::placeholder {
                    color: var(--cl-main);
                }

                width: 18rem;

                @include rpmin(xl) {
                    width: 22rem;
                }
            }

            button {

                path,
                g,
                circle {
                    transition: .2s all;

                    &[stroke] {
                        &:not([stroke="none"]) {
                            stroke: var(--cl-main);
                        }
                    }

                    &[fill] {
                        &:not([fill="none"]) {
                            fill: var(--cl-main);
                        }
                    }
                }

                &:hover {

                    path,
                    g,
                    circle {
                        &[stroke] {
                            &:not([stroke="none"]) {
                                stroke: var(--cl-sub);
                            }
                        }

                        &[fill] {
                            &:not([fill="none"]) {
                                fill: var(--cl-sub);
                            }
                        }
                    }
                }
            }
        }

        &-nav {
            position: absolute;
            top: 0%;
            left: 0;
            z-index: 999;
            width: 100%;
            height: 100vh;
            max-width: 100%;
            opacity: 1;
            visibility: visible;

            transform: translate(-100%, 0);
            transform-origin: left center;
            transition: .3s all linear;

            background-color: #00000000;

            @include shadow(s1);

            @include rpmin(lg) {
                display: none;
            }

            &.show{
                opacity: 1;
                visibility: visible;
                transform: translate(0%, 0);
                background-color: #00000066;
            }

            .nav {
                width: 40rem;
                max-width: 100%;
                height: 100%;
                transition: .3s all linear;
                padding: 2.5rem 4rem 3rem 3.6rem;
                @include rpmin(sm){
                    padding: 2.5rem 8rem 3rem 3.6rem;
                }

                background-color: var(--cl-w);

                overflow-y: auto;
                overflow-x: hidden;
                
                --wScroll: 5px;
                @include rpmin(lg){
                    --wScroll: 7px;
                }
        
                /* width */
                &::-webkit-scrollbar {
                    width: var(--wScroll);
                }
                
                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px var(--cl-lv-5); 
                    border-radius: var(--wScroll);
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: var(--cl-main); 
                    border-radius: var(--wScroll);
                }
                
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: var(--cl-lv-3); 
                }

                &>*:not(:first-child) {
                    margin-top: 2rem;
                }

                &-top {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }

                &-search {
                    input {
                        color: var(--cl-main);
                    }
                }

                &-menu {
                    display: flex;
                    flex-direction: column;
                    list-style: none;

                    &-item {
                        border-top: 1px solid #E1E1E1;
                        
                        &:last-child{
                            border-bottom: 1px solid #E1E1E1;
                        }

                        &.active {
                            .nav-menu__link {
                                &>a {
                                    color: var(--cl-sub);
                                    @include bold;
                                }
                                svg {
                                    transform: rotate(180deg);
                                }
                               
                            }

                            .nav-menu__sub-link{
                                .nav-menu__sub{
                                    position: relative;
                                    &::after{
                                        content: "";
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        width: 4px;
                                        height: 100%;
                                        background-color: var(--cl-sub);
                                    }
                                }
                            }

                            .nav-menu__sub {
                                opacity: 1;
                                height: auto;
                                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                                visibility: initial;
                            }
                        }
                    }

                    &__link {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &>a {
                            cursor: pointer;
                            // display: block;
                            padding: 12px 0;

                            color: var(--cl-main);
                            @include regular;
                            text-transform: uppercase;

                            // @include sizeBs(bodyText3);
                            font-size: 1.6rem;

                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            transition: .5s ease;

                            span {
                                flex: 1;
                            }
                        }
                        
                        svg {
                            transition: .5s ease;
                        }

                        &:focus,
                        &:hover,
                        &.active {
                            &>a {
                                color: var(--cl-sub);
                                @include bold;
                            }
                        }
                    }

                    &__sub {
                        height: 0;
                        opacity: 0;
                        transition: all .5s ease;
                        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                        visibility: hidden;
                    }

                    &__sub {
                        padding-left: 1.2rem;
                        list-style-type: none;

                        li {
                            &.active {
                                &>a {
                                    color: var(--cl-sub);
                                    @include bold;
                                }
                                svg {
                                    transform: rotate(180deg);
                                }

                                ul {
                                    opacity: 1;
                                    height: auto;
                                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                                    visibility: initial;
                                }
                            }

                            a {
                                cursor: pointer;
                                padding: 8px 0;

                                color: var(--cl-main);
                                @include regular;
                                text-transform: uppercase;

                                // @include sizeBs(bodyText3);
                                font-size: 1.5rem;

                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                span {
                                    flex: 1;
                                }

                                svg {
                                    transition: .5s ease;
                                }
                            }

                            ul {
                                height: 0;
                                opacity: 0;
                                transition: all .5s ease;
                                clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                                visibility: hidden;
                            }

                        }
                    }
                }
            }
        }
    }
}