.si-1{
    .button-choose{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        color: var(--cl-black);
        background-color: var(--cl-w);
        // border: 1px solid #1F6BB4;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
        padding: 1.6rem 2.6rem;
        @include bold;
        @include sizeBs(s20);


        cursor: pointer;
        transition: .3s all linear;

        user-select: none;
        white-space: wrap;
        text-align: center;
        line-height: 1.5;

        &.active,
        &:hover,
        &:focus{
            background-color: var(--cl-sub);
            color: var(--cl-w);
        }
    }
    &__section{
        display: none;
        &.active{
            display: block;
        }
        &-form{
            .block-form{
                &:not(:first-child){
                    margin-top: 40px;
                }
            }
        }

        .braney-subtitle{
            margin-bottom: 2rem;
            &>*{
                @include sizeBs(s32);
            }
        }

        form:not(:first-child){
            @extend .pt-b40;
        }
    }
}
