@keyframes dotAnimation{
    from{
        transform: translateY(-8px);
    }
    to{
        transform: translateY(0);
    }
}

// @keyframes zoomPopup {
//     to{
//         transform: translate(-50%, -50%) scale(1);
//     }
// }

.tools{
    position: fixed;
    z-index: 999;
    top: 12rem;
    
    @include rpmin(lg){
        top: 24rem;
        transform: translateX(-14rem);
    }
    left: 0;

    display: flex;
    flex-direction: row-reverse;
    transition: .3s all linear;

    &.show{
        transform: translateX(-110%);

        @include rpmin(lg){
            transform: translateX(0);
        }

        .tools-pc{
            height: auto;
            border-top: 2rem solid var(--cl-main);
            border-bottom: 2rem solid var(--cl-main);
            padding: 1rem 0;
            box-shadow: inset 0 3px 6px rgba(0,0,0,.16);
        }

        + .tools-mobile{
            // display: block;
            // opacity: 1;
            // transform-origin: center;
            // animation-delay: .2s;
            // transform: translate(-50%, -50%) scale(0);

            // animation:  zoomPopup .3s linear forwards;
            
            // @include rpmin(lg){
            //     display: none;
            //     opacity: 0;
            // }

            display: flex;
            @include rpmin(lg){
                display: none;
            }

            .modal{
                // transition-delay: .2s;
                // transform: scale(1);
                transform: scale(0) rotate(-90deg);
                animation-delay: .2s;
                animation: modalPopup .3s linear forwards;
            }
        }
    }

    &.scroll{
        top: 12rem;
    }
    
    &-btn{
        background-color: var(--cl-main);
        width: 6rem;
        height: 6rem;
        @include rpmin(lg){
            width: 7.7rem;
            height: 7.7rem;
        }
        display: flex;
        justify-content: center;
        align-items: center;

        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;

        .btn{
            cursor: pointer;

            position: relative;
            width: 54%;
            height: 54%;
            background-color: var(--cl-sub);
            border-radius: 50%;

            display: flex;
            justify-content: center;
            align-items: center;

            span{
                width: 15.7%;
                height: 15.7%;
                background-color: var(--cl-w);
                border-radius: 50%;
                user-select: none;
                pointer-events: none;
                margin-left: .2rem;
                margin-right: .2rem;

                animation: bounceDot 1s infinite;
                animation-delay: var(--i);
            }
        }
    }

    &-pc{
        width: 14rem;
        background-color: white;


        display: none;
        max-height: 64vh;
        height: 0;
        border-top: none;
        border-bottom: none;
        padding: 0 0;
        box-shadow: none;

        transition: .16s all;

        @include rpmin(lg){
            display: flex;
            flex-direction: column;
        }

        &__item{
            cursor: pointer;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;

            padding: 1.5rem 2rem;

            *{
                transition: .16s all;
            }

            .icon{
                margin-bottom: 1rem;
            }

            &>*{
                @include sizeBs(s15);
                @include bold;
                color: var(--cl-black);
            }

            &:hover{
                background-color: var(--cl-sub);

                svg{
                    [stroke] {
                        &:not([stroke="none"]) {
                            stroke: var(--cl-w);
                        }
                    }
    
                    [fill] {
                        &:not([fill="none"]) {
                            fill: var(--cl-w);
                        }
                    }
                }

                &>*{
                    color: var(--cl-w);
                }
            }
        }
    }

    &-mobile{
        position: fixed;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;

        z-index: 1001;

        display: flex;
        justify-content: center;
        align-items: center;
        display: none;

        &.show{
            display: flex;
            .modal{
                // transition-delay: .2s;
                // transform: scale(1);
                transform: scale(0) rotate(-90deg);
                animation-delay: .2s;
                animation: modalPopup .3s linear forwards;
            }
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
    
            background-color: #171717;
            opacity: .5;
            border: 1px solid #707070;
        }

        transition: .16s all linear;
        *{
            transition: .16s all linear;
        }

        .modal{
            width: 30rem;
            height: 30rem;

            @include rpmin(md){
                width: 36rem;
                height: 36rem;
            }

            position: relative;
            border: 3px solid var(--cl-main);
            box-shadow: inset 0 3px 6px rgba(0,0,0,.16);
            background-color: var(--cl-w);
            border-radius: 50%;

            z-index: 1;

            transform: scale(0);
            display: block;

            &-close{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 4rem;
                height: 4rem;
                
                @include rpmin(md){
                    width: 4.6rem;
                    height: 4.6rem;
                }

                z-index: 11;

                cursor: pointer;

                background-color: var(--cl-sub);
                border-radius: 50%;

                display: flex;
                justify-content: center;
                align-items: center;


                &::before, &::after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 54%;
                    height: 0;
                    transform: translate(-50%, -50%);

                    border-top: 2px solid #FFF;
                    transition: .2s all linear;
                    user-select: none;
                    pointer-events: none;

                    @include rpmin(lg){
                        border-top: 2px solid #707070;
                    }
                }

                &::before{
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
                &::after{
                    transform: translate(-50%, -50%) rotate(45deg);
                }
    
                &:hover{
                    &::before{
                        transform: translate(-50%, -50%) rotate(-135deg);
                    }
                    &::after{
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }
            }

            &-wrapper{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                list-style: none;

                display: flex;
                justify-content: center;
                align-items: center;


                // li{
                //     position: absolute;
                //     left: -50%;
                //     list-style: none;
                //     transform: rotate(calc(360deg / 5 * var(--i))) translateX(9.8rem);
                //     transform-origin: 16.2rem;
                //     z-index: 10;
                //     transition: .5s all linear;

                //     a{
                //         display: flex;
                //         flex-direction: column;
                //         justify-content: center;
                //         align-items: center;
                //         text-align: center;
                //         color: var(--cl-black);
                //         @include bold;

                //         transform: rotate(calc(360deg / -5 * var(--i)));

                //         // width: 4rem;
                //         // height: 4rem;
                //         // border-radius: 50%;
                //         // background-color: red;
                //     }

                //     .icon{
                //         width: 2rem;
                //         height: 2rem;
                //         margin-bottom: .6rem;
                //         svg{
                //             width: 100%;
                //             height: 100%;
                //         }
                //     }

                //     span{
                //         @include bold;
                //         font-size: 5px;
                //         color: var(--cl-black)
                //     }
                // }

                li{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    a{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        color: var(--cl-black);
                        @include bold;
                    }
                    
                    .icon{
                        margin-bottom: .6rem;
                        width: 4rem;
                        height: 4rem;

                        svg{
                            width: 100%;
                            height: 100%;
                        }
                        @include rpmin(md){
                            width: 4.2rem;
                            height: 4.2rem;
                        }
                    }

                    span{
                        @include bold;
                        font-size: 10px;
                        color: var(--cl-black);
                        @include rpmin(md){
                            font-size: 12px;
                        }
                    }
                }

                li:nth-of-type(1){
                    top: 76%;
                    left: 66%;
                }
                li:nth-of-type(4){
                    top: 76%;
                    left: 34%;
                }

                li:nth-of-type(2){
                    top: 46%;
                    left: 78%;
                }
                li:nth-of-type(3){
                    top: 46%;
                    left: 22%;
                }

                li:nth-of-type(5){
                    top: 22%;
                    left: 50%;
                }
                
            }
        }
    }
}