@mixin family--primary {
  font-family: map-get($family, primary), sans-serif;
}
@mixin family--second {
  font-family: map-get($family, second), sans-serif;
}
@mixin family--third {
  font-family: map-get($family, third), sans-serif;
}

@mixin plc($i) {
  display: -webkit-box;
  -webkit-line-clamp: #{$i};
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin uppercase{
  text-transform: uppercase;
}
@mixin italic{
  font-style: italic;
}


////////////////////////////////////////////////
//////////// FONT WEIGHT //////////////////////

@mixin thin{
  font-weight: 100;
}
@mixin extra-light{
  font-weight: 200;
}
@mixin light{
  font-weight: 300;
}
@mixin regular{
  font-weight: 400;
}
@mixin normal{
  font-weight: 400;
}
@mixin medium{
  font-weight: 500;
}
@mixin semi-bold{
  font-weight: 600;
}
@mixin bold{
  font-weight: 700;
}
@mixin extra-bold{
  font-weight: 800;
}
@mixin ultra-bold{
  font-weight: 900;
}

//////////// FONT WEIGHT ///////////////////////
////////////////////////////////////////////////



////////////////////////////////////////////////
//////////// Shadow ////////////////////////

@mixin shadow($sd){
  box-shadow: map-get($shadow, #{$sd});
}

//////////// Shadow ////////////////////////
////////////////////////////////////////////////


////////////////////////////////////////////////
//////////// Gradient ////////////////////////

@mixin gradient($gr)
{
  background-image: $gr;
}

//////////// Gradient ////////////////////////
////////////////////////////////////////////////

////////////////////////////////////////////////
//////////// color ////////////////////////

@mixin txt($txt)
{
  color: map-get($color, #{$txt});
}
//////////// color ////////////////////////
////////////////////////////////////////////////

////////////////////////////////////////////////
//////////// background ////////////////////////

@mixin bg($bg)
{
  background-color: map-get($color, #{$bg});
}

// @include txt(main);
//////////// background ////////////////////////
////////////////////////////////////////////////