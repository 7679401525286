.k-space{
    &>.container{
        &>.row{
            flex-direction: row-reverse;
        }
    }
    
    &--left{
        @include rpmin(lg){
            margin-right: 3rem;
        }        
        @include rpmin(xl){
            margin-right: 4rem;
        }
    }
}

.k-1{
    .box-policy{
        &__img{
            padding-top: 62.5%;
            @include rpmin(md){
                padding-top: 46.4%;
            }
        }

        &__content{
            width: calc(100% - 6rem);
            transform: translate(3rem, -50%);

            @include rpmin(md){
                width: calc(100% - 22rem);
                transform: translate(11rem, -50%);
            }

            padding-top: 3rem;
            padding-bottom: 3rem;

            &>*{
                @include sizeBs(s24);
            }
        }

        &:not(:first-child){
            margin-top: 4rem;
        }
    }
}

.kc-1{
    &-wrapper{
        &__item{
            &:not(:first-child){
                @include blockBs(b60, margin-top);
            }
        }
    }
}

.ku-1{
    &-table{        
        &__item{
            &:not(:first-child){
                margin-top: .8rem;
            }
        }
    }
}

.k-currency{
    &-time{
        @include sizeBs(s18);
    }

    &-table{
        table{
            width: 100%;
            th{
                text-align: center;
                padding: 1.4rem 1rem;
                background-color: #303090;
                @include sizeBs(s16);
                @include bold;
                color: var(--cl-w);
            }
        }
    }
}

.block-dictionary{
    &-tabs{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 -3px;
        margin-bottom: 40px;

        .tab-item{
            width: 50px;
            margin: 0 3px 5px 3px;
            padding: .5rem 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            cursor: pointer;
            color: var(--cl-w);
            background-color: var(--cl-main);
            border-radius: 10px;
            text-transform: uppercase;
            
            transition: .3s ease;

            @include bold;
            @include sizeBs(s18);
            
            &:hover,
            &.active{
                background-color: var(--cl-sub);
            }
        }
    }

    &-content{
        *{
            @include sizeBs(s18);
            color: var(--cl-text) !important;
        }
        .content-item{
            .title{
                &>*{
                    @include bold;
                }
            }
            &:not(:first-child){
                margin-top: 4rem;
            }
        }
        .wrapper-content>:not(:first-child){
            margin-top: .3rem;
        }
    }
}


.k-dictionary{
    .block-dictionary-opts{
        display: none;
        &.active{
            display: block;
        }
    }
}

