.block-t1{
    &-wrapper{
        max-width: 100%;
        overflow: auto;

        padding-bottom: .8rem;
    }
    &__title{
        text-align: center;
        padding: 1.4rem 2rem;
        background-color: #303090;
        &>*{
            @include uppercase;
            @include sizeBs(s24);
            @include bold;
            color: var(--cl-w);
        }
    }
    table{
        width: 100%;
    }
    table,th,td{
        border: 1px solid #707070;
        border-collapse: collapse;

        @include sizeBs(s18);
        text-align: center;

        color: var(--cl-black);
    }

    th{
        @include sizeBs(s14);
        @include regular;
        color: #05ACEB;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
    }

    th,td{
        padding: 1.2rem 1rem;
    }
}