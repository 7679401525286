.idx-6{
    position: relative;
    padding: 2rem;
    max-width: 100%;
    overflow: hidden;
    @include rpmin(lg){
        padding: 4rem;
    }

    &>.row{
        align-items: flex-start;
    }

    &-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    &-maps{
        padding: 1.5rem;
        background-color: var(--cl-lv-7);
    }

    &-content{
        background-color: var(--cl-w);
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
        padding: 2rem 3rem;
        @include rpmin(lg){
            padding: 4rem 6rem;
        }
        overflow: auto;

        max-height: 440px;
        @include rpmin(lg){
            max-height: initial;
        }
        
        --wScroll: 5px;
        @include rpmin(lg){
            --wScroll: 7px;
        }

        /* width */
        &::-webkit-scrollbar {
            width: var(--wScroll);
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px var(--cl-lv-5); 
            border-radius: var(--wScroll);
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: var(--cl-main); 
            border-radius: var(--wScroll);
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: var(--cl-lv-3); 
        }

        .item{
            & ~ .item{
                margin-top: 2rem;
            }
            &-content{
                color: var(--cl-gray);
                @include sizeBs(s16);
                line-height: 1.8;

                h1, h2, h3, h4, h5, h6{
                    color: var(--cl-black);
                    margin-bottom: 1rem;
                    a{
                        @include bold;
                        @include sizeBs(s20);
                        color: var(--cl-black);
                        display: inline-block;
                        transition: .3s all;

                        &:hover{
                            color: var(--cl-sub);
                        }
                    }
                }
            }

            &-contact{
                margin-top: 1rem;
                @include sizeBs(s18);
                color: var(--cl-black);
                line-height: 1.8;
                &__bottom{
                    display: flex;
                    flex-direction: column;
                    @include rpmin(yl){
                        flex-direction: row;
                    }
                    &>*:not(:first-child){
                        margin-left: 0;

                        @include rpmin(yl){
                            margin-left: 6vw;
                        }
                    }
                }
            }
        }
    }
}