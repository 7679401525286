.idx-3{
    position: relative;
    max-width: 100%;
    overflow: hidden;
    &-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include rpmin(lg){
            height: 380px;
        }
        @include rpmin(xl){
            height: 440px;
        }
        z-index: -2;

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
        }
        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: map-get($color, overlay-1);
        }
    }

    .braney-title{
        width: 100%;
        @include rpmin(sm){
            width: 74%;
        }
        @include rpmin(md){
            width: 64%;
        }
        @include rpmin(md){
            width: 64%;
        }
        @include rpmin(lg){
            width: 48%; 
        }
        margin-left: auto;
        margin-right: auto;
        
        &>*{
            color: var(--cl-w);
            line-height: 1.4;
        }
    }

    &-swiper{
        position: relative;

        .swiper-custom-button-next,
        .swiper-custom-button-prev{
            top: calc(50% + 4.8rem);
        }

        @include rpmax(lg){
            padding-right: 6rem;
            padding-left: 6rem;
        }
        @include rpmax(md){
            padding-right: 4rem;
            padding-left: 4rem;
        }
    }

    @include rpmax(lg){
        color: var(--cl-w) !important;
        
        .braney-more>*{
            color: var(--cl-w);
        }
    }
}