.m-c{margin-left:auto;margin-right:auto}

[class|="fx"]{display:flex}
[class^="fx-c"]{justify-content:center}
[class^="fx-l"]{justify-content:flex-start}
[class^="fx-r"]{justify-content:flex-end}
.fx-cc,.fx-lc,.fx-rc{align-items:center}
.fx-cl,.fx-ll,.fx-rl{align-items:flex-start}
.fx-lr,.fx-cr,.fx-rr{align-items:flex-end}
.fx-ls,.fx-cs,.fx-rs{align-items:stretch}
.fx-1{flex:1}
.fx-col{flex-direction:column}
.fx-row{flex-direction:row}

.t-c{text-align: center}
.t-l{text-align: left}
.t-r{text-align: right}

.uppercase{
    text-transform: uppercase;
}
.bold{
    font-weight: bold;
}

.italic{
    font-style: italic;
}

.noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.scrollgsap{
    position: relative;
    transition: top .5s ease,opacity .1s ease;
    top: 150px;
    opacity: 0;

    &.gsap-loaded{
        top: 0;
        opacity: 1;
    }
}

.of-scroll{
    /* width */
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        border-radius: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #EAEAEA;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: var(--color-main);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #5D5D5D;
    }
}

.appear-box{
    animation: appearBox .4s linear;
}

@for $i from 1 through 10{
    .plc-#{$i}{
        display: -webkit-box;
        -webkit-line-clamp: #{$i};
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

@each $key,
$val in $color {
    .cl-#{$key} {
        color: #{$val};
    }
}


@each $key,
$val in $blockBs {
    .p-#{$key} {
        @include blockBs(#{$key}, padding, 0);
    }
    .pt-#{$key} {
        @include blockBs(#{$key}, padding-top);
    }
    .pb-#{$key} {
        @include blockBs(#{$key}, padding-bottom);
    }
    .m-#{$key} {
        @include blockBs(#{$key}, margin, 0);
    }
    .mt-#{$key} {
        @include blockBs(#{$key}, margin-top);
    }
    .mb-#{$key} {
        @include blockBs(#{$key}, margin-bottom);
    }
}

@each $key,
$val in $sizeBs {
    .#{$key} {
        @include sizeBs(#{$key});
    }
    .#{$key} {
        @include sizeBs(#{$key});
    }
    .#{$key} {
        @include sizeBs(#{$key});
    }
}

.animate__bounce {
    animation-name: bounce;
    animation-duration: 1s;
    transform-origin: center bottom;
}

.wrapper-content{
    color: #8D8D8D;
    @include sizeBs(s18);
    h1{
        @include sizeBs(s32);
        color: #171717;
        line-height: 1.6;
    }
    
    h2{
        @include sizeBs(s24);
        color: #707070;
        line-height: 1.4;
    }
    
    p{
        color: currentColor;
        @include sizeBs(s18);
        line-height: 1.4;
    }

    time{
        @include sizeBs(s18);
        @include italic;
        color: var(--cl-main);
    }

    img{
        display: block;
    }

    &>*:not(:first-child){
        margin-top: 2rem;
    }

    ul{
        list-style-type: none;
    
        li{
            line-height: 1.6;
        }
    }
}
.submit-now{
    margin-top: 2rem;
    &>*{
        display: inline-block;
        padding: 1.4rem 2rem;
        @include sizeBs(s16);
        @include uppercase;
        @include bold;
        
        color: var(--cl-w);
        background-color: var(--cl-sub);
        border-radius: 10px;

        transition: .3s all ease-in-out;
        &:hover{
            background-color: var(--cl-main);
        }
    }
}

.playYtb {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    transform-box: fill-box;

    transition: .3s all;
    display: block;
    width: 14%;
    padding-top: 14%;
    height: 0;

    @include rpmin(xl){
        width: 8.8rem;
        height: 8.8rem;
        padding-top: 0;
    }

    border-radius: 50%;
    opacity: .6;

    img,
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: contain;
        user-select: none;
        pointer-events: none;
    }

    .arrowPlay {
        transition: .3s all;
        fill: #d1d1d1;
    }

    &:hover {
        opacity: 1 !important;

        // animation: scaleBtn .6s linear;
        animation: grow 1s infinite alternate linear;

        .arrowPlay {
            fill: var(--cl-main);
        }
    }

}

.content-line {
    *, p{
        color: var(--cl-text);
    }
    // .text {
    //     // @include sizeBs(small);
    // }

    p {
        color: map-get($color, tx1);
        // @include sizeBs(small);
        white-space: pre-wrap;
        line-height: 1.23;
    }

    // span {
    //     // @include sizeBs(small);
    // }

    img {
        width: 100%;
        height: auto;
    }

    // p ~ p{
    //     margin-top: 22px;
    // }

    // p ~ img{
    //     margin-top: 24px;
    // }
    // img ~ img{
    //     margin-top: 24px;
    // }
    // img ~ p{
    //     margin-top: 20px;
    // }

    p.note {
        // @include sizeBs(note);
        text-align: center;
        width: 90%;
        @include regular;
    }

    img~p.note {
        margin-top: 8px;
    }

    // &>*{
    //     &:not(:first-child){
    //         margin-top: 24px;
    //     }
    // }

    // p.line{ 
    //     margin-top: 4px;
    // }

    .head {
        text-transform: uppercase;
        color: #707070;
        @include bold;
        @include sizeBs(s24);
    }

    .bold{
        @include bold;
    }

    .table-content{
        &>*{
            p{
                height: 100%;

                padding: 16px 12px;
                @include uppercase;
                @include sizeBs(s18);
                @include regular;
                background-color: #F5F5F5;
                text-align: center;
            }

            img{
                height: 100%;
            }
        }

        img{
            height: 100%;
        }

        & + .table{
            margin-top: 0 !important;
        }
    }



    ul {
        list-style-type: none;

        li {
            @include sizeBs(s18);
            padding-left: 20px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                left: 0;
                transform: translate(0, -50%);

                background-color: var(--cl-main);
                border-radius: 50%;

                --rLi: 6px;
                --tLi: 10px;

                top: var(--tLi);
                width: var(--rLi);
                height: var(--rLi);

                @include rpmin(md) {
                    --tLi: 10px;
                    --rLi: 6px;
                }

                @include rpmin(xl) {
                    --tLi: 11px;
                    --rLi: 8px;
                }
            }
        }
    }
}


.social-contact {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-top: -12px;
    margin-left: -8px;
    margin-right: -8px;

    &__item {
        display: block;
        padding-top: 12px;
        padding-left: 8px;
        padding-right: 8px;

        &-link {
            transition: .3s all linear;
            cursor: pointer;
            position: relative;

            svg {
                path {
                    transition: .3s all linear;
                }
            }

            &:hover {
                svg {
                    path {
                        fill: map-get($color, bor2);
                    }
                }
            }
        }
    }
}

.wrapper-footer{
    display: flex;
    flex-wrap: wrap;

    .tag{
        flex: 0 0 100%;
        width: 100%;
    }

    // &>*:last-child{
    //     margin-top: 30px;
    // }

    .sourceAuthor{
        flex: 0 0 100%;
        margin-top: auto;
    }

    .socials{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
        flex: 0 0 100%;
        
        // margin-top: 20px;
        width: 100%;
    
        p{
            color: var(--cl-main);
            @include sizeBs(s18);
        }

        &-item{
            margin-left: 12px;
            @include rpmin(md){
                margin-left: 18px;
            }
            border-radius: 50%;
            cursor: pointer;

            &:hover{
                opacity: .8;
            }

            &>*{
                user-select: none;
                pointer-events: none;
            }
        }
    }

    // .tag + .socials{
    //     margin-top: 30px;
    // }
    &>*:not(:first-child){
        margin-top: 20px;
        @include rpmin(md){
            margin-top: 30px;
        }
    }
}

.sourceAuthor{
    color: var(--cl-main);
    @include sizeBs(s18);
    font-style: italic;

    a{
        display: inline-block;
        color: var(--cl-main);
        @include sizeBs(s18);
        font-style: italic;    
    }
}

.tag{
    display: inline-block;
    width: 100%;
    @include sizeBs(s22);
    color: var(--cl-black);

    &>p{
        display: inline-block;
    }
    
    &-list{
        a + a{
            position: relative;
            &::before{
                display: inline;
                content: ", ";
            }
        }
        a{
            @include sizeBs(s22);
            color: var(--cl-black);
            transition: .16s all;
            &:hover{
                color: var(--cl-main);
            }
        }
    }
}



.copyMaker{
    position: relative;

    &:before {
        content: "";
        width: 16px;
        height: 16px;
        bottom: 4px;
        // left: 50%;
        clip-path: polygon(50% 40%, 0% 100%, 100% 100%);
    }

    &:after {
        content: attr(tooltip);
        width: auto;
        bottom: -8px;
        white-space: nowrap;

        // left: 20px;
        padding: 5px;
        border-radius: 4px;
        font-size: 10px;
    }

    &:before,
    &:after {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        // bottom: -8px;
        left: 50%;
        box-sizing: border-box;
        background-color: #05aceb;
        color: white;
        transform: translate(-50%, 0%);
        transition: all 300ms ease;
    }

    &:hover:before,
    &:hover:after {
      opacity: 1;
      transform: translate(-50%, 100%);
    }
}


.fixed-phone{
    position: fixed;
    bottom: 8vh;
    left: 2vw;

    z-index: 1001;

    display: flex;
    align-items: center;
    // &>*{
    //     user-select: none;
    //     pointer-events: none;
    // }

    .phone-i {
        display: block;
        margin: 0;
        width: 1em;
        height: 1em;
        font-size: 40px;

        z-index: 2;
    
        background-color: var(--cl-sub);
        border-radius: 0.5em;
        box-shadow:
          0 0 0 0em rgba(var(--cl-sub), 0),
          0em 0.05em 0.1em rgba(#000000, 0.2);
        transform: translate3d(0, 0, 0) scale(1);
    
        &::before,
        &::after {
          position: absolute;
          content: "";
        }
    
        &::before {
            top: 0;
            left: 0;
            width: 1em;
            height: 1em;
            background-color: rgba(#fff, 0.1);
            border-radius: 100%;
            opacity: 1;
            transform: translate3d(0, 0, 0) scale(0);
        }

        &::after {
            top: 0.25em;
            left: 0.25em;
            width: 0.5em;
            height: 0.5em;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z'/%3E%3C/svg%3E");

            fill: white;
            // background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            transform: translate3d(0, 0, 0);
        }
        &.is-animating {
            animation: phone-outer 3000ms infinite;
        
            &::before {
                animation: phone-inner 3000ms infinite;
            }
            
            &::after {
                animation: phone-icon 3000ms infinite;
            }
        }
    }

    .phone-text{
        display: none;

        @include rpmin(md){
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            padding: 4px 20px 4px 60px;
            max-width: 40px;
    
            z-index: 1;
    
            font-size: 16px;
            @include bold;

            white-space: nowrap;
            display: flex;
            align-items: center;
            border-radius: 100px;
    
            background-color: var(--cl-sub);
            opacity: 1;
            transition: .16s all linear;
            visibility: hidden;
            color: #FFF;
            clip-path: polygon(0 0, 0 0%, 0 100%, 0% 100%);
        }
    }

    &:hover{
        .phone-text{
            visibility: visible;
            max-width: initial;
            opacity: 1;
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }
    }
}
