.e-2{
    position: relative;
    &__bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &-wrapper{
        &__content{
            padding: 3rem 4rem;
            @include rpmin(md){
                padding: 4rem 5rem;
            }
            background-color: rgba(#FFF, .9);
            box-shadow: 0 3px 6px rgba(0,0,0,.16);

            .title{
                &>*{
                    color: var(--cl-black);
                    @include sizeBs(s20);
                    @include bold;
                }
            }
            .content{
                margin-top: 2rem;
                &>*{
                    @include sizeBs(s20);
                    color: var(--cl-black);
                }
            }
        }
    }
}