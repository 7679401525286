.scroll-to-top{
    z-index: 300;
    position: fixed;
    bottom: 8rem;
    right: 2vw;
    
    width: 40px;
    height: 40px;
    
    @include rpmin(md){
        bottom: 8rem;
        width: 60px;
        height: 60px;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    svg{
        transform-origin: center;
    }

    cursor: pointer;
    transition: .5s ease;

    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);

    background: linear-gradient(to right, var(--cl-main) 80%, rgba(255, 255, 255, 0) 0%), 
        linear-gradient(var(--cl-main) 80%, rgba(255, 255, 255, 0) 0%), 
        linear-gradient(to right, var(--cl-main) 80%, rgba(255, 255, 255, 0) 0%), 
        linear-gradient(var(--cl-main) 80%, rgba(255, 255, 255, 0) 0%);
        
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 20px 2px, 2px 20px;

    transition: .3s all linear;

    svg{
        width: 52%;
    }


    &:hover{
        background-color: var(--cl-main);
        path{
            &[fill]{
                fill: var(--cl-w);
            }
            &[stroke]{
                stroke: var(--cl-w);
            }
        }
    }
}