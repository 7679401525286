.box-policy{
    display: block;
    width: 100%;
    height: 100%;
    &__img{
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 44%;

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            
        }
    }

    &__content{
        position: relative;
        width: calc(100% - 6rem);
        transform: translate(3rem, -50%);
        
        padding: 2rem 3rem;
        background-color: var(--cl-w);
        text-align: center;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
        transition: .3s all;

        &>*{
            @include bold;
            @include sizeBs(s20);
            color: var(--cl-black);
            transition: .3s all;
        }
    }

    &:hover{
        .box-policy__content{
            background-color: #05ACEB;
            &>*{
                color: var(--cl-w);
            }
        }
    }
}