.breadcrumbs {
    width: 100%;
    &-list {        
        display: flex;
        align-items: center;
        list-style-type: none;

        // overflow: auto;
    }
    
    &-item {
        display: flex;
        align-items: center;
        line-height: 1.4;
        white-space: nowrap;

        & > * {
            @include sizeBs(s16);
            color: var(--cl-main);
            margin-right: 12px;
            @include regular;
        }

        *{
            transition: .3s all linear;
        }
        
        &.active {
            .breadcrumbs-item__link {
                cursor: initial;
                pointer-events: none;
            }
        }

        &:hover{
            .breadcrumbs-item__link {
                color: var(--cl-black);
            }
        }
    }
}
