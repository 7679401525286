.box-logo{
    display: block;
    width: 100%;
    padding-top: 40%;
    
    background-color: var(--cl-w);
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
    position: relative;
    img{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        padding: 1rem;

        transform: translate(-50%,-50%);

        display: block;
        object-fit: contain;
    
    }
}