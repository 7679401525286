.footer{
    &-bottom{
        height: 6rem;
        width: 100%;
        display: block;
        background-color: var(--cl-main);
    }

    &-top{
        &-wrapper{
            justify-content: space-between;
        }

        &__item{
            &:nth-of-type(1){
                margin-bottom: 3rem;
                width: 100%;
                @include rpmin(sm){
                    width: 46%;
                }
                @include rpmin(md){
                    width: 100%;
                }
                @include rpmin(xl){
                    margin-bottom: auto;
                    width: 20%;
                }
                @include rpmin(xxl){
                    width: 18%;
                }
            }
            &:nth-of-type(2){
                width: 100%;
                margin-bottom: 3rem;
                @include rpmin(sm){
                    width: 46%;
                }
                @include rpmin(md){
                    width: 33%;
                    margin-bottom: 0rem;
                }
                @include rpmin(lg){
                    width: 30%;
                }
                @include rpmin(xl){
                    width: 26%;
                }
                @include rpmin(xxl){
                    width: 26%;
                }
            }
            &:nth-of-type(3){
                width: 100%;
                margin-bottom: 3rem;
                @include rpmin(sm){
                    width: 46%;
                    margin-bottom: 0rem;
                }
                @include rpmin(md){
                    width: 33%;
                }
                @include rpmin(lg){
                    width: 30%;
                }
                @include rpmin(xl){
                    width: 22%;
                }
                @include rpmin(xxl){
                    width: 20%;
                }
            }
            &:nth-of-type(4){
                width: 100%;
                @include rpmin(sm){
                    width: 46%;
                }
                @include rpmin(md){
                    width: 33%;
                }
                @include rpmin(lg){
                    width: 30%;
                }
                @include rpmin(xl){
                    width: 22%;
                }
                @include rpmin(xxl){
                    width: 20%;
                }
            }
        }
    }

    &-copyright{
        text-align: center;
        line-height: 1.4;
        padding-top: 1rem;
        *{
            @include sizeBs(s18);
            color: var(--cl-black);
        }
    }

    &-item + &-item{
        margin-top: 3rem;
    }

    &-item{
        max-width: 40rem;
        margin: 0 auto;
        text-align: center;
        @include rpmin(sm){
            text-align: left;
        }
        &__logo, &__bct{
            display: block;
            max-width: 20rem;
            margin-left: auto;
            margin-right: auto;
            @include rpmin(sm){
                margin-left: initial;
                margin-right: initial;    
            }
            
            @include rpmin(md){
                margin-left: auto;
                margin-right: auto;
                max-width: 22rem;
            }
            @include rpmin(xl){
                margin-left: auto;
                margin-right: auto;
                max-width: 30rem;
            }
        }

        &__title{
            &>*{
                @include uppercase;
                @include bold;
                @include sizeBs(s24);
                line-height: 1.5;
                color: var(--cl-main);

            }
            padding-bottom: 1.2rem;
            margin-bottom: 1.2rem;
            position: relative;

            &::after{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background:
                    linear-gradient(to right, #05ACEB 80%, rgba(255, 255, 255, 0) 0%);

                background-position: bottom;
                background-repeat: repeat-x;
                background-size: 20px 2px;
            }
        }

        &__info{
            display: flex;
            flex-direction: row;
            justify-content: center;
            @include rpmin(sm){
                justify-content: flex-start;
            }
            &>*{
                display: inline-block;
            }
            .icon{
                margin-right: 2rem;
            }
            .desc{
                color: var(--cl-black);
                @include sizeBs(s18);
            }
        }
        
        &__info + &__info{
            margin-top: 3rem;
        }

        &__links{
            display: flex;
            flex-direction: column;
            
            .link:not(:first-child){
                margin-top: 2rem;
            }
            .link{
                @include bold;
                @include sizeBs(s18);
                color: var(--cl-black);
            }
        }

        &__opt{
            select{
                width: 100%;
                white-space: nowrap;
                padding: 1.2rem 2rem;
                line-height: 1.4;
                border: none;
                background-color: #E3E3E3;
                color: var(--cl-gray);
                appearance:none;

                text-align: center;
                @include rpmin(sm){
                    text-align: left;
                }
            }
            position: relative;
            max-width: 100%;
            width: 100%;
            @include rpmin(sm){
                min-width: 220px;
                width: auto;
            }

            &::after{
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-top: 12px solid map-get($color, sub);
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
                z-index: 1;
                pointer-events: none;
            }
        }

        &__socials{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -4px;
            margin-right: -4px;
            margin-top: -4px;
            
            justify-content: center;
            @include rpmin(sm){
                justify-content: flex-start;
            }

            .social{
                padding-left: 4px;
                padding-right: 4px;
                padding-top: 4px;

                *{
                    user-select: none;
                }

                *:not([fill="#fff"]){
                    fill: var(--cl-main);
                    transition: .3s all;
                }

                &:hover{
                    *:not([fill="#fff"]){
                        fill: var(--cl-sub);
                    }  
                }
            }
        }
    }

    &-fixed{
        position: fixed;
        right: 2vw;
        --sizeFixed: 40px;
        bottom: 8rem;
        
        @include rpmin(md){
            --sizeFixed: 60px;
            bottom: 8rem;
        }

        transition: bottom .2s linear;

        &.showed-scroll{
            bottom: calc(8rem + 40px + 18px);
            
            @include rpmin(md){
                bottom: calc(8rem + 60px + 18px);
            }
        }

        font-size: var(--sizeFixed);

        
        z-index: 1001;
        
        &__thumb{
            width: var(--sizeFixed);
            height: var(--sizeFixed);
            border-radius: 100px;
            color: var(--cl-sub);

            background-color: var(--cl-sub);

            transform: translate3d(0, 0, 0) scale(1);
            cursor: pointer;

            &::before,
            &::after {
                position: absolute;
                content: "";
            }
        
            &::before {
                top: 0;
                left: 0;
                width: 1em;
                height: 1em;
                background-color: rgba(#fff, 0.1);
                border-radius: 100%;
                opacity: 1;
                transform: translate3d(0, 0, 0) scale(0);
            }

            &::after {
                top: 0.25em;
                left: 0.25em;
                width: 0.5em;
                height: 0.5em;
                background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='%23FFF' d='M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z'/%3E%3C/svg%3E%0A");
                // background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: cover;
                transform: translate3d(0, 0, 0);
            }
            &.is-animating {
                animation: phone-outer 3000ms infinite;
            
                &::before {
                    animation: phone-inner 3000ms infinite;
                }
                
                &::after {
                    animation: phone-icon 3000ms infinite;
                }
            }
        }

        &-ls{
            position: absolute;
            bottom: calc(100% + 2rem);
            left: 0;
            min-width: 100%;
            display: flex;
            flex-direction: column-reverse;

            transition: .16s all linear;

            opacity: 0;
            visibility: hidden;

            .item{
                transform: translateY(calc((100% + 2rem) * var(--i, 1)));
                transition: transform 0.3s linear;

                margin-top: 2rem;
                width: 100%;
                
                a, img, svg{
                    display: block;
                    width: 100%;
                    height: auto;
 
                    object-fit: contain;
                }

                .phone-i {
                    display: block;
                    margin: 0;
                    width: 1em;
                    height: 1em;
                    font-size: var(--sizeFixed);
            
                    z-index: 2;
                
                    background-color: var(--cl-sub);
                    border-radius: 0.5em;
                    box-shadow:
                      0 0 0 0em rgba(var(--cl-sub), 0),
                      0em 0.05em 0.1em rgba(#000000, 0.2);
                    transform: translate3d(0, 0, 0) scale(1);
                
                    &::before,
                    &::after {
                      position: absolute;
                      content: "";
                    }
                
                    &::before {
                        top: 0;
                        left: 0;
                        width: 1em;
                        height: 1em;
                        background-color: rgba(#fff, 0.1);
                        border-radius: 100%;
                        opacity: 1;
                        transform: translate3d(0, 0, 0) scale(0);
                    }
            
                    &::after {
                        top: 0.25em;
                        left: 0.25em;
                        width: 0.5em;
                        height: 0.5em;
                        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
                        background-position: 50% 50%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        transform: translate3d(0, 0, 0);
                    }
                    &.is-animating {
                        animation: phone-outer 3000ms infinite;
                    
                        &::before {
                            animation: phone-inner 3000ms infinite;
                        }
                        
                        &::after {
                            animation: phone-icon 3000ms infinite;
                        }
                    }
                }

            }
        }

        &.is-showed{
            .footer-fixed-ls{
                opacity: 1;
                visibility: visible;

                .item{
                    transform: translateY(0%);
                }
            }
            .footer-fixed__thumb{
                color: var(--cl-main);
                background-color: var(--cl-main);
            }
        }
    }
}