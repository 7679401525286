.box-article{
    max-width: 320px;
    @include rpmin(lg){
        max-width: initial;
    }
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    &-block{
        width: 100%;
        position: relative;
        --ratioHeight: 134.45%;
        --ratioHeightHover: 56.5%;

        --timeTransition: 1s;
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
        transition: var(--timeTransition) all;
        padding-top: var(--ratioHeight);

        @media (max-width: 575px){
            --ratioHeight: 114%;
        }
    }

    &-img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // padding-top: 144.45%;
        
        padding-top: var(--ratioHeightHover);
        transition: var(--timeTransition);
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: map-get($color, overlay-3);
            opacity: 0;
            visibility: hidden;
            transition: var(--timeTransition);
        }
    }

    &-time{
        position: absolute;
        top: 0;
        right: 3rem;
        @include rpmin(lg){
            right: 4rem;
        }
        background-color: var(--cl-main);
        color: var(--cl-w);
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-content: center;
        &>*{
            text-align: center;

            &:not(:first-child){
                margin-top: .4rem;
            }
        }

        padding: 1.6rem 1.2rem;

        @include bold;
        @include sizeBs(s18);
    }

    &-w{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-top: calc(#{var(--ratioHeight)} - #{var(--ratioHeightHover)});
        background-color: white;
        transition: var(--timeTransition);
    }

    &-wrapper{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-top: calc(#{var(--ratioHeight)} - #{var(--ratioHeightHover)});
        transition: var(--timeTransition);
    }

    &-content{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: 1;

        color: var(--cl-w);

        padding: 2rem 2rem 4rem 2rem;

        &>*:not(:first-child){
            padding-top: 1.6rem;
        }

        display: flex;
        flex-direction: column;

        .content-title{
            display: block;
            padding-bottom: 2rem;
            position: relative;

            background:
                linear-gradient(to right, var(--cl-lv-1) 80%, rgba(255, 255, 255, 0) 0%);

            background-position: bottom;
            background-repeat: repeat-x;
            background-size: 20px 2px;

            transition: var(--timeTransition);

            &>*{
                @include bold;
                @include sizeBs(s20);
                color: var(--cl-black);
                line-height: 1.5;

                transition: var(--timeTransition);
            }

            // &::after{
            //     content: "";
            //     position: absolute;
            //     top: 100%;
            //     left: 0;
            //     width: 100%;
            //     height: 100%;
            // }
        }

        .content-desc{
            flex: 1;
            transition: var(--timeTransition);
            transform-origin: top center;
            &>*{
                color: var(--cl-text);
                transition: var(--timeTransition);
            }
        }
    }
    .braney-more{
        &>*{
            transition: var(--timeTransition);
        }
    }

    &:hover{
        box-shadow: none;
        .box-article{
            &-img{
                padding-top: var(--ratioHeight);

                &::after{
                    opacity: 1;
                    visibility: visible;
                }
            }
            &-w{
                padding-top: 0;
            }
            &-content{
                justify-content: flex-end;
                .content-title{
                    &>*{
                        color: var(--cl-w);
                    }
                    background:
                        linear-gradient(to right, var(--cl-w) 80%, rgba(255, 255, 255, 0) 0%);
                    
                    background-position: bottom;
                    background-repeat: repeat-x;
                    background-size: 20px 2px;
                }

                .content-desc{
                    opacity: 0;
                    height: 0;
                    transform: scale(0);
                    flex: none;
                }
            }
        }

        .braney-more{
            &>*{
                color: var(--cl-w);
            }
        }
    }
}