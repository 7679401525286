.idx-5{
    background-color: var(--cl-lv-8);
    max-width: 100%;
    overflow: hidden;
    .swiper-wrapper{
        padding: 6px 3px 6px 3px;
    }

    position: relative;

    &-bg{
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;

        @include rpmin(lg){
            object-fit: contain;
        }
    }

    .container{
        position: relative;
        z-index: 1;
    }

    .braney-title{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        @include rpmin(lg){
            width: 100%;
        }
    }

    .swiper-wrapper {
        transition-timing-function: linear !important;
    }
}