.contact-1{
    position: relative;
    background-color: var(--cl-lv-10);
    
    &>.container{
        position: relative;
        z-index: 1;
    }
    &-bg{
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        @include rpmin(md){
            object-fit: contain;
        }

    }
}

.contact-2{
    background-color: var(--cl-lv-10);

    &>.container{
        box-shadow: 0 5px 10px rgba(0,0,0,.16);
        border-radius: 10px;

        background-color: white;

        padding-left: 3rem;
        padding-right: 3rem;
        
        @include rpmin(md){
            padding-left: 6rem;
            padding-right: 6rem;
        }


        @include rpmin(lg){
            padding-left: 8rem;
            padding-right: 8rem;
        }

        form{
            input,textarea, select, button{
                padding-top: 2.4rem;
                padding-bottom: 2.4rem;

                white-space: nowrap;
            }
            input,textarea, select,
            button,
            .inputSubmit .braney-more{
                border-radius: 10px;
            }

            .inputSubmit{
                @include rpmin(md){
                    width: 100%;
                    padding-right: 0;
                }
            }
        }
    }
}