.idx-1{
    position: relative;
    background-color: var(--cl-w);
    max-width: 100%;
    overflow: hidden;
    &-bg_right,
    &-bg_left{
        position: absolute;
        z-index: 1;
        
        object-fit: cover;
        opacity: .16;
        @include rpmin(lg){
            opacity: 1;
            object-fit: contain;
        }
    }
    &-bg_right{
      object-position: right center;
      height: 100%;
      bottom: 0;
      right: 0;

      width: 100%;
      display: none;
      @include rpmin(lg){
        width: 48%;
        display: block;
      }
    }
    &-bg_left{
      width: 100%;
      height: 100%;
      object-position: left center;
    }
    &-wrapper{
      position: relative;
      z-index: 2;
      &>*:not(:first-child){
        margin-top: 1.6rem;
        @include rpmin(md){
          margin-top: 2rem;
        }
      }
    }
  
    &-links{
      margin-top: 3.2rem !important;
      @include rpmin(md){
        margin-top: 4rem !important;
      }
    }
  
    .braney-title{
      @include rpmin(lg){
        width: 86%;
      }
      @include rpmin(xl){
        width: 82%;
      }
    }
}