@keyframes activeImg {
    0% {
        z-index: -1;
        opacity: 0;
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    }
    30% {
        z-index: 0;
        opacity: 0;
        clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    }
    100% {
        opacity: 1;
        z-index: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}

.about-2 {
    position: relative;
    background-color: var(--cl-lv-9);
    & > * {
        position: relative;
        z-index: 1;
    }
    * {
        transition: 0.3s all;
    }

    &-bg {
        z-index: 0 !important;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: right center;
    }

    &-wrapper {
        display: flex;
        align-items: center;

        position: relative;
        justify-content: center;
        @include rpmin(md) {
            justify-content: flex-end;
        }

        .left {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            @include rpmin(md) {
                width: 70%;
            }

            height: 100%;
            z-index: -1;

            overflow: hidden;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                transition: 0.6s all ease-in-out;

                &[data-select] {
                    z-index: -1;
                    opacity: 0;

                    &.active {
                        opacity: 1;
                        z-index: 1;
                        // animation: activeImg .6s linear forwards;
                    }
                }
            }
        }
        .right {
            padding: 4rem 0;

            width: 90%;
            @include rpmin(lg) {
                width: 60%;
            }
            @include rpmin(xxl) {
                width: 50%;
            }
            @include rpmin(yl) {
                width: 46%;
            }
        }

        .statements {
            background-color: var(--cl-w);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            min-height: auto;
            // @include rpmin(lg){
            //     min-height: 45rem;
            // }
            padding: 4rem 4rem;
            @include rpmin(md) {
                padding: 4rem 5rem;
            }
            @include rpmin(xxl) {
                padding: 4rem 6rem;
            }
            @include rpmin(yl) {
                padding: 4rem 8rem;
            }

            &-opts {
                margin-top: 2rem;
                display: none;
                &.active {
                    display: block;
                }
            }

            &-btns {
                width: 100%;
                margin-top: 2rem;
                display: inline-flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

                flex-direction: column;

                @include rpmin(md) {
                    width: 100%;
                    flex-direction: row;
                    justify-content: space-between;
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: calc(100% - 3rem);
                    height: 0;
                    border-top: 2px solid map-get($color, gray);
                    z-index: 0;

                    display: none;
                    @include rpmin(md) {
                        display: block;
                        content: "";
                    }
                }

                & > * {
                    position: relative;
                    z-index: 1;
                }

                &__item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 1.2rem 1.6rem;

                    min-width: 100%;

                    @include rpmin(md) {
                        min-width: 148px;
                    }

                    svg {
                        margin-right: 0rem;
                        transform: scaleX(0);
                        transition: 0.3s all;
                    }
                    span {
                        white-space: nowrap;
                    }
                    * {
                        transition: 0.3s all;
                    }
                    transition: 0.3s all;
                    @include bold;
                    @include sizeBs(s14);
                    line-height: 1.6;

                    path {
                        transition: 0.3s all;
                    }

                    // background-color: var(--cl-w);
                    // color: var(--cl-gray);

                    background-color: var(--cl-sub);
                    color: var(--cl-w);

                    border: 2px solid var(--cl-gray);
                    transition: 0.3s all;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        height: 100%;
                        transform: translate(-50%, -50%);

                        background: linear-gradient(to right, map-get($color, main) 80%, rgba(255, 255, 255, 0) 0%),
                            linear-gradient(map-get($color, main) 80%, rgba(255, 255, 255, 0) 0%),
                            linear-gradient(to right, map-get($color, main) 80%, rgba(255, 255, 255, 0) 0%),
                            linear-gradient(map-get($color, main) 80%, rgba(255, 255, 255, 0) 0%);

                        background-position: top, right, bottom, left;
                        background-repeat: repeat-x, repeat-y;
                        background-size:
                            0px 0px,
                            0px 0px;

                        transform-origin: center;
                        transition: 1.6s all;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 3px;
                        left: 3px;
                        right: 3px;
                        bottom: 3px;

                        background-color: transparent;
                        z-index: -1;
                        transition: 0.3s all;

                        @include rpmin(md) {
                            top: 4px;
                            left: 4px;
                            right: 4px;
                            bottom: 4px;
                        }
                    }

                    // &:hover{
                    // }

                    &.active {
                        color: var(--cl-w);
                        background-color: transparent;
                        border: 2px solid transparent;
                        position: relative;

                        svg {
                            margin-right: 0.8rem;
                            transform: scaleX(1);
                        }
                        &::before {
                            background-color: var(--cl-sub);
                        }
                        &::after {
                            background-size:
                                16px 1px,
                                1px 16px;
                            @include rpmin(md) {
                                background-size:
                                    20px 2px,
                                    2px 20px;
                            }
                        }
                    }
                }
                .statements-btns__item + .statements-btns__item {
                    margin-top: 12px;

                    @include rpmin(md) {
                        margin-top: 0;
                        margin-left: 3rem;
                    }

                    @include rpmin(lg) {
                        margin-left: 3rem;
                    }
                }
            }
        }

        .wrapper-content {
            margin-top: 2rem;

            color: var(--cl-black);

            p {
                color: var(--cl-black);
                line-height: 1.6;
            }
        }
    }
}
