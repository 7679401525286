// ratio
// 56.25%; -> 16:9
// 75%; -> 4:3
// 66.66%; -> 3:2
// 62.5%; -> 8:5
// 100% -> 1:1


// BOOTSTRAP 5
// Cách sử dụng: Không tùy chỉnh trong đây (phần này mặc định)
// kích thước 1183px (grid layout)
$containerWidth: (
	sx: 96%,
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1245px,
	yl: 1245px
);

$mdWidth: (
	sx: 420px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1336px,
	yl: 1500px
);

// use max-width
$maxWidth: (
	sx: 419px,
	sm: 575px,
	md: 767px,
	lg: 991px,
	xl: 1199px,
	xxl: 1335px,
	yl: 1499px,
);


$spaceBs: (
	s30: (
		default: 2.4rem,
		md: 2.52rem,
		lg: 2.7rem,
		xxl: 3rem,
	),
	s34: (
		default: 2.72rem,
		md: 2.86rem,
		lg: 3.06rem,
		xxl: 3.4rem
	),
	s40: (
		default: 3.2rem,
		md: 3.36rem,
		lg: 3.6rem,
		xxl: 4rem
	)
);

$blockBs: (
	b24: (
		default: 2rem,
		md: 2.1rem,
		lg: 2.2rem,
		xxl: 2.4rem
	),
	b40: (
		default: 3.2rem,
		md: 3.36rem,
		lg: 3.6rem,
		xxl: 4rem
	),
	b42: (
		default: 3.36rem,
		md: 3.53rem,
		lg: 3.78rem,
		xxl: 4.2rem
	),
	b50: (
		default: 4rem,
		md: 4.2rem,
		lg: 4.5rem,
		xxl: 5rem
	),
	b56: (
		default: 4.48rem,
		md: 4.7rem,
		lg: 5.04rem,
		xxl: 5.6rem
	),
	b60: (
		default: 4.8rem,
		md: 5.04rem,
		lg: 5.4rem,
		xxl: 6rem
	),
	b80: (
		default: 6.4rem,
		md: 6.72rem,
		lg: 7.2rem,
		xxl: 8rem
	),
	b100: (
		default: 8rem,
		md: 8.4rem,
		lg: 9rem,
		xxl: 10rem
	),
);

// $sectionBs: (
// );

$sizeBs: (
	s60: (
		default: 3.8rem, // 67%
		sm: 4.56rem, // 76%
		xl: 5.16rem, // 86%
		xxl: 6rem,
	),
	s36: (
		default: 2.95rem, // 82%
		md: 3.1rem, // 86%
		xl: 3.24rem, // 90%
		xxl: 3.6rem
	),
	s34: (
		default: 2.79rem, // 82%
		md: 2.92rem, // 86%
		xl: 3.06rem, // 90%
		xxl: 3.4rem,
	),
	s32:(
		default: 2.62rem, // 82%
		md: 2.75rem, // 86%
		xl: 2.88rem, // 90%
		xxl: 3.2rem,
	),
	s30: (
		default: 2.5rem, // 82%
		md: 2.6rem, // 86%
		xl: 2.7rem, // 90%
		xxl: 3rem,
	),

	s28: (
		default: 2.52rem, // 90%
		md: 2.58rem, // 92%
		xl: 2.63rem, // 94%,
		xxl: 2.8rem
	),

	s24: (
		default: 2.16rem, // 94% 
		md: 2.21rem, // 92%
		xl: 2.26rem, // 94%
		xxl: 2.4rem,
	),
	s22: (
		default: 1.98rem, // 90%
		md: 2.02rem, // 92%,
		xl: 2.21rem, // 94%
		xxl: 2.2rem,
	),

	s20: (
		default: 1.8rem, // 90%,
		md: 1.84rem, // 92%,
		xl: 1.88rem, // 94%
		xxl: 2rem,
	),
	s18: (
		default: 1.62rem, // 90%
		md: 1.66rem, // 92%
		xl: 1.7rem, // 94%
		xxl: 1.8rem,
	),

	s16: (
		default: 1.44rem, // 90%
		md: 1.47rem, // 92%
		xl: 1.5rem, // 94%
		xxl: 1.6rem,
	),
	s15: (
		default: 1.35rem,
		md: 1.38rem,
		xl: 1.41rem,
		xxl: 1.5rem
	),
	s14: (
		default: 1.34rem,
		md: 1.35rem,
		xl: 1.38rem,
		xxl: 1.4rem
	)
);


$flexCol: (
	1: 8.33333%,
	2: 16.66667%,
	3: 25%,
	4: 33.33333%,
	5: 41.66667%,
	6: 50%,
	7: 58.33333%,
	8: 66.66667%,
	9: 75%,
	10: 83.33333%,
	11: 91.66667%,
	12: 100%,
);

$spaceSection: (
);

$flexOffset: (
	0: 0%,
	1: 8.33333%,
	2: 16.66667%,
	3: 25%,
	4: 33.33333%,
	5: 41.66667%,
	6: 50%,
	7: 58.33333%,
	8: 66.66667%,
	9: 75%,
	10: 83.33333%,
	11: 91.66667%
);

$flexRow: (
	1: 100%,
	2: 91.66667%,
	3: 83.33333%,
	4: 75%,
	5: 66.66667%,
	6: 58.33333%,
	7: 50%,
	8: 41.66667%,
	9: 33.33333%,
	10: 25%,
	11: 16.66667%,
	12: 8.33333%,
);

$flexGutter: (
	// 0: 0,
	// 1: 0.25rem,
	// 2: 0.5rem,
	// 3: 1rem,
	// 4: 1.5rem,
	// 5: 3rem,
	// 6: 5rem,

	// mặc định xd pc
	0: 0rem,
	8: .8rem,
	10: 1rem,
	16: 1.6rem,
	20: 2rem,
	24: 2.4rem,
	26: 2.6rem,
	30: 3rem,
	32: 3.2rem,
	38: 3.8rem,
	40: 4rem,
	42: 4.2rem,
	48: 4.8rem,
	52: 5.2rem,
	60: 6rem,
	76: 7.6rem,
);




///////////////////// GRID ////////////////////////////
@import "../_variables/bootstraps/gridBs";


///////////////////// MIXIN ////////////////////////////
@import "../_variables/bootstraps/mixinsBs";




// $containerWidth: (
// 	sm: 540px,
// 	md: 720px,
// 	lg: 960px,
// 	xl: 1140px,
// 	xxl: 1320px,
// 	yl: 1640px
// );

// old
// $mdWidth: (
// 	sm: 576px,
// 	md: 768px,
// 	lg: 992px,
// 	xl: 1200px,
// 	xxl: 1400px,
// 	yl: 1680px
// );


// $mdWidth: (
// 	sm: 576px,
// 	md: 768px,
// 	lg: 992px,
// 	xl: 1240px,
// 	xxl: 1440px,
// 	yl: 1760px
// );

// // use max-width
// $maxWidth: (
// 	sm: 575px,
// 	md: 767px,
// 	lg: 991px,
// 	xl: 1239px,
// 	xxl: 1439px,
// 	yl: 1759px,
// );