.box-contact{
    &__img{
        position: relative;
        padding-top: 56.25%;
        width: 100%;
        
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__title{
        text-align: center;
        margin-top: 2rem;
        &>*{
            display: block;

            @include bold;
            @include sizeBs(s24);
            line-height: 1.4;

            color: var(--cl-black);
        }
    }

    &__bottom{
        margin-top: 1.6rem;
        padding: 2rem;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--cl-w);
        box-shadow: 0 3px 6px rgba(0,0,0,.16);

        &>*{
            line-height: 1.6;
            @include sizeBs(s22);
            color: var(--cl-black);
        }

        a{
            line-height: 1.6;
            @include sizeBs(s22);
            color: var(--cl-black);
        }
    }
}