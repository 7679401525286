.o-1{
    background-color: #F6F6F6;

    &-map{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        height: 0;
        padding-top: 80%;

        background-color: var(--cl-w);
        iframe{
            position: absolute;
            top: 1rem;
            left: 1rem;
            width: calc(100% - 2rem);
            height: calc(100% - 2rem);
        }
    }

    &__left{
        .braney-desc{
            margin-top: 2rem;
            @include sizeBs(s16);
            color: var(--cl-gray);
            p{
                @include sizeBs(s16);
                color: var(--cl-gray);
            }
        }
    }

    &__right{
        &>*{
            @include rpmin(lg){
                margin-left: 4.2rem;
            }

        }
    }

    &-images{
        &__item{
            display: block;
            position: relative;
            width: 100%;
            height: 0;
            padding-top: 80%;
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &-addr{
        padding: 3rem 4rem 3.3rem 4rem;
        @include rpmin(sm){
            padding: 4rem 8rem 4.5rem 8rem;
        }

        @include rpmin(lg){
            padding: 4rem 5rem 4.5rem 5rem;
        }

        position: relative;
        background-color: var(--cl-w);
        box-shadow: 0 3px 6px rgba(0,0,0,.16);
        &::after{
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: #303090;
        }

        .name{
            @include bold;
            @include sizeBs(s24);
            color: var(--cl-black);
        }
        .desc{
            margin-top: .8rem;
            @include sizeBs(s16);
        }

        &__item{
            &:not(:first-child){
                padding-top: 1rem;
                margin-top: 1.2rem;
                border-top: 2px solid #DBDBDB;
            }
        }
    }
}