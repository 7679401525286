.inputSubmit{
    margin-top: 2.4rem;
    
    width: 100%;
    @include rpmin(md){
        width: 50%;
        padding-right: 2rem;
        margin-left: auto;
        margin-right: auto;
    }
    
    &.btn-full{
        button{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
    &.full-center{
        width: 100%;
        padding-right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .braney-more{
        background-color: #05ACEB;
        outline: none;
        border: none;

        &>*{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem 1.8rem;
            width: 100%;
            color: var(--cl-w);
        }
        text-align: center;

        display: flex;
        justify-content: center;
        align-items: center;    
        width: 100%;

        path{
            stroke: var(--cl-w);
        }

        
        &>*{
            background-color: #05ACEB;
            border: none;
            outline: none;

            &:hover{
                color: var(--cl-w) !important;
            }
        }
    }
}